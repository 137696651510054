import React, { Component } from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

class OffHireSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Off Hire</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Off Hire Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="off_hire_date"
                  onChange={this.props.onChange}
                  value={this.props.rental.off_hire_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>New Mileage</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="off_hire_mileage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_mileage)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Mileage Excess (over allowance)</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="off_hire_excess_mileage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_excess_mileage)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Missing Fuel</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="off_hire_missing_fuel"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_missing_fuel)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Mileage Excess Cost</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="excess_mileage_charge"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.excess_mileage_charge)}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>New Damage</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="off_hire_new_damage"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.off_hire_new_damage)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Off Hire Total Additional Charges</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="off_hire_total_additional_charges"
                  value={String(
                    this.props.rental.off_hire_total_additional_charges
                  )}
                  readOnly
                  disabled
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default OffHireSection;
