import React, {useState} from "react";
import {Button} from "react-bulma-components";
import ViewPartsModal from "../WorkLookup/ViewPartsModal";


function WorkSearchModalRow({work, selectWork, workIndex}){
    const [openPartView, setOpenPartView] = useState(false);
    return (
            <tr key={work.id}>
                <td>{work.description}</td>
                <td>{work.labour_hours}</td>
                <td>
                    £
                    {(work.labour_cost || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </td>
                <td>
                    £
                    {(work.customer_labour_cost || 0).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </td>
                <td>{work.supplier && work.supplier.display_name}</td>
                <td>{work.part_work_lookup ? work.part_work_lookup.length : 0}</td>
                <td>
                    <Button
                        onClick={() => selectWork(work, workIndex, workIndex)}
                        color="success"
                    >
                        Select
                    </Button>
                </td>
                <td>
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            setOpenPartView(true)
                        }}
                        color={"warning"}
                    >
                        View Parts
                    </Button>
                </td>
                <td>
                    <ViewPartsModal
                        workLookup={work}
                        open={openPartView}
                        setOpen={setOpenPartView}
                    />
                </td>
            </tr>
    )

}

export default WorkSearchModalRow;