import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";

import axios from "axios";
import { formatDate } from "../../utils";

class PrintView extends Component {
  dateFields = [
    "tax_expiry",
    "date_sold",
    "finish_date",
    "depreciation_start_date",
    "off_hire_date"
  ];
  typeFields = ["L1", "L2", "L3", "L4", "Car"];

  rates = [
    "first_day",
    "two_five_days",
    "six_twenty_seven_days",
    "twenty_eight_plus",
    "one_week",
    "six_twenty_seven_week",
    "long_term_weekly",
    "weekend"
  ];

  day = 1000 * 60 * 60 * 24;

  constructor(props) {
    super(props);
    this.state = {
      customer: {},
      vehicle: {},
      start_date: "",
      finish_date: "",
      start_mileage: 0
    };
  }

  static propTypes = {
    endpoint: PropTypes.string.isRequired
  };

  state = {
    customer: {},
    start_date: "",
    finish_date: ""
  };

  async UNSAFE_componentWillMount() {
    const { rental } = this.props.match.params;
    if (rental) {
      const promise = await axios.get("/api/rentals/" + rental);
      const status = promise.status;
      if (status === 200) {
        let data = promise.data;
        this.setState(data);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="address-table">
          <Columns>
            <Columns.Column>
              <img
                src="http://temp.sansaar.in/wp-content/uploads/2019/01/LOGO_RDLwase-1.jpg"
                width="220"
                height="148"
                alt="Rentals Direct Ltd"
              />
            </Columns.Column>
            <Columns.Column>
              <Table className="is-bordered address-table">
                <tbody className="is-small">
                  <tr>
                    <td className="is-centered">
                      Rentals Direct Ltd., JCR Unit 3, Cherry Tree Road,
                      Tibenham, Norfolk NR16 1PH
                    </td>
                  </tr>
                  <tr>
                    <td>Tel: 01953 665421</td>
                  </tr>
                  <tr>
                    <td>Email: mail@rentalsdirect4u.co.uk</td>
                  </tr>
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
        </div>
        <Box>
          <Heading size={4}>
            Rental Agreement - {this.state.customer.display_name} -{" "}
            {this.state.vehicle.registration}
          </Heading>
          <Columns>
            <Columns.Column>
              <Form.Control>
                <Form.Field>
                  <Form.Label>Start Date</Form.Label>
                  <Form.Input
                    type="date"
                    name="start_date"
                    value={this.state.start_date}
                    readOnly
                    required
                  />
                </Form.Field>
              </Form.Control>
            </Columns.Column>
            <Columns.Column>
              <Form.Control>
                <Form.Field>
                  <Form.Label>Return Date</Form.Label>
                  <Form.Input
                    type="date"
                    name="finish_date"
                    value={this.state.finish_date}
                    readOnly
                    required
                  />
                </Form.Field>
              </Form.Control>
            </Columns.Column>
          </Columns>
        </Box>
        <Columns>
          <Columns.Column>
            <Table className="is-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>Vehicle</th>
                  <th colSpan={2}>Driver</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>Registration</th>
                  <td>{this.state.vehicle.registration}</td>
                  <th>Name</th>
                  <td>{this.state.customer.display_name}</td>
                </tr>
                <tr>
                  <th>Make</th>
                  <td>{this.state.vehicle.make}</td>
                  <th>Address</th>
                  <td>{this.state.customer.address_line1}</td>
                </tr>
                <tr>
                  <th>Model</th>
                  <td>{this.state.vehicle.model}</td>
                  <th></th>
                  <td>{this.state.customer.town}</td>
                </tr>
                <tr>
                  <th>1 Day Rental</th>
                  <td>£{this.state.first_day}</td>
                  <th></th>
                  <td>{this.state.customer.postcode}</td>
                </tr>
                <tr>
                  <th>2-5 Day Rental</th>
                  <td>£{this.state.two_five_days}</td>
                  <th>Email</th>
                  <td>{this.state.customer.email}</td>
                </tr>
                <tr>
                  <th>6-27 Day Rental</th>
                  <td>£{this.state.six_twenty_seven_days}</td>
                  <th>Phone</th>
                  <td>{this.state.contact_number}</td>
                </tr>
                <tr>
                  <th>28+ Day Rental</th>
                  <td>£{this.state.twenty_eight_plus}</td>
                  <th>License Number</th>
                  <td>{this.state.licence_number}</td>
                </tr>
                <tr>
                  <th>6-27 Weekly Rental</th>
                  <td>£{this.state.six_twenty_seven_days * 7}</td>
                  <th>Period Held</th>
                  <td>{this.state.license_held}</td>
                </tr>
                <tr>
                  <th>Long Term Weekly</th>
                  <td>£{this.state.long_term_weekly}</td>
                  <th>NI Number</th>
                  <td>{this.state.ni_number}</td>
                </tr>
              </tbody>
            </Table>
            <Table className="is-bordered">
              <thead>
                <tr>
                  <th colSpan={2}>Insurance</th>
                  <th colSpan={2}>Payer (If Different From Driver)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Insurer</th>
                  <td>{this.state.insurer}</td>
                  <th>Name</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Daily Cover</th>
                  <td>{this.state.insurance_daily}</td>
                  <th>Address</th>
                  <td></td>
                </tr>
                <tr>
                  <th>Policy Name</th>
                  <td>{this.state.policy_name}</td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>Policy Number</th>
                  <td>{this.state.policy_number}</td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>Expiry</th>
                  <td>{this.state.insurance_expiry}</td>
                  <th>Phone</th>
                  <td></td>
                </tr>
              </tbody>
            </Table>
            <Table className="is-bordered">
              <thead>
                <tr>
                  <th colSpan={4}>Mileage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Start Mileage</th>
                  <td>{this.state.start_mileage}</td>
                  <th>Mileage Allowance</th>
                  <td>{this.state.mileage_allowance}</td>
                </tr>
                <tr>
                  <th>Excess Mileage Charge</th>
                  <td>{this.state.excess_mileage_charge}</td>
                  <th>Delivery Charge</th>
                  <td>{this.state.delivery_charge}</td>
                </tr>
                <tr>
                  <th>Comments</th>
                  <td colSpan={3}>{this.state.comments}</td>
                </tr>
              </tbody>
            </Table>
            {this.state.rental_type === "ST" && (
              <Table className="is-bordered">
                <thead>
                  <tr>
                    <th colSpan={4}>Short Term Hire</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th></th>
                    <th>Excluding VAT</th>
                    <th>Including VAT</th>
                  </tr>
                  <tr>
                    <th>Hire Charge</th>
                    <td>{this.state.total}</td>
                    <td>{this.state.total * 1.2}</td>
                  </tr>
                  <tr>
                    <th>Insurance</th>
                    <td>{this.state.insurance_daily * this.state.days}</td>
                    <td>N/A</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {this.state.rental_type === "LT" && (
              <Table className="is-bordered">
                <thead>
                  <tr>
                    <th>Long Term Hire</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>This Contract is For 12 Months</td>
                  </tr>
                  <tr>
                    <td>
                      Early Return Penalty Charge of £20 per week from the start
                      of the contract term
                    </td>
                  </tr>
                  <tr>
                    <td>
                      £25 allowance used against fair wear & tear for the term
                      of 12 months
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
            <Table className="is-bordered">
              <tbody>
                <tr>
                  <th>Driver Signature:</th>
                  <th>Rentals Direct Signature:</th>
                </tr>
                <tr>
                  <th>Print Name:</th>
                  <th>Print Name:</th>
                </tr>
              </tbody>
            </Table>
          </Columns.Column>
        </Columns>
        <Box>
          <Heading size={5}>Terms & Conditions</Heading>
          <p>
            I declare to the best of knowledge and belief that the particulars
            given in this declaration are correct and no information has been
            omitted, misrepresented or mis-stated, which might influence
            acceptance of the provision of a hire vehicle. I have inspected the
            vehicle and there is no damage other than specified on the Vehicle
            Inspection Sheet. I will make good any damage to the vehicle that is
            caused by unreasonable use as described in section 3 of our terms
            and conditions or not covered by this insurance as stated in the
            additional conditions section of our terms and Conditions. I
            acknowledge that during the currency of the agreement, I /we will be
            liable in respect of any fixed penalty notices, excess charges and
            the like which may be incurred under the Road Traffic Offences Act
            1988 and Road Traffic Act 1991 (both as amended or replaced from
            time to time) in accordance with condition 9 on our terms and
            conditions. In consideration of Rentals Direct Ltd making the
            vehicle available to me as a hire vehicle, I hereby agree to observe
            the terms and conditions which are set out in our office and
            available to me, should I request a printed copy.
          </p>
        </Box>
        <Columns>
          <Columns.Column>
            <Box>
              <Heading size={5}>Post Hire Return of Vehicles</Heading>
              <p>
                All vehicles need to be clean upon return OR they will need to
                be washed prior to us booking the vehicle back in. Our return
                times are Monday to Friday: 0730 to 1700 and Saturday 0800 to
                1300. Outside these hours the vehicle remains your
                responsibility until we have washed it and checked for damage.
                any additional damage found (other than already on the vehicle
                check sheet when signed out) is chargeable to you. You will be
                asked to sign the 'Post-Hire Disclaimer' to agree to the
                following statement. I hereby agree that I am responsible for
                any damage found after the vehicle is booked in'
              </p>
              <p>
                I CONFIRM THAT I AGREE WITH THE TERMS AND CONDITIONS FOR VEHICLE
                HIRE:
              </p>
              <Columns>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Signed</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" value="" readOnly />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Print Name</Form.Label>
                    <Form.Control>
                      <Form.Input type="text" value="" required readOnly />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Label>Date</Form.Label>
                    <Form.Control>
                      <Form.Input
                        type="date"
                        value={formatDate(new Date())}
                        readOnly
                      />
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
            </Box>
          </Columns.Column>
        </Columns>
        <Box>
          <Heading size={5}>Statement of Liability</Heading>
          <p>
            I agree that whilst this rental agreement is in force, i will be
            liable, as the hirer of this vehicle or any replacement vehicle for
            the following:<br></br>* Any Fixed Penalty Notice or Any Notice Of
            Intended Prosecution for driving offences<br></br>* Congestion
            Charges, Dart Charges or other toll fees<br></br>* Parking Charges
            or Fines<br></br>* Other Fines or Charges imposed during the period
            of hire<br></br>
          </p>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Signed</Form.Label>
                <Form.Control>
                  <Form.Input type="text" value="" required readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Print Name</Form.Label>
                <Form.Control>
                  <Form.Input type="text" value="" required readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Date</Form.Label>
                <Form.Control>
                  <Form.Input type="date" value={formatDate(new Date())} readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
        </Box>
        <Box>
          <Heading size={5}>IMPORTANT</Heading>
          <p>
            It is important that you have read and understood the terms and
            conditions that will apply to this contract before signing. * Only
            sign this agreement if you wish to be bound by the terms and
            conditions contract.<br></br>* Only sign this agreement if you wish
            to be bound by the terms and conditions above.<br></br>* Only sign
            this agreement if you confirm to make payment by debit / credit
            card, cash or bank transfer (subject to confirmation of receipt of
            funds).
          </p>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Signed</Form.Label>
                <Form.Control>
                  <Form.Input type="text" value="" required readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Print Name</Form.Label>
                <Form.Control>
                  <Form.Input type="text" value="" required readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Date</Form.Label>
                <Form.Control>
                  <Form.Input type="date" value={formatDate(new Date())} readOnly />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
        </Box>
      </div>
    );
  }
}
export default PrintView;
