import React from "react";
import {readableDate} from "../../utils";


export default function VehicleInspectionRow(inspection){
    return (
        <tr>
            <td data-testid={"inspection-type"}>{inspection.inspection.inspection_type}</td>
            <td data-testid={"inspection-workshop"}>{inspection.inspection.inspection_workshop ? inspection.inspection.inspection_workshop.display_name : ""}</td>
            <td data-testid={"inspection-expiry-date"}>{readableDate(inspection.inspection.expiry_date)}</td>
        </tr>
    )
}