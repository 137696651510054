import React from "react";
import { Table } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { readableDate, formatCurrency } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import { Columns } from "react-bulma-components";

class PrintTransactions extends React.Component {
  get suppliers() {
    const transactionValues = Object.values(this.props.data);
    const suppliers = {};

    for (let i = 0; i < transactionValues.length; i++) {
      if (transactionValues[i].contact) {
        if (!suppliers[transactionValues[i].contact.display_name]) {
          suppliers[transactionValues[i].contact.display_name] = {
            "invoice_amount": 0,
            "invoice_vat": 0
          };
        }
        suppliers[transactionValues[i].contact.display_name]['invoice_amount'] += transactionValues[i].invoice_amount;
        suppliers[transactionValues[i].contact.display_name]['invoice_vat'] += transactionValues[i].invoice_vat;
      }
    }
    return suppliers;
  }

  get totalCost() {
    return Object.values(this.suppliers).reduce((sum, next) => sum + next['invoice_amount'], 0);
  }

  get totalVAT() {
    let x = Object.values(this.suppliers).reduce((sum, next) => sum + next['invoice_vat'], 0)
    return (Math.round((x*100))/100);
  }

  get totalWithVAT() {
    return this.totalCost + this.totalVAT;
  }

  sortSuppliers(a, b) {
      let textA = a[0].toUpperCase();
      let textB = b[0].toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }


  TransactionRow(transaction) {
    return (
      <tr key={transaction.id}>
        <td>{transaction.id}</td>
        <td>{transaction.description}</td>
        <td>{transaction.contact ? transaction.contact.display_name : ""}</td>
        <td>
          {transaction.type}
        </td>
        <td>{transaction.cost_centre ? transaction.cost_centre.name : ""}</td>
        <td>{transaction.category ? transaction.category.name : ""}</td>
        <td>{formatCurrency(transaction.invoice_amount)}</td>
        <td>{formatCurrency(transaction.invoice_vat)}</td>
        <td>{formatCurrency(transaction.invoice_amount + transaction.invoice_vat)}</td>
        <td>{transaction.invoice_number}</td>
        <td>{readableDate(transaction.invoice_date)}</td>
        <td>{readableDate(transaction.payment_date)}</td>
        <td>{transaction.comments}</td>
      </tr>
    );
  }

  render() {
    return (
      <div className="print-background">
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <Heading>Transactions</Heading>
        </Columns>
        <Table>
          <thead>
            <tr>
              <th>Total Cost</th>
              <th>Total VAT</th>
              <th>Total With VAT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{formatCurrency(this.totalCost)}</td>
              <td>{formatCurrency(this.totalVAT)}</td>
              <td>{formatCurrency(this.totalWithVAT)}</td>
            </tr>
          </tbody>
        </Table>
        <Heading size={4}>Contact Summary</Heading>
        <Table>
          <thead>
            <tr>
              <th>Contact</th>
              <th>Total Costs</th>
              <th>Total VAT</th>
              <th>Total Costs + VAT</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(this.suppliers).sort(this.sortSuppliers).map(entry => (
              <tr>
                <td>{entry[0]}</td>
                <td>{formatCurrency(entry[1]['invoice_amount'])}</td>
                <td>{formatCurrency(entry[1]['invoice_vat'])}</td>
                <td>{formatCurrency(entry[1]['invoice_amount'] + entry[1]['invoice_vat'])}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Heading size={4}>Transactions Breakdown</Heading>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Description</th>
              <th>Contact</th>
              <th>Type</th>
              <th>Cost Centre</th>
              <th>Category</th>
              <th>Invc Amount</th>
              <th>Invc VAT</th>
              <th>Invc Total</th>
              <th>Invc Number</th>
              <th>Invc Date</th>
              <th>Payment Date</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(this.props.data)
              .sort((a, b) => {
                // sort by supplier then ordered date
                if (a.contact && b.contact) {
                  if (a.contact.display_name > b.contact.display_name) return 1;
                  if (a.contact.display_name < b.contact.display_name)
                    return -1;
                }

                return new Date(a.invoice_date) - new Date(b.invoice_date);
              })
              .map(entry => this.TransactionRow(entry))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default PrintTransactions;
