import React from "react";
import { Table } from "react-bulma-components";
import { formatDate, readableDate } from "../../utils";
import { Heading } from "react-bulma-components";

class PrintServiceRecord extends React.Component {
  get totalPartCost() {
    if (Object.entries(this.props.data).length < 1) return 0;

    return Object.entries(this.props.data).reduce((total, work) => {
      return (
        work[1].parts.reduce((work_total, part) => {
          return work_total + part.cost;
        }, 0) + total
      );
    }, 0);
  }

  get totalCost() {
    if (Object.entries(this.props.data).length < 1) return 0;
    return Object.entries(this.props.data).reduce((total, work) => {
      return total + work[1].labour_cost;
    }, 0);
  }

  get totalWithVAT() {
    return (this.totalCost + this.totalPartCost) * 1.2;
  }

  PartRow(part) {
    return (
      <tr key={part.id}>
        <td></td>
        <td></td>
        <td></td>
        <td>{part.name}</td>
        <td>
          £
          {(part.cost || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </td>
      </tr>
    );
  }

  WorkRow(work) {
    let rows = [];
    rows.push(
      <tr key={work.id} className="table-highlight-orange">
        <td>{readableDate(work.finish_date)}</td>
        <td>{work.description}</td>
        <td>
          £
          {(work.labour_cost || 0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </td>
        <td></td>
        <td></td>
      </tr>
    );
    rows = rows.concat(work.parts.map(part => this.PartRow(part)));
    return rows;
  }

  render() {
    return (
      <div className="print-background">
        <Heading>Service Record: {this.props.registration}</Heading>
        <Table>
          <thead>
            <tr>
              <td>Total Labour:</td>
              <td>Total Parts:</td>
              <td>Total Labour and Parts:</td>
              <td>Total Inc. VAT</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                £
                {(this.totalCost || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td>
                £
                {(this.totalPartCost || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
              <td>
                £
                {(this.totalPartCost + this.totalCost || 0).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }
                )}
              </td>
              <td>
                £
                {(this.totalWithVAT || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
              </td>
            </tr>
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Description</td>
              <td>Labour Cost</td>
              <td>Part Name</td>
              <td>Part Cost</td>
            </tr>
          </thead>
          <tbody>
            {Object.entries(this.props.data)
              .sort(
                (a, b) =>
                  new Date(a[1].finish_date) - new Date(b[1].finish_date)
              )
              .map(entry => this.WorkRow(entry[1]))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default PrintServiceRecord;
