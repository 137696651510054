import React from "react";
import {Button, Columns, Modal, Section} from "react-bulma-components";
import ContactSection from "../Contact/ContactSection";





export default function ContactModal({job, dataFresh, open, setOpen, handleSave, triggerChange, endpoint}){




    return (
        <Modal
          closeOnBlur
          show={open}
          onClose={() => {
            setOpen(false);
          }}
        >
            <Modal.Content>
                <Section style={{ backgroundColor: "white" }}>
                    <Columns>
                      <Columns.Column>
                        <ContactSection
                          title="Customer 1"
                          contactUpdate={customer => triggerChange({customer})}
                          endpoint={endpoint + "contacts"}
                          contact={job.customer || {}}
                          type="CC"
                          disabled={false}
                        />
                      </Columns.Column>
                    </Columns>
                    <Columns>
                      <Columns.Column>
                        <ContactSection
                          title="Customer 2"
                          contactUpdate={customer2 => triggerChange({customer2})}
                          endpoint={endpoint + "contacts"}
                          contact={job.customer2 || {}}
                          type="CC"
                          disabled={false}
                        />
                      </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                fullwidth={true}
                                onClick={handleSave}
                            >
                                Turn Into Quote
                            </Button>
                        </Columns.Column>
                        <Columns.Column>
                            <Button
                                color={"info"}
                                fullwidth={true}
                                onClick={() => {
                                    setOpen(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )




}