import Figure from "./Figure";
import { Columns } from "react-bulma-components";
import React from "react";

function TransactionsSummary({category, loaded, hideIncome, hideExpense}) {
  return (
    <>
      {!hideIncome &&
      <Figure
        key="income"
        label={`${category.name} Income`}
        loaded={loaded}
        value={
          loaded ? category.in : 0
        }
      />
      }
      {!hideExpense &&
      <Figure
        key="expense"
        label={`${category.name} Expenses`}
        loaded={loaded}
        value={
          loaded ? category.out : 0
        }
      />
      }
    </>)
}

export default TransactionsSummary;
