import React, {useEffect} from "react";
import { Table } from "react-bulma-components";
import { readableDate } from "../../utils";
import logo from "../Nav/img/Logo.jpg";
import { Columns } from "react-bulma-components";

class PrintPickList extends React.Component {

  render() {

    const job = this.props.data;
    const {vehicle} = job;
    const parts = job.work_items.reduce((allParts, workItem) => {
      const parts = workItem.parts.map((part) => {
        return {
          ...part,
          workDescription: workItem.description
        }
      });
      return [...allParts, ...parts];
    }, []);
    const totalPartRows = 15 - parts.length;
    let blankRows = [];
    for (let i = 0; i < totalPartRows; i++) {
      blankRows.push(
          <tr>
            <td>&nbsp;</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
      );
    }
    return (
      <div>
        <br/>
        <Columns>
          <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd" />
          <h2 className="job-card-print-title" data-testid={"title"}>
            {job.workshop && job.workshop.display_name} -{" "}
            {job.vehicle ? job.vehicle.registration : ""}{job.vehicle ? job.vehicle.vin_number ? '/' + job.vehicle.vin_number : "" : ""} - Workshop Date:{" "}
            {job.workshop_date && readableDate(job.workshop_date)}
          </h2>
        </Columns>

        <Columns>
          <Columns.Column>
            <div>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Customer</th>
                    <th>Customer2</th>
                    <th>Job ID</th>
                  </tr>
                </thead>
                <tr>
                  <td data-testid={"customer1"}>{job.customer ? job.customer.display_name : ""}</td>
                  <td data-testid={"customer2"}>{job.customer2 ? job.customer2.display_name : ""}</td>
                  <td data-testid={"job-id"}>{job.id}</td>
                </tr>
              </Table>
              <Table bordered size={"fullwidth"}>
                <thead>
                <tr>
                  <td colSpan={2} className="cell-header"><b>MAKE / MODEL:</b></td>
                  <td colSpan={4} data-testid={"vehicle-make/model"}>{vehicle ? vehicle.make ? vehicle.make.name : "" : ""} - {vehicle ? vehicle.model ? vehicle.model.name : "" : ""}</td>
                </tr>
                <tr className="header-row">
                  <td>Part Description</td>
                  <td>Part Number</td>
                  <td>Supplier</td>
                  <td>Part Stock</td>
                  <td>Part Used</td>
                  <td>Part Needs Replacing</td>
                </tr>
                </thead>
                <tbody>
                {
                  parts.map(({workDescription, ...part}, index) => <tr key={`${index}`}>
                    <td data-testid={"part-picklist-name"+index}>{part.name ?? ''}</td>
                    <td data-testid={"part-number"+index}>{part.part_number ?? ''}</td>
                    <td data-testid={"part-supplier"+index}>{part.supplier?.display_name ?? ''}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>)
                }
                {
                            blankRows.map(row => row)
                }
                </tbody>
              </Table>
            </div>
          </Columns.Column>
        </Columns>
      </div>
    );
  }
}

export default PrintPickList;
