import React from "react"
import {Picker} from "../Utils/Picker";


export default function VehicleModelPicker({onChange, vehicle, models, showTitle = true, disabled}){
    return (
        <Picker
            name={"model"}
            label={showTitle ? "Vehicle Model" : null}
            value={vehicle.model}
            blank={true}
            onChange={onChange}
            options={models}
            optionField={"name"}
            disabled={disabled}
        />
    )

}