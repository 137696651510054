import React from "react";
import { Heading } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import {formatCurrency} from "../../utils";

export default function RecurringPaymentPreview({
    intervalType,
    startDate,
    endDate,
    intervalDays,
    paymentAmount,
    maxRows
}) {
    const previewDates = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    let i = 0;

    // Iterate over dates until either max rows generated or end date is reached
    for(let date= new Date(startDate); (!endDate || (date <= end)) && i < maxRows; i++) {
        // push current date
        previewDates.push(date.toDateString());
        // add amount to date based on interval type
        switch(intervalType) {
            case 'WEEKLY':
                date.setDate(date.getDate() + 7);
                break;
            case 'MONTHLY':
                date.setMonth(date.getMonth() + 1);
                break;
            case 'YEARLY':
                date.setFullYear(date.getFullYear() + 1);
                break;
            case 'FIXED_DURATION':
                date.setDate(date.getDate() + (intervalDays * 1));
                break;
        }
    }

    return <Columns>
        <Columns.Column>
            <Heading size={6}>Dates Preview:</Heading>
            {previewDates.map(dateString => <div>{paymentAmount && `${formatCurrency(paymentAmount)} on `} {dateString}</div>)}
            <br/>
        </Columns.Column>
    </Columns>
}