import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";

export default function NotReadyForInvoiceModal({ open, setOpen, items }) {
  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Cannot Approve For Invoicing
          </Heading>
          <Columns>
            <Columns.Column>
              <p className="is-centered">
                The following items are not ready for invoicing:
              </p>
              <ul>
                {items.map(item => (
                  <li>{item.description}</li>
                ))}
              </ul>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
