import React from "react";



export default function PrintWashRow({job, index}){
    const pickCustomer = (job) =>{
        return job.customer2 ? job.customer2.display_name : job.customer.display_name
    }
    return(
        <div>
            <tr>
                <td data-testid={"vehicle-reg"+index}>{job.vehicle.registration}</td>
                <td data-testid={"vehicle-make"+index}>{job.vehicle.make ? job.vehicle.make.name : ""}</td>
                <td data-testid={"vehicle-model"+index}>{job.vehicle.model ? job.vehicle.model.name : ""}</td>
                <td data-testid={"customer"+index}>{pickCustomer(job)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </div>
    )





}