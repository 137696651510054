import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import useInput from "../../hooks/useInput";

export default function MultipleEditModal({
  open,
  setOpen,
  selected,
  saveSelected
}) {
  const [invoiceDate, invoiceDateInput, setInvoiceDate] = useInput({
    type: "date",
    label: "Invoice Date"
  });
  const [paymentDate, paymentDateInput, setPaymentDate] = useInput({
    type: "date",
    label: "Payment Date"
  });
  const [invoiceNumber, invoiceNumberInput, setInvoiceNumber] = useInput({
    type: "text",
    label: "Invoice Number"
  });
  const [invoiceAmount, invoiceAmountInput, setInvoiceAmount] = useInput({
    type: "number",
    label: "Invoice Amount",
    currency: true
  });

  const [invoiceVAT, invoiceVATInput, setInvoiceVAT] = useInput({
    type: "number",
    label: "Invoice VAT",
    currency: true
  });
  const [comments, commentsInput, setComments] = useInput({
    type: "text",
    label: "Comments"
  });

  const onSave = () => {
    saveSelected(invoiceDate, invoiceNumber, paymentDate, invoiceAmount, invoiceVAT, comments).then(onClose);
  };

  const onClose = () => {
    setOpen(false);
    setInvoiceNumber("");
    setPaymentDate("");
    setInvoiceDate("");
    setInvoiceAmount("");
    setInvoiceVAT("");
    setComments("");
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Columns>
            {invoiceNumberInput}
            {invoiceDateInput}
          </Columns>
          <Columns>
            {invoiceAmountInput}
            {invoiceVATInput}
          </Columns>
          <Columns>
            {paymentDateInput}
          </Columns>
          <Columns>
            {commentsInput}
          </Columns>
          <Form.Field>
            <p>You are editing {Object.keys(selected).length} parts</p>
          </Form.Field>
          <Button color="success" onClick={onSave}>
            Save
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
