import React, {useState} from "react";
import {readableDate} from "../../../../utils";
import useEditableTableDateCell from "../../../../hooks/useEditableTableDateCell";
import useSafeSave from "../../../../hooks/useSafeSave";
import axios from "axios";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../../state/selectors";

function OrderedRow({value, finance, activeTab, index, onVehicleUpdate, endpoint}) {
  const [lastEdited, setLastEdited] = useState();
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  const [editable, setEditable] = useState(false);
  const dateFields = ["ordered_date", "delivery_date", "deposit_allocated_date"];

  const fixEmptyDates = vehicleData => {
    dateFields.forEach(function (date) {
      if (vehicleData[date] === "") {
        vehicleData[date] = null;
      }
    });
    return vehicleData;
  };

  const checkUpdated = () => {
    return axios.get(endpoint + "vehicles/" + value.id).then(newVehicle => {
      return newVehicle.data.last_saved === value.last_saved;
    });
  };

  const submitUpdate = () => {
    const url = endpoint + "vehicles/" + value.id;
    const data = fixEmptyDates(value);
    const conf = {
      method: "put",
      data,
      url
    };

    return axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const [makeDirty, safeSave] = useSafeSave(
    submitUpdate,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newVeh = {...value};
    newVeh[e.target.name] = e.target.value;
    onVehicleUpdate(index, newVeh);
  };

  return (
    <tr
      className="clickable"
      onClick={() => window.open("/editvehicle/" + value.id, "_self")}
      key={value.id}
    >
      <td data-testid={"vehicle-ordernum"}>{value.order_number}</td>
      <td data-testid={"vehicle-registration"}>
        <span className="registration-field">
          {value.registration}
        </span>
      </td>
      {useEditableTableDateCell({
        propName: "month_due",
        value: value.month_due,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        monthOnly: true,
        inlineEdit: true
      })}
      <td data-testid={"vehicle-orderdate"}>{readableDate(value.ordered_date)}</td>
      {useEditableTableDateCell({
        propName: "delivery_date",
        value: value.delivery_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      <td data-testid={"vehicle-depositdate"}>{readableDate(value.deposit_allocated_date)}</td>
      <td data-testid={"vehicle-deposit"}>£{value.deposit_to_be_paid}</td>
      <td data-testid={"vehicle-make"}>{value.make ? value.make.name : ""}</td>
      <td data-testid={"vehicle-model"}>{value.model ? value.model.name : ""}</td>
      <td data-testid={"vehicle-variant"}>{value.variant}</td>
      <td data-testid={"vehicle-vin"}>{value.vin_number}</td>
      <td data-testid={"vehicle-supplier"}>{value.vehicle_supplier ? value.vehicle_supplier.display_name : "None Set"}</td>
      <td data-testid={"vehicle-purchaseprice"}>£{(value.ordered_purchase_price || 0).toFixed(2)}</td>
      <td data-testid={"vehicle-status"}>{value.status}</td>
      <td data-testid={"vehicle-reservecustomer"}>{value.reserve_customer ? value.reserve_customer.display_name : "None Set"}</td>
      <td data-testid={"vehicle-comments"}>{value.ordering_comments}</td>
    </tr>
)
}

export default OrderedRow;
