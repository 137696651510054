import React, {useState} from "react";
import shortid from "shortid";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";
import {
  daysInMonth, formatDate,
  getFreePeriodMonths,
  getMonthsBetween,
  getMonthsBetweenFormDates,
  getPartCustomerCost
} from "../../utils";

import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import {Icon} from "react-bulma-components";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import {getPaymentSchedule, getRemainingInterest} from "../../financeUtils";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function StockingPlanSection({updateFinanceAgreements, financeAgreement, index,}) {
  const [capturedEvent, setCapturedEvent] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const paymentSchedule = getPaymentSchedule(financeAgreement);

  const onChange = e => {
    const newFinanceAgreement = getNewFinanceAgreement(e);
    updateFinanceAgreements(newFinanceAgreement, index);
  };

  const getTotalRepayable = () => {
    if (financeAgreement.payment_term && financeAgreement.monthly_payment) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL") {
        return (
          paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0) +
          financeAgreement.payment_term * financeAgreement.monthly_payment
        );
      } else if (financeAgreement.finance_type === "REGULAR") {
        return financeAgreement.payment_term * financeAgreement.monthly_payment;
      }
    }
    return 0;
  };

  const getTotalInterest = () => {
    if (
      financeAgreement.payment_term &&
      financeAgreement.monthly_payment &&
      financeAgreement.finance_amount_borrowed
    ) {
      if (financeAgreement.finance_type === "FIXED_MONTHLY_CAPITAL" || financeAgreement.finance_type === "STOCKING_PLAN") {
        return paymentSchedule.reduce((a, b) => a + b.interest_accrued, 0);
      } else if (financeAgreement.finance_type === "REGULAR") {
        return (
          financeAgreement.payment_term * financeAgreement.monthly_payment -
          financeAgreement.finance_amount_borrowed
        );
      }
    }
    return 0;
  };

  const onMonthlyInterestChange = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    const annualInterest = e.target.value * 12;
    newFinanceAgreement.hp_interest_rate = annualInterest;

    updateFinanceAgreements(newFinanceAgreement, index)
    // dispatch(createSetVehicleDataAction(newVehicle));
  };

  const onCapitalChange = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};

    if (e.target.name === 'sp_monthly_capital_percentage') {
      newFinanceAgreement.monthly_payment = newFinanceAgreement.finance_amount_borrowed * (e.target.value * (0.01));
      newFinanceAgreement.monthly_payment = Math.round(newFinanceAgreement.monthly_payment * 100 + Number.EPSILON) / 100
    } else if (e.target.name === 'monthly_payment') {
      newFinanceAgreement.sp_monthly_capital_percentage = e.target.value / newFinanceAgreement.finance_amount_borrowed * 100;
      newFinanceAgreement.sp_monthly_capital_percentage = Math.round(newFinanceAgreement.sp_monthly_capital_percentage * 100 + Number.EPSILON) / 100
    }
    updateFinanceAgreements(newFinanceAgreement, index)
  };

  const onInterestChange = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};

    if (e.target.name === 'sp_monthly_interest_rate') {
      newFinanceAgreement.sp_yearly_interest_rate = e.target.value * 12;
      newFinanceAgreement.sp_monthly_interest_charge = newFinanceAgreement.finance_amount_borrowed ? newFinanceAgreement.finance_amount_borrowed * (e.target.value / 100) : 0;
      newFinanceAgreement.sp_monthly_interest_charge = Math.round(newFinanceAgreement.sp_monthly_interest_charge * 100 + Number.EPSILON) / 100
    } else if (e.target.name === 'sp_yearly_interest_rate') {
      newFinanceAgreement.sp_monthly_interest_rate = e.target.value / 12;
    }
    updateFinanceAgreements(newFinanceAgreement, index)
  };

  const onPurchasingChange = e => {
    let newAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    if (newAgreement.purchase_price && newAgreement.deposit_amount) {
      newAgreement.finance_amount_borrowed =
        newAgreement.purchase_price - newAgreement.deposit_amount;
    }
    updateFinanceAgreements(newAgreement, index)
  };

  const getEarlySettlementInterestCharges = () => {
    if (financeAgreement.finance_early_settlement_date) {
      const settlementAsDate = new Date(financeAgreement.finance_early_settlement_date);
      const days_in_month = daysInMonth(settlementAsDate.getMonth() + 1, settlementAsDate.getFullYear());
      const days_into_month = settlementAsDate.getDate();
      return financeAgreement.sp_monthly_interest_charge / days_in_month * days_into_month;
    }
    return 0;
  };

  const getTotalCapitalPayments = () => {
    const total = (getMonthsBetweenFormDates(financeAgreement.sp_capital_repayment_start_date, financeAgreement.sp_capital_repayment_end_date)) * financeAgreement.monthly_payment;
    return Math.round(total * 100 + Number.EPSILON) / 100
  };

  const getTotalInterestPayments = () => {
    const total = (getMonthsBetweenFormDates(financeAgreement.sp_interest_repayment_start_date, financeAgreement.sp_interest_repayment_end_date)) * financeAgreement.sp_monthly_interest_charge;
    return Math.round(total * 100 + Number.EPSILON) / 100
  };

  const getThisMonthsPaymentDate = () => {
    let newDate = new Date();
    if (financeAgreement.sp_first_payment_date && new Date(financeAgreement.sp_interest_repayment_end_date) > newDate && new Date(financeAgreement.sp_capital_repayment_end_date) > newDate && new Date(financeAgreement.balloon_payment_date) > newDate) {
      let daysInMonth = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate();

      const dayOfMonthPayment = new Date(financeAgreement.sp_first_payment_date).getDate();

      if (daysInMonth < dayOfMonthPayment) {
        newDate.setDate(daysInMonth)
      } else {
        newDate.setDate(dayOfMonthPayment);
      }
      return formatDate(newDate);
    } else {
      return ""
    }
  };

  const getRemainingCapitalPayments = () => {
    if (financeAgreement.sp_capital_repayment_start_date && financeAgreement.sp_capital_repayment_end_date && financeAgreement.monthly_payment && (financeAgreement.balloon_payment || financeAgreement.balloon_payment === 0)) {
      const today = new Date();

      if (financeAgreement.balloon_payment_date < today) {
        return 0
      }

      const startDataAsDate = new Date(financeAgreement.sp_capital_repayment_start_date);
      const startDate = new Date() > startDataAsDate ? new Date : startDataAsDate;
      let total = (getMonthsBetween(startDate, new Date(financeAgreement.sp_capital_repayment_end_date))) * financeAgreement.monthly_payment;
      total = total < 0 ? 0 : total;
      return 1 * financeAgreement.balloon_payment + Math.round(total * 100 + Number.EPSILON) / 100
    } else {
      return 0;
    }
  };

  const getRemainingInterestPayments = () => {
    const startDataAsDate = new Date(financeAgreement.sp_interest_repayment_start_date);
    const startDate = new Date() > startDataAsDate ? new Date : startDataAsDate;
    let total = (getMonthsBetween(startDate, new Date(financeAgreement.sp_interest_repayment_end_date))) * financeAgreement.sp_monthly_interest_charge;
    // 0 if negative
    total = total < 0 ? 0 : total;
    return Math.round(total * 100 + Number.EPSILON) / 100
  };

  const getNewFinanceAgreement = e => {
    let newFinanceAgreement = {...financeAgreement, [e.target.name]: e.target.value};
    return newFinanceAgreement;
  };

  const onCheck = propName => {
    const newFinanceAgreement = getNewFinanceAgreement({
      target: {
        name: propName,
        value: !financeAgreement[propName]
      }
    });
    updateFinanceAgreements(newFinanceAgreement, index);
  };

  return (
    <Box>
      <div>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Deposit</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="deposit_amount"
                  onChange={onPurchasingChange}
                  value={String(financeAgreement.deposit_amount)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Amount Borrowed</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="finance_amount_borrowed"
                  onChange={onChange}
                  value={String(financeAgreement.finance_amount_borrowed)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>
                Monthly Capital %
              </Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="sp_monthly_capital_percentage"
                  onChange={onCapitalChange}
                  value={String(financeAgreement.sp_monthly_capital_percentage)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>
                Monthly Capital Payment
              </Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="monthly_payment"
                  onChange={onCapitalChange}
                  value={String(financeAgreement.monthly_payment)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Finance Start Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="finance_start_date"
                  onChange={onChange}
                  value={financeAgreement.finance_start_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          {/*<Columns.Column>*/}
          {/*  <Form.Field>*/}
          {/*    <Form.Label>{financeAgreement.finance_type === "STOCKING_PLAN" ? "Payment Months (exc. balloon payment)" : "Payment Term (Months) "}</Form.Label>*/}
          {/*    <Form.Control>*/}
          {/*      <Form.Input*/}
          {/*        type="number"*/}
          {/*        name="payment_term"*/}
          {/*        onChange={onChange}*/}
          {/*        value={String(financeAgreement.payment_term)}*/}
          {/*      />*/}
          {/*    </Form.Control>*/}
          {/*  </Form.Field>*/}
          {/*</Columns.Column>*/}
          <Columns.Column>
            <Form.Field>
              <Form.Label>Capital Repayment Start Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_capital_repayment_start_date"
                  onChange={onChange}
                  value={financeAgreement.sp_capital_repayment_start_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Capital Repayment End Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_capital_repayment_end_date"
                  onChange={onChange}
                  value={financeAgreement.sp_capital_repayment_end_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Capital Repayment Free Period Months</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getFreePeriodMonths(financeAgreement.finance_start_date, financeAgreement.sp_capital_repayment_start_date))}
                  disabled
                  readOnly
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Capital Repayment Months Excluding Balloon Payment</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getMonthsBetweenFormDates(financeAgreement.sp_capital_repayment_start_date, financeAgreement.sp_capital_repayment_end_date))}
                  disabled
                  readOnly
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Total Capital Repayments</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getTotalCapitalPayments())}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Balloon Payment</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="balloon_payment"
                  onChange={onPurchasingChange}
                  value={String(financeAgreement.balloon_payment)}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Balloon Payment Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="balloon_payment_date"
                  onChange={onChange}
                  value={financeAgreement.balloon_payment_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>
                Monthly Interest %
              </Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="sp_monthly_interest_rate"
                  onChange={onInterestChange}
                  value={String(financeAgreement.sp_monthly_interest_rate)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>
                Yearly Interest %
              </Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="sp_yearly_interest_rate"
                  onChange={onInterestChange}
                  value={String(financeAgreement.sp_yearly_interest_rate)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>
                Monthly Interest Charge
              </Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="sp_monthly_interest_charge"
                  value={String(financeAgreement.sp_monthly_interest_charge)}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Interest Start Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_interest_accrual_start_date"
                  onChange={onChange}
                  value={financeAgreement.sp_interest_accrual_start_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Interest Repayment Start Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_interest_repayment_start_date"
                  onChange={onChange}
                  value={financeAgreement.sp_interest_repayment_start_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Interest Repayment End Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_interest_repayment_end_date"
                  onChange={onChange}
                  value={financeAgreement.sp_interest_repayment_end_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Interest Repayment Free Period Months</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getFreePeriodMonths(financeAgreement.finance_start_date, financeAgreement.sp_interest_repayment_start_date))}
                  disabled
                  readOnly
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Interest Repayment Months</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getMonthsBetweenFormDates(financeAgreement.sp_interest_repayment_start_date, financeAgreement.sp_interest_repayment_end_date))}
                  disabled
                  readOnly
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Total Interest Repayments</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(getTotalInterestPayments())}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>First Payment Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="sp_first_payment_date"
                  onChange={onChange}
                  value={financeAgreement.sp_first_payment_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>

          <Columns.Column>
            <Form.Field>
              <Form.Label>Finance Company</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="finance_company"
                  onChange={onChange}
                  value={financeAgreement.finance_company}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Agreement Number</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="agreement_no"
                  onChange={onChange}
                  value={String(financeAgreement.agreement_no) || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Finance Settlement Amount Paid</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="finance_early_settlement_amount_paid"
                  onChange={onChange}
                  value={String(
                    financeAgreement.finance_early_settlement_amount_paid || ""
                  )}
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Finance Early Settlement Date</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="finance_early_settlement_date"
                  onChange={onChange}
                  value={financeAgreement.finance_early_settlement_date || ""}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          {financeAgreement.finance_type !== "REGULAR" && financeAgreement.finance_type !== "LEASE_AGREEMENT" && (
            <Columns.Column>
              <Form.Field>
                <Form.Label>This Months Payment</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="text"
                    value={
                      financeAgreement.this_months_payment
                        ? financeAgreement.this_months_payment
                        : 0.0
                    }
                    disabled
                    readOnly
                  />
                  <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          )}
          {financeAgreement.finance_type !== "REGULAR" && financeAgreement.finance_type !== "LEASE_AGREEMENT" && (
            <Columns.Column>
              <Form.Field>
                <Form.Label>This Months Payment Date</Form.Label>
                <Form.Control>
                  <Form.Input
                    type="date"
                    value={
                      financeAgreement.sp_first_payment_date
                        ? getThisMonthsPaymentDate()
                        : ""
                    }
                    disabled
                    readOnly
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          )}
          <Columns.Column>
            <Form.Field>
              <Form.Label>Remaining Capital</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  value={(getRemainingCapitalPayments() || 0.0).toFixed(2)}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Remaining Interest</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  value={(getRemainingInterestPayments() || 0.0).toFixed(2)}
                  // value={getRemainingInterest(vehicle, new Date(Date.now()) || 0.0)}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Outstanding Balance</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  value={financeAgreement.remaining_balance || 0.0}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          {financeAgreement.finance_early_settlement_date &&
          <Columns.Column>
            <Form.Field>
              <Form.Label>Early Settlement Charges</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  value={
                    ((financeAgreement.remaining_balance - (getRemainingInterestPayments() || 0.0)) - financeAgreement.finance_early_settlement_amount_paid || 0.0).toFixed(2)
                  }
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          }
          {financeAgreement.finance_early_settlement_date &&
          <Columns.Column>
            <Form.Field>
              <Form.Label>Potential Overpayment</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  value={
                    ((financeAgreement.remaining_balance - (getRemainingInterestPayments() || 0.0)) - financeAgreement.finance_early_settlement_amount_paid | 0.0) < (financeAgreement.monthly_payment * -1) ? financeAgreement.monthly_payment.toFixed(2) : 0.0.toFixed(2)
                  }
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          }
        </Columns>
      </div>
    </Box>
  );
}

export default StockingPlanSection;
