import React from "react";
import {Button, Columns, Heading, Modal, Section} from "react-bulma-components";




export default function WorkshopInstructions({contact1, contact2, open, setOpen, filterInstructions}){


    return(
        <Modal
          closeOnBlur
          show={open}
          onClose={() => {
            setOpen(false);
          }}
        >
            <Modal.Content>
                <Section style={{ backgroundColor: "white" }}>
                    <Heading>Instructions</Heading>
                    <Columns>
                        <Columns.Column>
                            <h2>{contact1 ? contact1.display_name : ""} Instructions</h2>
                            <ul>
                                {contact1 && contact1.id && contact1.workshop_instructions.filter((instruction) => filterInstructions(instruction)).map((instruction) => (
                                    <li>{instruction.text}</li>
                                ))}
                            </ul>
                        </Columns.Column>
                        <Columns.Column>
                            <h2>{contact2 ? contact2.display_name : ""} Instructions</h2>
                            <ul>
                                {contact2 && contact2.id && contact2.workshop_instructions.filter((instruction) => filterInstructions(instruction)).map((instruction) => (
                                    <li>{instruction.text}</li>
                                ))}
                            </ul>
                        </Columns.Column>
                    </Columns>
                    <Button
                        color={"info"}
                        onClick={() => setOpen(false)}
                        fullwidth={true}
                    >
                        Close
                    </Button>
                </Section>
            </Modal.Content>
        </Modal>
    )


}