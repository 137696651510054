import React from "react";
import {Form, Button} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction, createSetDepartmentsDataAction} from "../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../state/selectors";



function DepartmentRow(department, endpoint){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);

    const onDelete = () => {
        const url = "api/departments/" + department.department.id;
        const conf = { method: "delete", url };
        axios(conf).then(response =>
            dispatch(createSetDataFreshAction(dataFresh + 1))
        );
    };

    const save = () => {
        const url = "api/departments/"+department.department.id;
        const conf = {method: "put", url, data:department.department}
        axios(conf). then(response => {
            dispatch(createSetDataFreshAction(dataFresh + 1))
        });
    }
    const onCheck = (e) => {
        department.department[e.target.name] = !department.department[e.target.name];
        save()
    };
    return (
        <tr>
            <td>{department.department.id}</td>
            <td>{department.department.name}</td>
            <td>
                <Form.Control>
                    <Form.Checkbox
                        type={"checkbox"}
                        name={"hide"}
                        checked={department.department.hide}
                        onChange={(e) => {
                            onCheck(e)
                        }}
                    />
                </Form.Control>
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    );
}
export default DepartmentRow;