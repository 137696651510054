import React from "react";
import {Button, Image} from "react-bulma-components";



export default function ImageDisplay({imageList, dataFresh, fileList, onImageDelete, displayHeader=true}){

    const getInternalUrl = (fileList) => {
        let urlList = []
        for(let i=0;i<fileList.length;i++){
          let file = fileList[i];
          let url = URL.createObjectURL(file);
          urlList.push({"url": url, "type": file.type});
        }

        return urlList;
    }
    let urlList = []
    if(fileList){
        urlList = getInternalUrl(fileList);
    }
    else{
        urlList = imageList;
    }

    return (
        <div>
            {displayHeader && urlList && urlList.length < 1 && (<h2>NO ASSOCIATED IMAGES</h2>)}
            {displayHeader && urlList && urlList.length > 0 && (<h2>Uploaded Images</h2>)}
            {
              urlList && urlList.map((image, index) => {
                  if(image.type === "application/pdf"){
                      return (
                          <div>
                              <iframe src={image.url} title={"defect-image"} className={"has-addons"}/>
                              <Button
                                  color={"danger"}
                                  onClick={(e) => {
                                      onImageDelete(e, index)
                                  }}
                              >
                                  X
                              </Button>
                          </div>
                      )
                  }
                  else {
                      return (
                          <div>
                              <Image src={image.url} className={"has-addons resizeable"}/>
                              <Button
                                  color={"danger"}
                                  onClick={(e) => {
                                      onImageDelete(e, index)
                                  }}
                              >
                                  X
                              </Button>
                          </div>
                      )
                  }

                })
            }
        </div>
    )

}