import React from "react";
import { useVehicleData } from "../../hooks/useVehicleData";
import Form from "./Form";
import { getDataFresh } from "../../state/selectors";
import { useSelector } from "react-redux";
import { useInspectionTypeData } from "../../hooks/useInspectionTypeData";
import { useVehicleTypeData } from "../../hooks/useVehicleTypeData";
import { useDefaultsData } from "../../hooks/useDefaultsData";
import {useKeysData} from "../../hooks/useKeysData";
import {useVehicleMakeData} from "../../hooks/useVehicleMakeData";
import {useVehicleModelData} from "../../hooks/useVehicleModelData";

function VehicleForm(props) {
  let dataFresh = useSelector(getDataFresh);
  let vehicle_loaded = useVehicleData(props.match.params.vehicle, dataFresh);
  let inspection_types_loaded = useInspectionTypeData({});
  let vehicle_types_loaded = useVehicleTypeData({page_size: 100});
  let app_defaults_loaded = useDefaultsData({});
  let keys_loaded = useKeysData({})
  const vehicle_make_loading = useVehicleMakeData({page_size: 100}, dataFresh);
  const vehicle_model_loading = useVehicleModelData({page_size: 1000}, dataFresh);
  return (
    <Form
      loaded={
        vehicle_loaded &&
        inspection_types_loaded &&
        vehicle_types_loaded &&
        app_defaults_loaded &&
        keys_loaded &&
        vehicle_make_loading &&
        vehicle_model_loading
      }
      endpoint={props.endpoint}
      history={props.history}
    />
  );
}

export default VehicleForm;
