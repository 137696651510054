import React, {useState, useEffect} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import { Button } from "react-bulma-components";
import useSafeSave from "../../hooks/useSafeSave";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useEditableTableDateCell from "../../hooks/useEditableTableDateCell";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import useEditableTableDropdownCell from "../../hooks/useEditableTableDropdownCell";
import {intervalTypes, paymentTypes} from "./types";
import useTypeaheadInput from "../../hooks/useTypeaheadInput";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import useEditableTableTypeaheadCell from "../../hooks/useEditableTableTypeaheadCell";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";

export default function RecurringPaymentRow({recurringPayment, endpoint, updateValue, costCentres, costCentreObjects}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [contact, contactInput, setContact] = useTypeaheadInput({
    label: "Contact",
    placeholder: "Search Contact",
    searchUrl: "/api/contacts?contact_type=SP",
    initialValue: recurringPayment.contact
  });

  useEffect(() => {
    if(contact) {
      let newRecurringPayment = {...recurringPayment}
      newRecurringPayment.contact = contact;
      updateValue(newRecurringPayment);
    } else {
      updateValue({
        ...recurringPayment,
        contact: null
      })
    }
  }, [contact])

  const onSave = e => {
    const url = endpoint + "recurringpayment/" + recurringPayment.id;
    const conf = {
      method: "put",
      data: recurringPayment,
      url
    };
    setEditable(false);
    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };


  const handleDelete = e => {
    const url = endpoint + "recurringpayment/" + recurringPayment.id;
    const conf = {
      method: "delete",
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  }

  const checkUpdated = () => {
    return axios
      .get(endpoint + "recurringpayment/" + recurringPayment.id)
      .then(
        response => true
      );
  };

  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const onCostCentreChange = e => {
    const toUpdate = {
      target: {
        name: "cost_centre",
        value: costCentreObjects[e.target.value]
      }
    };
    onChange(toUpdate)
  }
  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newRecurringPayment = {...recurringPayment};
    newRecurringPayment[e.target.name] = e.target.value;
    updateValue(newRecurringPayment);
  };

  const saveAfterButtonClick = () => {
    const url = endpoint + "recurringpayment/" + recurringPayment.id;
    const conf = {
      method: "put",
      data: recurringPayment,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    recurringPayment[prop] = !recurringPayment[prop];
    saveAfterButtonClick();
  };

  const getInvervalTypeOption = (intervalTypeId) =>
    intervalTypes.find(option => option.id === intervalTypeId);

  const getPaymentTypeOption = (paymentTypeId) =>
      paymentTypes.find(option => option.id === paymentTypeId);

  const onIntervalTypeChange = (e) => {
    const interval_type = e.target.value;
    updateValue({
      ...recurringPayment,
      interval_type,
      interval_day: null,
    })
  };

  const onPaymentTypeChange = (e) => {
    const payment_type = e.target.value;
    updateValue({
      ...recurringPayment,
      payment_type
    })
  };

  const onContactChange = (e) => {
    const contact = e.target.value;
    updateValue({
      ...recurringPayment,
      contact
    })
  }

  return (
    <tr key={recurringPayment.id}>
      {useEditableTableTypeaheadCell({
          propName: "contact",
          value: recurringPayment.contact,
          onChange: onContactChange,
          onSave: safeSave,
          editable,
          setEditable,
          placeholder: "Search Contacts",
          searchUrl: "/api/contacts",
          inlineEdit: true,
          labelKey: "display_name",
      })}
      {useEditableTableDropdownCell({
            propName: "payment_type",
            value: getPaymentTypeOption(recurringPayment.payment_type),
            onChange: onPaymentTypeChange,
            onSave: safeSave,
            options: paymentTypes,
            editable,
            setEditable,
            inlineEdit: true
          }
      )}
      {useEditableTableDropdownCell({
            propName: "interval_type",
            value: getInvervalTypeOption(recurringPayment.interval_type),
            onChange: onIntervalTypeChange,
            onSave: safeSave,
            options: intervalTypes,
            editable : false,
            setEditable,
            inlineEdit: false
          }
      )}
      {useEditableTableNumberCell({
            propName: "interval_day",
            value: recurringPayment.interval_day,
            onChange: onChange,
            onSave: safeSave,
            options: intervalTypes,
            editable: false,
            setEditable,
            inlineEdit: false,
            replaceInput: recurringPayment.interval_type !== 'FIXED_DURATION' ? <></> : false
          }
      )}
      {useEditableTableCostCell({
        propName: "interval_payment_amount",
        value: recurringPayment.interval_payment_amount,
        onChange: onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDropdownCell({
        propName: "cost_centre",
        value: recurringPayment.cost_centre,
        options: costCentres,
        onChange: onCostCentreChange,
        onSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "start_date",
        value: recurringPayment.start_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableDateCell({
        propName: "end_date",
        value: recurringPayment.end_date,
        onChange,
        onSave: safeSave,
        editable,
        setEditable,
        inlineEdit: true
      })}
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: recurringPayment.comments,
        onChange,
        onSave: safeSave,
        inlineEdit: true,
        editable,
        setEditable
      })}

      <td className="float-right">
        <Button
          color="danger"
          data-testid={`submit-part-delete${recurringPayment.id}`}
          onClick={setDeleteModalOpen}
        >
          Delete
        </Button>
      </td>
      <DeleteWarningModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        onDelete={handleDelete}
      />
    </tr>
  );
}
