import React, { Component } from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

class RatesSection extends Component {
  state = { total: 0 };

  day = 1000 * 24 * 60 * 60;

  render(props) {
    return (
      <Box>
        <Heading>Rates</Heading>
        <Form.Field className="has-addons">
          <Form.Control>
            <Button
              color={this.props.rental.rental_type === "LT" ? "primary" : ""}
              onClick={() =>
                this.props.onChange({
                  target: { name: "rental_type", value: "LT" }
                })
              }
            >
              Long Term Hire
            </Button>
            <Button
              color={this.props.rental.rental_type === "ST" ? "primary" : ""}
              onClick={() =>
                this.props.onChange({
                  target: { name: "rental_type", value: "ST" }
                })
              }
            >
              Short Term Hire
            </Button>
          </Form.Control>
        </Form.Field>
        {this.props.rental.rental_type === "ST" && (
          <div>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Single Day Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="first_day"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.first_day)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
                      <FontAwesomeIcon icon={faPoundSign}/>
                    </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>Weekend</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="Weekend"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.two_five_days * 2)}
                      disabled
                      readOnly
                    />
                    <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>2-5 Day Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="two_five_days"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.two_five_days)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>1 Week Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="one_week"
                      onChange={this.props.onChange}
                      value={String(
                        1 * this.props.rental.first_day +
                          this.props.rental.two_five_days * 4 +
                          this.props.rental.six_twenty_seven_days * 2
                      )}
                      disabled
                      readOnly
                    />
                    <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>6-27 Day Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="six_twenty_seven_days"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.six_twenty_seven_days)}
                    />
                    <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>6-27 Days Weekly Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="six_twenty_seven_week"
                      onChange={this.props.onChange}
                      value={String(
                        this.props.rental.six_twenty_seven_days * 7
                      )}
                      readOnly
                      disabled
                    />
                    <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Label>28 Day + Hire</Form.Label>
                  <Form.Control>
                    <Form.Input
                      type="number"
                      name="twenty_eight_plus"
                      onChange={this.props.onChange}
                      value={String(this.props.rental.twenty_eight_plus)}
                    />
                  </Form.Control>
                  <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </div>
        )}
        {this.props.rental.rental_type === "LT" && (
          <div>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Field>
                    <Form.Label>Long Term Weekly</Form.Label>
                    <Form.Control>
                      <Form.Input
                        type="number"
                        name="long_term_weekly"
                        onChange={this.props.onChange}
                        value={String(this.props.rental.long_term_weekly)}
                      />
                      <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
                    </Form.Control>
                  </Form.Field>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </div>
        )}
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Additional Costs</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="additional_cost"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.additional_cost)}
                />
                <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Excess Mileage Cost (£ per mile)</Form.Label>
              <Form.Control>
                <Form.Input
                  name="excess_mileage_charge_per_mile"
                  type="number"
                  value={String(
                    this.props.rental.excess_mileage_charge_per_mile
                  )}
                  onChange={this.props.onChange}
                />
                <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Total</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  value={String(this.props.rental.total)}
                  disabled
                  readOnly
                />
                <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default RatesSection;
