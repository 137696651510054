import React from "react";
import {Columns, Table} from "react-bulma-components";
import FAIcon from "../Icon/FAIcon";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {readableDate} from "../../utils";




class SalesPurchaseForm extends React.Component{


    render(){
        const finance_length = this.props.data.finance_agreements.length;
        const mostRecentFinance = this.props.data.finance_agreements[finance_length-1];
        const payableInterest = (mostRecentFinance ?
            ((mostRecentFinance.payment_term * mostRecentFinance.monthly_payment) - mostRecentFinance.finance_amount_borrowed).toFixed(2)
            : "");
        return (
            <div className="print-background">
                <Columns>
                    <Columns.Column>

                        <Table bordered size={"fullwidth"} className={"page-break"}>
                            <tbody>
                            <tr>
                                <th colSpan={6} className={"table-header-bold fill-grey border-black small-text"}>SALES AND PURCHASE FORM</th>
                            </tr>
                            <tr>
                                <th className="table-header-bold fill-grey border-black small-text">FLEET NUMBER</th>
                                <td colSpan={2} width={"30%"} className={"border-black small-text"} data-testid={"fleet-number"}>{this.props.data.fleet_number}</td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>NOMINAL CODE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DATE EXPECTED</th>
                                <td colSpan={2} className={"border-black small-text"} data-testid={"delivery-date"}>{readableDate(this.props.data.delivery_date)}</td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SUPPLIER</th>
                                <td colSpan={2} className={"border-black small-text"}>{this.props.data.vehicle_supplier ? this.props.data.vehicle_supplier.display_name : ""}</td>
                            </tr>
                            <tr>
                                <th colSpan={6} className={"table-header-bold fill-grey border-black small-text"}>VEHICLE ALLOCATION</th>
                            </tr>
                            <tr>
                                <th className={"table-header-bold narrow-column fill-grey border-black small-text"}>Registration</th>
                                <td className={"border-black small-text"} data-testid={"registration"}>{this.props.data.registration}</td>
                                <td className={"narrow-column border-black small-text"}></td>
                                <th className={"table-header-bold narrow-column fill-grey border-black small-text"}>Chassis Number</th>
                                <td className={"border-black small-text"} data-testid={"vin"}>{this.props.data.vin_number}</td>
                                <td className={"narrow-column border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MAKE</th>
                                <td className={"border-black small-text"} data-testid={"make"}>{this.props.data.make ? this.props.data.make.name  : ""}</td>
                                <td className={"narrow-column border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FIRST REG. DATE</th>
                                <td className={"border-black small-text"} data-testid={"first-reg-date"}>{readableDate(this.props.data.first_registration_date)}</td>
                                <td className={"narrow-column border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MODEL</th>
                                <td className={"border-black small-text"} data-testid={"model"}>{this.props.data.model ? this.props.data.model.name : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MOT DUE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TYPE</th>
                                <td className={"border-black small-text"} data-testid={"type-name"}>{this.props.data.type ? this.props.data.type.name : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TAX DUE / COST</th>
                                <td className={"border-black small-text"} data-testid={"tax-cost"}>{"£"+this.props.data.tax_cost}</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SERIVCE INTERVAL</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>WARRANTY</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={"fill-grey border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>PURCHASE PRICE</th>
                                <td className={"border-black small-text"} data-testid={"purchase-price"}>{mostRecentFinance ? "£" + mostRecentFinance.purchase_price : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DEPOSIT</th>
                                <td className={"border-black small-text"} data-testid={"deposit-amount"}>{mostRecentFinance ? "£" + mostRecentFinance.deposit_to_be_paid : ""}</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FINANCE COMPANY</th>
                                <td className={"border-black small-text"} data-testid={"finance-company"}>{mostRecentFinance ? mostRecentFinance.finance_company : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>AGREEMENT NUMBER</th>
                                <td className={"border-black small-text"} data-testid={"agreement-no"}>{mostRecentFinance ? mostRecentFinance.agreement_no : ""}</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>AMOUNT BORROWED</th>
                                <td className={"border-black small-text"} data-testid={"amount-borrowed"}>{mostRecentFinance ? "£" + mostRecentFinance.finance_amount_borrowed : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MONTHLY AMOUNT</th>
                                <td className={"border-black small-text"} data-testid={"monthly-payment"}>{mostRecentFinance ? "£" + mostRecentFinance.monthly_payment : ""}</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TERM</th>
                                <td className={"border-black small-text"} data-testid={"payment-term"}>{mostRecentFinance ? mostRecentFinance.payment_term + " MONTHS" : ""}</td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>INTEREST PAYABLE</th>
                                <td className={"border-black small-text"} data-testid={"payable-interest"}>{payableInterest ? "£" + payableInterest : payableInterest}</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={"table-header-bold fill-grey border-black small-text"}>ANCILLARY DETAILS</td>
                            </tr>
                            <tr>
                                <td className={"fill-grey border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>ON</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>OFF</th>
                                <td className={"fill-grey border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>ON</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>OFF</th>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>QBE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>JOB CARD</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DAILY INSURE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>KEY NO.</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TRACKER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FUEL</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CAMERA</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SLAM LOCKS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FLOOR MATS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FIRE EXTINGUISHER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>RENTALS APP.</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>WARNING STICKERS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CONTROL SHEET</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SEAT COVERS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <td colSpan={6} className={"table-header-bold fill-grey border-black small-text"}>SALE DETAILS</td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>INVOICE NO.</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>V5 FILLED OUT</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SAGE INV NO.</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MOT CERTIFICATE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SALE PRICE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SPARE KEYS</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SOLD TO</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE PACK</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SALES DATE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DVLA BOOK OFF</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DEPR REPORT</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>HP REPORT</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DISPOSAL DONE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CONTINUE MAINTAINANCE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            </tbody>
                        </Table>
                        <Table bordered size={"fullwidth"}>
                            <tbody>
                            <tr>
                                <th colSpan={3} className={"table-header-bold fill-grey border-black small-text"}>CHECKSHEET</th>
                                <th colSpan={1} className={"table-header-bold fill-grey border-black small-text"}>MILEAGE:</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <td className={"fill-grey border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CHECK</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>COMMENTS</th>
                                <td className={"fill-grey border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CHECK</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>COMMENTS</th>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>KEY / NUMBER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>OIL TYPE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SPARE KEY</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>MATS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SLAM LOCK / NUMBER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TOOL KIT</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TYRE SIZE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TYRE TREAD & SIDEWALL</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>RADIO CODE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SPARE WHEEL</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>SERVICE PACK</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>AERIAL (IF APPLICABLE)</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CAMERA</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>GLASS / MIRRORS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>LOG BOOK</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CHECK ROOF</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>ANTI DRILL PLATE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>PLY-LINING</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>WINDOW STICKERS</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>LOCKING WHEEL NUT</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>FIRE EXTINGUISHER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>ANCILLARY EQUIPMENT</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>BODY SERIAL NUMBER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <th className={"table-header-bold fill-grey border-black small-text"}>COPY OF DELIVERY NOTE</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>BODYWORK ALL ROUND</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"fill-grey border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TAIL-LIFT SERIAL NUMBER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"fill-grey border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>TRACKER</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"fill-grey border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>GROSS VEHICLE WEIGHT</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"fill-grey border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>PAYLOAD</th>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"fill-grey border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th colSpan={4} className={"table-header-bold fill-grey border-black small-text"}>DEFECTS / ISSUES ON ARRIVAL</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>REPORT TO</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>ACTION</th>
                            </tr>
                            <tr>
                                <td colSpan={4} className={"wide-cell border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <td colSpan={4} className={"wide-cell border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th colSpan={5} className={"table-header-bold fill-grey border-black small-text"}>DRIVER / CHECKERS DETAILS</th>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DATE</th>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>DELIVERY DRIVERS SIGNATURE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <td colSpan={2} className={"border-black small-text"}>NAME:</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th className={"table-header-bold fill-grey border-black small-text"}>CHECKERS SIGNATURE</th>
                                <td colSpan={2} className={"border-black small-text"}></td>
                                <td colSpan={2} className={"border-black small-text"}>PRINT NAME:</td>
                                <td className={"border-black small-text"}></td>
                            </tr>
                            <tr>
                                <th colSpan={6} className={"table-header-bold fill-grey border-black small-text"}>COMMENTS</th>
                            </tr>
                            <tr>
                                <td className={"wide-cell border-black small-text"} colSpan={6}></td>
                            </tr>
                            </tbody>
                        </Table>
                    </Columns.Column>
                </Columns>
            </div>
        )
    }
}

export default SalesPurchaseForm;