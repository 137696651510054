import React, {useEffect, useState} from "react";
import {Button, Form} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../../state/selectors";
import {NotificationManager} from "react-notifications";
import VehicleMakePicker from "../../../Vehicle/VehicleMakePicker";


export default function VehicleModelRow({modelData, index, onUpdate, makes, makeObjects}){
    const dispatch = useDispatch();
    const [model, setModel] = useState(modelData);
    const [editable, setEditable] = useState(false);
    let dataFresh = useSelector(getDataFresh);

    const onEdit = e => {
        e.stopPropagation();
        setEditable(!editable);
    };


    const onChange = (e) => {
        let newVehicleModel = {...model};
        newVehicleModel[e.target.name] = e.target.value || null;
        setModel(newVehicleModel);
        onUpdate(index, newVehicleModel);
    }

    const onMakeChange = e => {
        let newVehicleModel = {...model};
        newVehicleModel['make'] = makeObjects[e.target.value] || null;
        setModel(newVehicleModel);
        onUpdate(index, newVehicleModel);
    }

    const submitUpdate = (e) => {
        e.stopPropagation();
        const conf = {
            method: "put",
            data: model,
            url: "api/vehiclemodel/"+model.id
        }
        axios(conf).then(res => {
           setEditable(false);
           dispatch(createSetDataFreshAction(dataFresh + 1))
        })
    }

    const onDelete = () => {
        const conf = {
            method: "delete",
            url: "api/vehiclemodel/"+model.id
        }
        axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            if(err){
                NotificationManager.error("There was an error");
            }
        })
    }

    return (
        <tr key={modelData.id}>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"text"}
                            name={"name"}
                            value={model.name}
                            disabled={!editable}
                            onChange={onChange}
                        />
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <VehicleMakePicker
                        disabled={!editable}
                        makes={makes}
                        vehicle={model}
                        onChange={onMakeChange}
                        showTitle={false}
                    />
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            name={"mileage_limit"}
                            value={model.mileage_limit}
                            disabled={!editable}
                            onChange={onChange}
                        />
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    )

}