import React, {useState} from "react";
import {Box, Columns, Heading, Form, Button} from "react-bulma-components";
import {useDispatch} from "react-redux";
import axios from "axios";
import {createSetJobAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";



export default function PartEnquiryForm(props){

    const [description, setDescription] = useState("");
    const [registration, setRegistration] = useState();
    const [make, setMake] = useState();
    const [model, setModel] = useState();
    const dispatch = useDispatch();


    const validator = (enquiry) => {
        if(enquiry.enquiry_description){
            return true
        }
        else{
            NotificationManager.warning("Description are required")
            return false
        }
    }

    const onSave = () => {
        let enquiry = {
            enquiry_description: description,
            enquiry_registration: registration,
            enquiry_make: make,
            enquiry_model: model,
            is_enquiry: true
        };
        if(validator(enquiry)){
            const conf = {
              method: "post",
              data: enquiry,
              url: "api/createpartenquiry"
            };
            axios(conf).then((res) => {
              dispatch(createSetJobAction(res.data));
              if (res) {
               props.history.push("/editjob/" + res.data.id)
              }
            }).catch(err => {
              if (err.response.data) {
                NotificationManager.error(
                  JSON.stringify(err.response.data),
                  "Error!",
                  10000
                );
              }
            });
        }
    }

    return (
        <Box>
            <Heading>Part Enquiry</Heading>
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Enquiry Vehicle Registration</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onInput={(e) => setRegistration(e.target.value)}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Enquiry Vehicle Make</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onInput={(e) => setMake(e.target.value)}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Enquiry Vehicle Model</Form.Label>
                        <Form.Control>
                            <Form.Input
                                type={"text"}
                                onInput={(e) => setModel(e.target.value)}
                            />
                        </Form.Control>
                    </Form.Field>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Form.Field>
                        <Form.Label>Description of Parts</Form.Label>
                        <Form.Textarea
                            value={description}
                            onInput={(e) => setDescription(e.target.value)}
                        />
                    </Form.Field>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Button
                        fullwidth={true}
                        color={"success"}
                        onClick={onSave}
                    >
                        Create Part Enquiry
                    </Button>
                </Columns.Column>
            </Columns>
        </Box>
    )



}