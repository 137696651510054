import React, {useState} from "react";
import {useVehicleTrackerTypeData} from "../../../hooks/useVehicleTrackerTypeData";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getVehicleTrackerTypeData} from "../../../state/selectors";
import {Box, Heading, Pagination, Table} from "react-bulma-components";
import {VehicleTypeTrackerRow} from "./VehicleTypeTrackerRow";
import Spinner from "react-spinner-material";
import {NewVehicleTrackerType} from "./NewVehicleTrackerType";
import {createSetVehicleTrackerTypeDataAction} from "../../../actions/dataActions";
import Collapsible from "react-collapsible";




export default function VehicleTrackerType(props){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh);
    const [page, setPage] = useState(1);

    let loading = useVehicleTrackerTypeData({page:page, page_size:25}, dataFresh);
    const data = useSelector(getVehicleTrackerTypeData);


    if (!loading) {
        return (
          <Box>
            <Spinner
              className="spinner-centre"
              size={120}
              spinnerColor={"#3273dc"}
              spinnerWidth={2}
            />
          </Box>
        );
    }

    const onVehicleTrackerTypeUpdate = (index, newVehicleTrackerType) => {
        let newState = data;
        newState.results[index]=newVehicleTrackerType;
        dispatch(createSetVehicleTrackerTypeDataAction(newState));
    }

    return (
        <div>
            <NewVehicleTrackerType dataFresh={dataFresh}/>
            <Box>
                <Collapsible
                  className="title"
                  triggerOpenedClassName="title"
                  trigger="Vehicle Tracker Types"
                >
                    <Heading size={6}>
                        Note: Modifying these values will update the tracker types of existing trackers!
                    </Heading>
                    <Table
                        size={"fullwidth"}
                    >
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Monthly Cost</th>
                            <th>UpFront Cost</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.results.map((type, index) => (
                            <VehicleTypeTrackerRow
                                type={type}
                                dataFresh={dataFresh}
                                onVehicleTrackerTypeUpdate={onVehicleTrackerTypeUpdate}
                                index={index}
                            />
                        ))}
                        </tbody>
                    </Table>
                    <Pagination
                        showFirstLast={true}
                        onChange={page => setPage(page)}
                        current={page}
                        total={Math.ceil(data.count / 25)}
                    >
                        {" "}
                    </Pagination>
                </Collapsible>
            </Box>
        </div>
    );


}