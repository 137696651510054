import React from "react";
import shortid from "shortid";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";


import Collapsible from "react-collapsible";
import { useDispatch } from "react-redux";
import { createSetContactDataAction } from "../../actions/dataActions";

function ContactInfoSection({ contactList, propertyName, includeAddress }) {
  const dispatch = useDispatch();

  const handleChange = (e, index) => {
    let contact = contactList[index];
    contact[e.target.name] = e.target.value;
    updateContacts(contact, index);
  };

  const addContact = e => {
    let newContacts = [...contactList];
    newContacts.push({
      key: shortid.generate(),
      name: "",
      email: "",
      role: "",
      comments: "",
      address: ""
    });
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  const updateContacts = (contact, index) => {
    let newContacts = [...contactList];
    newContacts.splice(index, 1, contact);
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  const removeContact = index => {
    let newContacts = [...contactList];
    newContacts.splice(index, 1);
    dispatch(createSetContactDataAction({ [propertyName]: newContacts }));
  };

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th>Phone Number</th>
            <th>Email</th>
            {includeAddress && <th>Address</th>}
            <th>Comments</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contactList
            .sort((a, b) => a.id > b.id)
            .map((item, index) => (
              <tr>
                <td>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      value={item.name || ""}
                      name="name"
                      onChange={e => handleChange(e, index)}
                    />
                  </Form.Control>
                </td>
                <td>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      value={item.role}
                      name="role"
                      onChange={e => handleChange(e, index)}
                    />
                  </Form.Control>
                </td>
                <td>
                  <Form.Control>
                    <Form.Input
                      type="text"
                      value={item.contact_number}
                      name="contact_number"
                      onChange={e => handleChange(e, index)}
                    />
                  </Form.Control>
                </td>
                <td>
                  <Form.Control>
                    <Form.Input
                      type="email"
                      value={item.email}
                      name="email"
                      onChange={e => handleChange(e, index)}
                    />
                  </Form.Control>
                </td>
                {includeAddress && (
                  <td>
                    <Form.Control>
                      <Form.Textarea
                        rows={2}
                        type="text"
                        value={item.address}
                        name="address"
                        onChange={e => handleChange(e, index)}
                      />
                    </Form.Control>
                  </td>
                )}
                <td>
                  <Form.Control>
                    <Form.Textarea
                      rows={2}
                      type="text"
                      value={item.comments}
                      name="comments"
                      onChange={e => handleChange(e, index)}
                    />
                  </Form.Control>
                </td>
                <td>
                  <Button
                    remove
                    onClick={() => removeContact(index)}
                    data-testid="remove-contact"
                  >
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button color="primary" onClick={addContact} data-testid="add-contact">
        Add Additional Contact
      </Button>
    </div>
  );
}

export default ContactInfoSection;
