import React from "react";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import useInput from "../../../hooks/useInput";
import usePostCostCentre from "../../../hooks/usePostCostCentre";
import { Button } from "react-bulma-components";
import Collapsible from "react-collapsible";

function CostCentre() {
  let [costCentre, nameInput] = useInput({
    category: "text",
    label: "Reporting Category Name"
  });

  const [, postCostCentre] = usePostCostCentre({
    name: costCentre,
  });

  const handleSubmit = () => {
    postCostCentre();
  };

  return (
    <Box className="settings_type">
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Cost Centre"
      >
        <Columns>
          {nameInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default CostCentre;
