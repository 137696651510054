import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import React, { Component } from "react";
import Figure from "../Homepage/Figure";

export default class DailyTable extends Component {
  render() {
    return (
      <Box>
        <h1 className="title">Rentals</h1>
        <Figure label="Total Weekly Rate" value={this.props.total_weekly} loaded={this.props.total_weekly}/>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Registration</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Customer</th>
              <th>Registration</th>
              <th>Make</th>
              <th>Model</th>
              <th>Weekly Rate</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {this.props.rentals.map((value, index) => (
              <tr
                className="clickable"
                onClick={() => window.open(`/editrental/${value.id}`, "_self")}
                key={value.id}
              >
                <td>{value.vehicle.registration}</td>
                <td>{value.start_date}</td>
                <td>{value.finish_date}</td>
                <td>{value.customer.display_name}</td>
                <td>{value.vehicle.registration}</td>
                <td>{value.vehicle.make}</td>
                <td>{value.vehicle.model}</td>
                <td>£{value.long_term_weekly} </td>
                <td>{value.comments} </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          showFirstLast={true}
          onChange={page => this.props.onPageChange(page)}
          current={this.props.current}
          total={Math.ceil(this.props.count / 25)}
        />
      </Box>
    );
  }
}
