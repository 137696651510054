import React, { useRef, useState } from "react";

import PartRow from "./PartRow.js";

import {Box, Heading} from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { usePartsData } from "../../hooks/usePartsData";
import {
  getDataFresh,
  getDepartmentsData,
  getPartsData,
  getVehicleMakeData, getVehicleModelData,
  getVehicleTypesData
} from "../../state/selectors";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-spinner-material";
import {
  createSetDataFreshAction,
  createSetPartsDataAction
} from "../../actions/dataActions";
import useSelect from "../../hooks/useSelect";
import { Columns } from "react-bulma-components";
import ReactToPrint from "react-to-print";
import PrintParts from "./PrintParts";
import FilterHeading from "../Invoicing/FilterHeading";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FAIcon from "../Icon/FAIcon";
import MultipleEditModal from "./MultipleEditModal";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import DepartmentPicker from "../Job/DepartmentPicker";
import {useVehicleMakeData} from "../../hooks/useVehicleMakeData";
import {useVehicleModelData} from "../../hooks/useVehicleModelData";
import useInput from "../../hooks/useInput";

function Part(props) {
  const componentRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [filters, setFilters] = useState({});
  const [searchFresh, setSearchFresh] = useState(1);

  const vehicle_types_loaded = useVehicleTypeData({});
  const vehicle_types = useSelector(getVehicleTypesData);

  const headings = [
    {
      label: 'ID'
    },
    {
      label: "Registration",
      field: "job__vehicle",
      labelField: "job__vehicle__registration",
      className: "small-typeahead"
    },
    { label: "Make/Model" },
    {
      label: "Part Supplier",
      field: "supplier",
      labelField: "supplier__display_name"
    },
    {
      label: "Part Name",
      blank: true,
      field: "name"
    },
    {
      label: "Part Number",
      blank: true,
      field: "part_number"
    },
    {
      label: "Cost",
      blank: true,
      field: "cost",
      className: "small-typeahead",
      thClass: "small-column"
    },
    {
      label: "Workshop Date",
      field: "job__workshop_date",
      labelField: "job__workshop_date",
      date: true
    },
    {
      label: "Date Ordered",
      field: "date_ordered",
      labelField: "date_ordered",
      date: true
    },
    {
      label: "Invoice Number",
      blank: true,
      field: "part_invoice_number"
    },
    {
      label: "Payment Date",
      field: "payment_date",
      labelField: "payment_date",
      date: true
    },
    {
      label: "Comments"
    },
    { label: "Stock Accrual" },
    { label: "Stock Amount"},
    { label: "Backorder Date"},
    { label: "Stock Kept At Workshop"}
  ];

  const [startDate, startDateInput, setStartDate] = useInput({
    type:"date",
    label:"Start Date",
  })

  const [endDate, endDateInput, setEndDate] = useInput({
    type:"date",
    label:"End Date",
  })

  let params = { page };

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const onSelectCheck = item => {
    let newSelection = { ...selected };
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newSelection[item.id.toString()] = item;
    } else {
      delete newSelection[item.id.toString()];
    }
    setSelected(newSelection);
  };

  const clearSelected = () => {
    setSelected({});
  };

  const [view, viewInput] = useSelect({
    label: "View",
    options: [
      { name: "Current", value: "current" },
      { name: "To Be Ordered", value: "to_order" },
      { name: "Approved", value: "approved" },
      { name: "Stock", value: "stock" },
      { name: "Backorder", value: "backorder"}
    ],
    initialValue: "current",
    testId: "part-view",
    callback: () => {
      setPage(1);
    }
  });
  let [selectedDepartment, setSelectedDepartment] = useState();

  if (view) {
    params.view = view;
  }

  if (selectedDepartment) {
    params.department = selectedDepartment;
  }

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if(startDate && endDate){
    params.startDate = startDate;
    params.endDate = endDate;
  }
  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);
  const complete = usePartsData(params, dataFresh);
  const data = useSelector(getPartsData);
  const makesLoaded = useVehicleMakeData({page_size:1000}, dataFresh);
  const modelsLoaded = useVehicleModelData({page_size:1000}, dataFresh);
  const makes = useSelector(getVehicleMakeData);
  const models = useSelector(getVehicleModelData);
  let departments_loading = useDepartmentsData({}, dataFresh);
  const departments = useSelector(getDepartmentsData);

  const onPartUpdate = (index, newPart) => {
    let newState = { ...data };
    newState.results[index] = newPart;
    dispatch(createSetPartsDataAction(newState));
    setSelected({});
  };

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const updateMultiple = newData => {
    const url = props.endpoint + "parts/update";
    const conf = {
      method: "put",
      data: newData,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setSelected({});
    });
  };

  const saveSelected = (paymentDate, invoiceNumber, dateOrdered, supplier) => {
    const newData = Object.values(selected).map(item => {
      let toSave = { ...item };
      if (paymentDate) {
        toSave.payment_date = paymentDate;
      }
      if (invoiceNumber) {
        toSave.part_invoice_number = invoiceNumber;
      }
      if (dateOrdered) {
        toSave.date_ordered = dateOrdered;
      }
      if(supplier) {
        toSave.supplier = supplier;
      }
      return toSave;
    });
    return updateMultiple(newData);
  };

  const approveSelected = () => {
    let passValidation = true;
    const newData = Object.values(selected).map(item => {
      if (item.part_invoice_number.length > 0 && !/[A-Za-z]+/.test(item.part_invoice_number) && item.payment_date) {
        return { ...item, approved: true };
      } else {
        passValidation = false;
        return {};
      }
    });

    if (passValidation) {
      return updateMultiple(newData);
    } else {
      NotificationManager.warning(
        "You must specify an invoice number (numbers only) and payment date before approving!",
        "Invoice Number Required",
        10000
      );
    }
  };
  const onDepartmentChange = (e) => {
    setSelectedDepartment(e.target.value);

  }
  const toggleSelectVisible = () => {
    let newSelected = { ...selected };
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      part => selectedKeys.indexOf(part.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };

  if (!complete || !vehicle_types_loaded || !departments_loading || !modelsLoaded || !makesLoaded) {
    return (
      <div className="spinner-centre">
        <Spinner
          className="spinner-centre"
          radius={120}
          color={"#3273dc"}
          stroke={5}
        />
      </div>
    );
  }

  document.title = "Parts Invoicing";

  return (
    <div>
      <Box>
        <h1 className="title">Parts Invoicing</h1>
        <Columns>
          {viewInput}
          <Columns.Column>
              <DepartmentPicker
                departments={departments}
                selectedDepartment={selectedDepartment}
                onDepartmentChange={onDepartmentChange}
              />
          </Columns.Column>
          <Columns.Column size={4}>
            <Form.Label>Search</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Form.Control>
              <Form.Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Heading size={6}>Date Ordered Filter</Heading>
        <Columns>
          {startDateInput}
          {endDateInput}
          <Columns.Column/>
          <Columns.Column/>
          <Columns.Column/>
          <Columns.Column/>
        </Columns>
      </Box>
      <Box>
        Total Parts Cost: £
        {data.total_cost.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </Box>
      <Box>
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
            <tr className="small-row-black">
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label} className={heading.className || ""}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                      className={heading.className}
                      thClass={heading.thClass}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                    className={heading.className}
                    thClass={heading.thClass}
                  />
                );
              })}
              {view === 'approved' && <th>Approver</th>}
              <th>
                <Button
                  onClick={toggleSelectVisible}
                  color={
                    data.results.find(
                      part =>
                        Object.keys(selected).indexOf(part.id.toString()) === -1
                    )
                      ? ""
                      : "danger"
                  }
                >
                  {data.results.find(
                    part =>
                      Object.keys(selected).indexOf(part.id.toString()) === -1
                  ) ? (
                    <div>
                      <FAIcon size="small" icon={["fas", "check"]} />
                      <span>Visible</span>
                    </div>
                  ) : (
                    <div>
                      <FAIcon size="small" icon={["fas", "times"]} />
                      <span>Visible</span>
                    </div>
                  )}
                </Button>
              </th>
              {!!Object.entries(selected).length && (
                <th>
                  <Button onClick={() => setSelected({})} color="danger">
                    <FAIcon size="small" icon={["fas", "times"]} />
                  </Button>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <PartRow
                key={value.id}
                part={value}
                index={index}
                onPartUpdate={onPartUpdate}
                endpoint={props.endpoint}
                showApprove={view === "current" || view === "stock"}
                onSelectCheck={onSelectCheck}
                selected={
                  Object.keys(selected).indexOf(value.id.toString()) !== -1
                }
                clearSelected={clearSelected}
                vehicleTypes={vehicle_types.results}
                approvedView={view === 'approved'}
                makes={makes}
                models={models}
              />
            ))}
          </tbody>
        </table>
          <div className={"second-delete"}>
          <Button
                  onClick={toggleSelectVisible}
                  color={
                    data.results.find(
                      part =>
                        Object.keys(selected).indexOf(part.id.toString()) === -1
                    )
                      ? ""
                      : "danger"
                  }
                  className={"second-delete-button"}
                >
                  {data.results.find(
                    part =>
                      Object.keys(selected).indexOf(part.id.toString()) === -1
                  ) ? (
                    <div>
                      <FAIcon size="small" icon={["fas", "check"]} />
                      <span>Visible</span>
                    </div>
                  ) : (
                    <div>
                      <FAIcon size="small" icon={["fas", "times"]} />
                      <span>Visible</span>
                    </div>
                  )}
                </Button>
            </div>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setEditModalOpen(true)}
              color={Object.entries(selected).length ? "primary" : ""}
              disabled={!Object.entries(selected).length}
            >
              Edit Selected
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setModalOpen(true)}
              color={Object.entries(selected).length ? "warning" : ""}
              disabled={!Object.entries(selected).length}
            >
              Print Selected
            </Button>
          </Columns.Column>
          {(view === "current" || view === "stock") && (
            <Columns.Column>
              <Button
                fullwidth
                onClick={() => approveSelected()}
                color={Object.entries(selected).length ? "success" : ""}
                disabled={!Object.entries(selected).length}
              >
                Approve Selected
              </Button>
            </Columns.Column>
          )}
        </Columns>
      </Box>
      <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <Section style={{ backgroundColor: "white" }}>
            <Form.Field>
              <p>
                You have selected {Object.keys(selected).length} out of total of{" "}
                {data.count} items for printing
              </p>
            </Form.Field>
            <ReactToPrint
              trigger={() => (
                <Button
                  color={Object.entries(selected).length ? "success" : ""}
                >
                  Print Selected
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Section>
        </Modal.Content>
      </Modal>
      <MultipleEditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        selected={selected}
        saveSelected={saveSelected}
      />
      <div style={{ display: "none" }}>
        <PrintParts data={selected} ref={componentRef} />
      </div>
    </div>
  );
}

export default Part;
