import React from "react";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import Figure from "./Figure";
import { useFiguresData } from "../../hooks/useFiguresData";
import { useSelector } from "react-redux";
import {
  getTransactionFiguresData,
  getPartFiguresData,
  getWorkFiguresData,
  getRentalFiguresData,
  getVehicleFiguresData,
  getTargetFiguresData
} from "../../state/selectors";
import TransactionsSummary from "./TransactionsSummary";

function Figures(props) {
  let params = {
    start: props.dates.start,
    end: props.dates.end,
    type: props.dates.type
  }
  const complete = useFiguresData(params);

  const getTotalWorkshopProfitLoss = (part, work) => {
    return (
      work.total_customer_labour_cost +
      part.total_customer_part_cost -
      work.total_labour_cost -
      part.total_part_cost
    );
  };

  const getTotalRentalsProfitLoss = rentals => {
    return rentals.total_short_term_income + rentals.total_long_term_income;
  };

  const getTotalIncome = data => {
    const rentalsIncome = getTotalRentalsProfitLoss(data.rentals.data);
    const workshopIncome = getTotalWorkshopProfitLoss(
      data.part.data,
      data.work.data
    );
    const vehicleSalesIncome = data.vehicles.data.total_sales_profit;

    return (
      rentalsIncome +
      workshopIncome +
      vehicleSalesIncome +
      data.transactions.data.total_in
    );
  };

  const getTotalOutgoing = data => {
    return (
      data.vehicles.data.total_tax +
      data.vehicles.data.total_finance +
      data.transactions.data.total_out
    );
  };

  const getProfit = data => {
    return getTotalIncome(data) - getTotalOutgoing(data);
  };

  const data = {
    transactions: {
      loaded: complete.transactionsLoading,
      data: useSelector(getTransactionFiguresData)
    },
    work: {
      loaded: complete.workLoading,
      data: useSelector(getWorkFiguresData)
    },
    part: {
      loaded: complete.partsLoading,
      data: useSelector(getPartFiguresData)
    },
    rentals: {
      loaded: complete.rentalsLoading,
      data: useSelector(getRentalFiguresData)
    },
    vehicles: {
      loaded: complete.vehiclesLoading,
      data: useSelector(getVehicleFiguresData)
    },
    target: {
      loaded: complete.targetsLoading,
      data: useSelector(getTargetFiguresData)
    }
  };

  const all_loaded =
    data.transactions.loaded &&
    data.vehicles.loaded &&
    data.rentals.loaded &&
    data.part.loaded &&
    data.work.loaded &&
    data.target.loaded;

  return (
    <Box>
      <Heading size={6}>Workshop</Heading>
      <Columns>
        <Figure
          label="Total Supplier Part Cost"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_part_cost : 0}
        />
        <Figure
          label="Total Supplier Labour Cost"
          loaded={data.part.loaded}
          value={data.work.loaded ? data.work.data.total_labour_cost : 0}
        />
        <Figure
          label="Total Customer Part Charges"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_customer_part_cost : 0}
        />
        <Figure
          label="Total Customer Labour Charges"
          loaded={data.work.loaded}
          value={
            data.work.loaded ? data.work.data.total_customer_labour_cost : 0
          }
        />
        <Figure
          label="Total Workshop Parts/Labour Profit"
          loaded={data.work.loaded && data.part.loaded}
          value={
            data.work.loaded && data.part.loaded
              ? getTotalWorkshopProfitLoss(data.part.data, data.work.data)
              : 0
          }
        />
      </Columns>
      <Columns>
        <Figure
          label="Internal Accts. Part Charges"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_internal_accounts_charges : 0}
        />
        <Figure
          label="Internal Accts. Labour Charges"
          loaded={data.work.loaded}
          value={
            data.work.loaded ? data.work.data.total_internal_accounts_charges : 0
          }
        />
        <Figure
          label="Internal Accts. Total Charges (Fleet Maintenance Cost)"
          loaded={data.part.loaded && data.work.loaded}
          value={
            data.work.loaded && data.part.loaded
              ? data.work.data.total_internal_accounts_charges +
                data.part.data.total_internal_accounts_charges
              : 0
          }
        />
        <Columns.Column></Columns.Column>
        <Columns.Column></Columns.Column>
      </Columns>
      <Heading size={6}>Rentals</Heading>
      <Columns>
        <Figure
          label="Total Short Term Rentals"
          loaded={data.rentals.loaded}
          value={
            data.rentals.loaded ? data.rentals.data.total_short_term_income : 0
          }
        />
        <Figure
          label="Total Long Term Rentals"
          loaded={data.rentals.loaded}
          value={
            data.rentals.loaded ? data.rentals.data.total_long_term_income : 0
          }
        />
        <Columns.Column></Columns.Column>
        <Columns.Column></Columns.Column>
        <Columns.Column></Columns.Column>
      </Columns>
      <Heading size={6}>Vehicles</Heading>
      <Columns>
        <Figure
          label="HP Interest Accrued"
          loaded={data.vehicles.loaded}
          value={
            data.vehicles.loaded
              ? data.vehicles.data.total_hp_interest_accrued
              : 0
          }
        />
        <Figure
          label="HP Capital Repayments"
          loaded={data.vehicles.loaded}
          value={
            data.vehicles.loaded
              ? data.vehicles.data.total_capital_repayments
              : 0
          }
        />
        <Figure
          label="Total Vehicle Finance"
          loaded={data.vehicles.loaded}
          value={data.vehicles.loaded ? data.vehicles.data.total_finance : 0}
        />
        <Figure
          label="Total Depreciation"
          loaded={data.vehicles.loaded}
          value={
            data.vehicles.loaded ? data.vehicles.data.total_depreciation : 0
          }
        />
        <Figure
          label="Vehicle Tax"
          loaded={data.vehicles.loaded}
          value={data.vehicles.loaded ? data.vehicles.data.total_tax : 0}
        />
        <Figure
          label="Total Vehicle Sales Cash Flow"
          loaded={data.vehicles.loaded}
          value={
            data.vehicles.loaded ? data.vehicles.data.total_sales_cash_flow : 0
          }
        />
        <Figure
          label="Total Vehicle Sales Profit"
          loaded={data.vehicles.loaded}
          value={
            data.vehicles.loaded ? data.vehicles.data.total_sales_profit : 0
          }
        />
        <Columns.Column></Columns.Column>
      </Columns>
      <Heading size={6}>Misc</Heading>
      <Columns>
        { data.transactions.loaded && data.transactions.data.categories.map(cat => <TransactionsSummary category={cat} loaded={data.transactions.loaded}/>)}
        <Figure
          label="Total Expenses"
          loaded={data.transactions.loaded}
          value={
            data.transactions.loaded ? data.transactions.data.total_out : 0
          }
        />
        <Figure
          label="Total Income"
          loaded={data.transactions.loaded}
          value={data.transactions.loaded ? data.transactions.data.total_in : 0}
        />
      </Columns>
      <Heading size={6}>Totals</Heading>
      <Columns>
        <Figure
          label="Total Income (Inc. Vehicle Sales Profits)"
          loaded={all_loaded}
          value={all_loaded ? getTotalIncome(data) : 0}
        />
        <Figure
          label="Total Expenses"
          loaded={all_loaded}
          value={all_loaded ? getTotalOutgoing(data) : 0}
        />
        <Figure
          label="Profit"
          loaded={all_loaded}
          value={all_loaded ? getProfit(data) : 0}
        />
        <Figure
          label="Target Profit"
          loaded={data.target.loaded}
          value={all_loaded ? data.target.data.weekly_target : 0}
        />
        <Figure
          label="Profit vs Target"
          loaded={all_loaded}
          value={
            all_loaded ? getProfit(data) - data.target.data.weekly_target : 0
          }
        />
      </Columns>
    </Box>
  );
}

export default Figures;
