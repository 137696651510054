import React, { Component } from "react";
import { Panel } from "react-bulma-components";
import { Button } from "react-bulma-components";
import axios from "axios";
import { getQueryString } from "../../utils";

class RentalsPanel extends Component {
  state = {
    rentals: []
  };

  componentDidMount() {
    this.loadRentals();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.finishDate !== this.props.finishDate
    ) {
      this.loadRentals();
    }
  }

  goToRentalPage = id => {
    window.location.href = `/editrental/${id}`;
  };

  async loadRentals(params) {
    let config = {
      start_date: this.props.startDate,
      finish_date: this.props.finishDate
    };
    const queryString = getQueryString(config);
    const promise = await axios.get(this.props.endpoint + queryString);
    const status = promise.status;
    if (status === 200) {
      const data = promise.data;
      this.setState({ rentals: data.results });
    }
  }

  render() {
    return (
      <Panel className="scrollable-panel">
        <Panel.Header>Other Rentals On This Date</Panel.Header>
        {this.state.rentals.map(rental => (
          <Panel.Block key={rental.id}>
            <Button onClick={() => this.goToRentalPage(rental.id)}>
              {`${rental.vehicle.registration}
                        ${rental.customer.display_name}
                        ${rental.start_date} - ${rental.finish_date}`}
            </Button>
          </Panel.Block>
        ))}
      </Panel>
    );
  }
}

export default RentalsPanel;
