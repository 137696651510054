import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { asyncContainer, Typeahead } from "react-bootstrap-typeahead";
import { Columns } from "react-bulma-components";
import { getQueryString } from "../../utils";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";

const AsyncTypeahead = asyncContainer(Typeahead);

class VehicleFinder extends Component {
  typeahead = null;
  typeFields = [
    "L1",
    "L2",
    "L3",
    "L4",
    "Car",
    "Low Loader",
    "Truck",
    "Luton",
    "Recovery"
  ];
  static propTypes = {
    endpoint: PropTypes.string.isRequired
  };
  state = {
    vehicle: this.props.vehicle,
    searchData: [],
    isLoading: false,
    type: "L1"
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = query => {
    this.setState({ isLoading: true });
    axios.get(this.props.endpoint + `?search=${query}`).then(resp =>
      this.setState({
        isLoading: false,
        searchData: resp.data.results
      })
    );
  };

  handleVehicleSelect = e => {
    this.props.selectVehicle(e[0]);
    this.typeahead.getInstance().clear();
    // this.props.vehicleUpdate(e[0]);
  };

  handleApplyFilter = e => {
    this.loadVehicles(
      getQueryString({
        type: this.state.type,
        start: this.props.startDate,
        end: this.props.finishDate,
        vehicle_status: "ACTIVE"
      })
    );
  };

  async loadVehicles(queryString) {
    this.setState({ isLoading: true });

    const promise = await axios.get(`/api/vehicles${queryString}`);
    const status = promise.status;
    if (status === 200) {
      const data = promise.data;
      this.props.updateAvailableVehicles(data.results);
      return data.results;
    }
  }

  render() {
    return (
      <form>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <AsyncTypeahead
                  useCache={false}
                  {...this.state}
                  id="typeahead1"
                  labelKey="registration"
                  minLength={2}
                  name={"registration"}
                  onSearch={this.handleSearch}
                  onChange={this.handleVehicleSelect}
                  placeholder={"Search"}
                  options={this.state.searchData}
                  ref={typeahead => (this.typeahead = typeahead)}
                  className="registration-ui vehicle-finder"
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <h1>OR Filter Available Vehicles</h1>
            <Form.Field>
              <Form.Control>
                <Form.Label>Type</Form.Label>
                <Form.Select
                  onChange={this.handleChange}
                  name="type"
                  value={this.state.type}
                >
                  {this.typeFields.map(value => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </Form.Select>
              </Form.Control>
            </Form.Field>
            <Form.Field>
              <div className="control">
                <Button
                  onClick={this.handleApplyFilter}
                  color="success"
                  type="button"
                >
                  Apply Filters and Search
                </Button>
              </div>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </form>
    );
  }
}
export default VehicleFinder;
