import React from "react";
import {Form} from "react-bulma-components";




export default function DepartmentPicker({departments, onDepartmentChange, selectedDepartment}){
  return (
      <div>
            <Form.Label>Department</Form.Label>
            <Form.Control>
              <Form.Select
                name="departments"
                value={selectedDepartment ? selectedDepartment : 0}
                onChange={e => onDepartmentChange(e)}
              >
                <option key={0} value={0}>All</option>
                {departments.results.map(value => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
      </div>
  )
};