import React, { Component } from "react";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";

import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import ContactSection from "../Contact/ContactSection";

class Inspection extends Component {
  state = {
    ...this.props.inspection,

    vehicle: this.props.vehicle || undefined
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.props.update_inspection(this.state, this.props.index);
    });
  };

  render() {
    return (
      <Box>
        <Columns>
          <Columns.Column>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Inspection Type</Form.Label>
                    <Form.Select
                      onChange={this.handleChange}
                      name="inspection_type"
                      value={this.state.inspection_type}
                      disabled={this.state.booked_in_date}
                    >
                      {this.props.types.map(value => (
                        <option
                          key={value.inspection_type}
                          value={value.inspection_type}
                        >
                          {value.inspection_type}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Expiry Date</Form.Label>
                    <Form.Input
                      className="input"
                      type="date"
                      name="expiry_date"
                      onChange={this.handleChange}
                      value={this.state.expiry_date || ""}
                      disabled={this.state.booked_in_date}
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Last Completed</Form.Label>
                    <Form.Input
                      type="date"
                      name="date_completed"
                      onChange={this.handleChange}
                      value={this.state.date_completed || ""}
                      disabled={this.state.booked_in_date}
                      required
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
            <Columns>
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Form.Label>Comments</Form.Label>
                    <Form.Textarea
                      type="textarea"
                      name="comments"
                      onChange={this.handleChange}
                      value={this.state.comments}
                      required
                    />
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>

            <Button
              color="danger"
              onClick={() => this.props.remove_inspection(this.props.index)}
            >
              Remove Inspection
            </Button>
          </Columns.Column>

          <Columns.Column>
            <ContactSection
              title="Inspection Workshop"
              contactUpdate={ws =>
                this.handleChange({
                  target: { name: "inspection_workshop", value: ws }
                })
              }
              endpoint={this.props.endpoint + "contacts"}
              contact={this.state.inspection_workshop || {}}
              type="SP"
            />
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default Inspection;
