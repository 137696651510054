import React from "react";
import {Picker} from "../Utils/Picker";



export default function VehicleMakePicker({onChange, vehicle, makes, disabled, showTitle=true, className}){
    return (
        <Picker
            name={"make"}
            label={showTitle ? "Vehicle Make" : null}
            value={vehicle.make}
            blank={true}
            onChange={onChange}
            options={makes}
            optionField={"name"}
            disabled={disabled}
            className={className}
        />
    )


}