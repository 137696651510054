import React, {useState} from "react";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Notification } from "react-bulma-components"
import { Heading } from "react-bulma-components"
import { Form } from "react-bulma-components";

import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import { Icon } from "react-bulma-components";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AsyncTypeahead = asyncContainer(Typeahead);

function ContactSection(props) {
  const [, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();

  const types = {
    CC: "Customer",
    SP: "Supplier"
  };

  const handleCustomerSelect = e => {
    typeahead.getInstance().clear();
    props.contactUpdate(e[0]);
  };

  const handleClear = e => {
    typeahead.getInstance().clear();
    props.contactUpdate(undefined);
  };

  const handleSearch = query => {
    setLoading(true);
    axios
      .get(props.endpoint + `?contact_type=${props.type}&search=${query}`)
      .then(resp => {
        setSearchData(resp.data.results);
        setLoading(false);
      });
  };

  return (
    <Box className="contact-section">
      <Columns>
        <Columns.Column>
          <h1 className="title">{props.title}</h1>
        </Columns.Column>

        {props.type === "CC" && !props.print && (
          <Columns.Column>
            <Button
              tabIndex={-1}
              onClick={() => window.open("/editcontact", "_blank")}
              color="success"
              className="is-pulled-right"
              disabled={props.disabled}
            >
              Add a new Customer +
            </Button>
          </Columns.Column>
        )}
        {props.contact.id &&
          <Columns.Column>
            <Button
              tabIndex={-1}
              onClick={() => window.open(`/editcontact/${props.contact.id}`, "_blank")}
              color="info"
              className="is-pulled-right"
            >
              Contact Details
            </Button>
          </Columns.Column>
        }
        <Columns.Column>
          <Button
            tabIndex={-1}
            onClick={handleClear}
            color="warning"
            className="is-pulled-right"
            disabled={props.disabled}
          >
            Clear
          </Button>
        </Columns.Column>
      </Columns>
      {!props.print && (
        <AsyncTypeahead
          useCache={false}
          isLoading={false}
          id="typeahead"
          labelKey="display_name"
          minLength={2}
          name={"display_name"}
          onSearch={handleSearch}
          onChange={handleCustomerSelect}
          placeholder={`Search ${types[props.type]}`}
          options={searchData}
          ref={typeahead => setTypeahead(typeahead)}
          className="typeahead"
          disabled={props.disabled}
        />
      )}
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Name</Form.Label>
            <Form.Input
              type="text"
              name="display_name"
              // onChange={this.handleChange}
              value={props.contact.display_name}
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Contact Number</Form.Label>
            <Form.Input
              type="text"
              name="company_phone"
              // onChange={this.handleChange}
              value={props.contact.company_phone}
              readOnly
              disabled
            />
          </Form.Field>
        </Columns.Column>
        {!props.print && (
          <Columns.Column>
            <Form.Field>
              <Form.Label>Email</Form.Label>
              <Form.Input
                type="text"
                name="company_email"
                value={props.contact.company_email}
                readOnly
                disabled
              />
            </Form.Field>
          </Columns.Column>
        )}
      </Columns>
      {props.print && (
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Email</Form.Label>
              <Form.Input
                type="text"
                name="email"
                value={props.contact.company_email}
                readOnly
                disabled
              />
            </Form.Field>
          </Columns.Column>
        </Columns>
      )}
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Address</Form.Label>
            <pre>{`${props.contact.address_line1 || ""}\n${props.contact.town ||
            ""}\n${props.contact.postcode || ""}`}</pre>
          </Form.Field>
        </Columns.Column>
      </Columns>
      {props.contact.id && props.displayWorkshopInstructions && !props.contact.accounts_active &&
        <Notification
          className="clickable"
          onClick={() => window.open(`/editcontact/${props.contact.id}`, "_blank")}
          color="danger">
          Warning! Account not active, check contact page.
        </Notification>
      }
      {props.contact.id && props.contact.overdue_payment &&
        <Notification
          color="danger">
          Warning! Account has overdue payment to {props.contact.cost_centre_list.join(", ")}.
        </Notification>
      }
      {props.displayWorkshopInstructions && props.contact.id &&
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Credit Limit</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="credit_limit"
                  value={props.contact.credit_limit}
                  readOnly
                  disabled
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Account Balance</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="outstanding_balance"
                  value={props.contact.outstanding_balance}
                  readOnly
                  disabled
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Payment Terms</Form.Label>
              <Form.Input
                type="text"
                name="payment_terms"
                value={props.contact.payment_terms}
                readOnly
                disabled
              />
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Payment Method</Form.Label>
              <Form.Input
                type="text"
                name="payment_method"
                value={props.contact.payment_method}
                readOnly
                disabled
              />
            </Form.Field>
          </Columns.Column>
        </Columns>
      }
      {(props.displayWorkshopInstructions && props.contact.workshop_instructions && props.contact.workshop_instructions.length > 0) &&
        <Heading size={6}>Workshop Instructions</Heading>}
      {props.displayWorkshopInstructions && props.contact.workshop_instructions && props.contact.workshop_instructions
          .filter((instruction) => !instruction.on_completion).map(instruction =>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Input
                className="ws-instructions"
                type="text"
                name="workshop_instructions"
                value={instruction.text}
                readOnly
                disabled
              />
            </Form.Field>
          </Columns.Column>
        </Columns>
      )
      }
    </Box>
  );
}

export default ContactSection;
