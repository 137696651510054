import React, {useState} from "react";
import {Box, Button, Columns, Form, Heading, Table} from "react-bulma-components";
import Spinner from "react-spinner-material";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getWorkLookupData} from "../../state/selectors";
import PartRow from "./PartRow";
import AddPartModal from "./AddPartModal";
import {createSetDataFreshAction, createSetWorkLookupDataAction} from "../../actions/dataActions";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import NotificationManager from "react-notifications/lib/NotificationManager";
import axios from "axios";
import workLookup from "./WorkLookup";
import PartSearchModal from "../Job/PartSearchModal";

const AsyncTypeahead = asyncContainer(Typeahead);



function WorkLookupForm(props){

    const workLookup = useSelector(getWorkLookupData);
    let dataFresh = useSelector(getDataFresh);
    const [partModalOpen, setPartModalOpen] = useState(false);
    const [partLookupSearchModal, setPartLookupSearchModal] = useState(
        {
            modalOpen:false,
            currentSearch: "",
            searchText: "",
            partIndex: 0,
            workIndex: 0
        });
    const [isLoading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [, setTypeahead] = useState();
    const dispatch = useDispatch();

    const handleSearch = e => {
        setLoading(true);
        axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
          setSearchData(resp.data.results);
          setLoading(false);
        });
    }
    const triggerChange = (data) => {
        dispatch(createSetWorkLookupDataAction(data));
    }
    const handleTextChange = (e) =>{
        const newLookup = {...workLookup}
        newLookup[e.target.name] = e.target.value;
        triggerChange(newLookup);
    }
    const handleSupplierSelect = (e) =>{
        const newLookup = {...workLookup}
        newLookup['supplier'] = e[0];
        triggerChange(newLookup);
    }
    const updateWorkLookup = (newWorkLookup) => {
        triggerChange(newWorkLookup)
    }
    const selectPart = (part, partIndex, workIndex) =>{
        let newWork = {...workLookup}

        let oldParts = workLookup.part_work_lookup ? workLookup.part_work_lookup : [];
        oldParts.push(part);
        newWork['part_work_lookup'] = oldParts;
        updateWorkLookup(newWork);
        setPartLookupSearchModal({
            modalOpen: false,
        })
    }
    const save = () =>{
        const conf = {
            data: workLookup,
            url: props.endpoint + "worklookup/update/" + workLookup.id,
            method: "put"
        }

        return axios(conf).then(() => {
            dispatch(createSetDataFreshAction(dataFresh + 1))
        }).catch(error => {
            NotificationManager.error("There was an error!")
        })
    }

    if(!props.loaded){
        return(
            <Box>
                <Spinner
                  className="spinner-centre"
                  size={120}
                  spinnerColor={"#3273dc"}
                  spinnerWidth={2}
                />
            </Box>
        )
    }


    return(
        <div>
            <Box>
                <Heading>Work Lookup</Heading>
                <Columns>
                    <Columns.Column>
                        <Form.Control>
                                <Form.Label>Supplier</Form.Label>
                                <AsyncTypeahead
                                  useCache={false}
                                  id="typeahead"
                                  labelKey="display_name"
                                  minLength={2}
                                  name={"supplier"}
                                  onSearch={handleSearch}
                                  onChange={handleSupplierSelect}
                                  placeholder={"Search Supplier"}
                                  disabled={false}
                                  options={searchData}
                                  ref={typeahead => setTypeahead(typeahead)}
                                  className="typeahead"
                                  isLoading={isLoading}
                                  selected={workLookup.supplier && workLookup.supplier.id ? [workLookup.supplier] : []}
                                />
                            </Form.Control>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Supplier Name</Form.Label>
                            <Form.Input
                                type={"text"}
                                data-testid={"supplier-name"}
                                disabled
                                readOnly
                                value={workLookup.supplier ? workLookup.supplier.display_name : ""}
                            />
                        </Form.Control>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Description</Form.Label>
                            <Form.Input
                                type={"text"}
                                data-testid={"description"}
                                value={workLookup.description}
                                name={"description"}
                                onChange={(e) => (handleTextChange(e))}
                            />
                        </Form.Control>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Labour Hours</Form.Label>
                            <Form.Input
                                type={"number"}
                                name={"labour_hours"}
                                data-testid={"labour-hours"}
                                onChange={(e) => (handleTextChange(e))}
                                value={workLookup.labour_hours}
                            />
                        </Form.Control>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Labour Cost</Form.Label>
                            <Form.Input
                                type={"number"}
                                name={"labour_cost"}
                                data-testid={"labour-cost"}
                                onChange={(e) => (handleTextChange(e))}
                                value={workLookup.labour_cost}
                            />
                        </Form.Control>
                    </Columns.Column>
                    <Columns.Column>
                        <Form.Control>
                            <Form.Label>Customer Labour Cost</Form.Label>
                            <Form.Input
                                type={"number"}
                                name={"customer_labour_cost"}
                                data-testid={"customer-labour-cost"}
                                onChange={(e) => (handleTextChange(e))}
                                value={workLookup.customer_labour_cost}
                            />
                        </Form.Control>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Columns.Column>
                        <Button
                            color={"success"}
                            onClick={() => (setPartModalOpen(true))}
                        >
                            Add Part +
                        </Button>
                        <Button
                            color={"success"}
                            onClick={() => (
                                setPartLookupSearchModal({
                                    modalOpen: true,
                                    currentSearch: "",
                                    searchText: "",
                                    partIndex: 0,
                                    workIndex: 0
                                })
                            )}
                        >
                            Search Parts
                        </Button>
                    </Columns.Column>
                </Columns>
                <Columns>
                    <Table
                        size={"fullwidth"}
                    >
                        <tr>
                            <th className={"table-header-bold"}>Part Name</th>
                            <th className={"table-header-bold"}>Part Number</th>
                            <th className={"table-header-bold"}>Part Suppler</th>
                            <th className={"table-header-bold"}>Part Cost</th>
                            <th></th>
                        </tr>
                        {workLookup.part_work_lookup && workLookup.part_work_lookup.map((part, index) =>(
                            <PartRow
                                workLookup={workLookup}
                                endpoint={props.endpoint}
                                dataFresh={dataFresh}
                                index={index}
                                disabled={false}
                            />
                        ))
                        }
                    </Table>
                </Columns>
            </Box>
            <Box>
                <Button
                    color={"success"}
                    fullwidth
                    onClick={save}
                >
                    Save
                </Button>
            </Box>
            <AddPartModal
                open={partModalOpen}
                setOpen={setPartModalOpen}
                workLookup={workLookup}
                setWorkLookup={updateWorkLookup}
            />
            <PartSearchModal
                {...partLookupSearchModal}
                setModalState={setPartLookupSearchModal}
                selectPart={selectPart}
            />
        </div>
    )


}

export default WorkLookupForm;