import React, { Component } from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";

class LicenceSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Licence</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>N.I. Number</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="ni_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.ni_number}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Licence Number</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="licence_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.licence_number}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Licence Held (Years)</Form.Label>
              <Form.Control>
                <Form.Input
                  type="number"
                  name="licence_held"
                  onChange={this.props.onChange}
                  value={String(this.props.rental.licence_held)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default LicenceSection;
