import React, {useState} from "react";
import {Box, Button, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import useInput from "../../../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {getDataFresh} from "../../../../state/selectors";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import VehicleMakePicker from "../../../Vehicle/VehicleMakePicker";


export default function NewVehicleModel({makes, makeObjects}){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh)
    let [name, nameInput, setName] = useInput({
        type: "text",
        label: "Name"
    });
    let [mileageLimit, mileageLimitInput, setMileageLimit] = useInput({
        type: "number",
        label: "Mileage Limit"
    });
    let [make, setMake] = useState({id: 0})


    const clear = () =>{
        setName("");
        setMileageLimit(0);
        setMake({id: 0});
    }

    const handleSubmit = () => {
        const conf = {
            data: {name: name, mileage_limit: mileageLimit, make: makeObjects[make.id]},
            url: "api/vehiclemodel",
            method: "post"
        }
        console.log(make);
        console.log(makeObjects);
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
            clear();
        }).catch(err => {
            NotificationManager.error("There was an error");
        })
    }

    return (
        <Box className="settings_type">
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Add Vehicle Model"
          >
              <Columns>
                  {nameInput}
                  {mileageLimitInput}
                  <Columns.Column>
                      <VehicleMakePicker
                        makes={makes}
                        vehicle={{make: make}}
                        onChange={e => setMake({id: e.target.value})}
                      />
                  </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                    <Button
                        color={"success"}
                        fullwidth
                        onClick={handleSubmit}
                    >
                        Add +
                    </Button>
                </Columns.Column>
              </Columns>
          </Collapsible>
        </Box>
    )
}