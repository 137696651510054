import { Form } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableDateCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit,
  monthOnly
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control className={inlineEdit ? "date-cell" : ""}>
          <Form.Input
            className={`small-row-black ${monthOnly ? 'extra-max-width' : ''}`}
            onChange={onChange}
            type={monthOnly ? 'month': 'date'}
            name={propName}
            value={value || ""}
            disabled={!editable}
          />
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableDateCell;
