import { useDispatch } from "react-redux";
import { useHttp } from "./useHttp";
import { createSetWorkLookupDataAction } from "../actions/dataActions";

export const useWorkLookup = (id, dataFresh) => {
  let nofetch = !id;
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
    `worklookup/update/${id}`,
    "get",
    {},
    dataFresh,
    nofetch
  );
  if (error) {
    throw Error("There was an error");
  }
  if (!loading && data !== null) {
    dispatch(createSetWorkLookupDataAction(data));
  }

  return !loading;
};
