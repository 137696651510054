import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetQuickLinksDataAction } from "../../../actions/dataActions";
import { getDataFresh, getQuickLinksData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { useQuickLinkData } from "../../../hooks/useQuickLinkData";
import NewQuickLink from "./NewQuickLink";
import QuickLinkRow from "./QuickLinkRow";
import Collapsible from "react-collapsible";

function QuickLinkView(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const onQuickLinkUpdate = (index, newQuickLink) => {
    let newState = data;
    newState.results[index] = newQuickLink;
    dispatch(createSetQuickLinksDataAction(newState));
  };

  const data = useSelector(getQuickLinksData);

  return (
    <div>
      <NewQuickLink />
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Quick Links"
        >
          <Heading size={6}>
            Note: Quick Links are seen by all site users
          </Heading>
          <Table>
            <thead>
              <tr>
                <th>Title</th>
                <th>URL</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {(data.results ?? []).map((value, index) => (
                <QuickLinkRow
                  key={value.id}
                  quickLinkData={value}
                  index={index}
                  endpoint={props.endpoint}
                  onQuickLinkUpdate={onQuickLinkUpdate}
                />
              ))}
            </tbody>
          </Table>
          <Pagination
            showFirstLast={true}
            onChange={page => setPage(page)}
            current={page}
            total={Math.ceil(data.count / 50)}
          >
            {" "}
          </Pagination>
        </Collapsible>
      </Box>
    </div>
  );
}

export default QuickLinkView;
