import React, {useState} from "react";
import axios from "axios";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import {Columns, Heading} from "react-bulma-components";
import {Box} from "react-bulma-components";
import {Button} from "react-bulma-components";
import {Form} from "react-bulma-components";
import ExistingItemModal from "./ExistingItemModal";
import Inspections from "./Inspections";
import KeyPicker from "../Vehicle/KeyPicker";

const AsyncTypeahead = asyncContainer(Typeahead);

function VehicleJobSection({
                             handleVehicleChange,
                             vehicleUpdate,
                             endpoint,
                             vehicle,
                             history,
                             type,
                             disabled,
                             keys,
                             keyFilterField,
                             keyFilter,
                             onKeyClear
                           }) {
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [associatedItems, setAssociatedItems] = useState([]);
  const [targetVehicle, setTargetVehicle] = useState({});

  const handleChange = e => {
    vehicle[e.target.name] = e.target.value;
    handleVehicleChange(vehicle);
  };

  const handleContinue = vehicle => {
    typeahead.getInstance().clear();
    vehicleUpdate(vehicle);
    setModalOpen(false);
  };
  let keyObjects;
  if (type === "Job Card"){
    keyObjects = keys.results.reduce((obj, item) => {
      return {
        ...obj,
        [item["id"]]: item
      };
    }, {});
  }

  const handleKeyChange = (e) => {
    vehicle.key = keyObjects[e.target.value];
    vehicleUpdate(vehicle);
  }
  const diffDates = (date1, date2) => {
    const dayDiff = (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24);
    return dayDiff;
  }
  const warrantyExpire = (date) => {
    const today = new Date();
    const dateToCheck = new Date(date);

    const diff = diffDates(dateToCheck, today)

    if (date == null) {
      return ""
    } else if (diff < 1) {
      return "danger"
    } else if (diff <= 30) {
      return "warning"
    } else {
      return "success"
    }
  }


  const handleVehicleSelect = e => {
    if (e[0] && e[0].id) {
      axios
        .all([
          axios.get(endpoint + `quotes?vehicle=${e[0].id}&ready_for_customer_invoice=false`),
          axios.get(
            endpoint + `jobs?vehicle=${e[0].id}&view=not_finished&ws=all`
          )
        ])
        .then(
          axios.spread((estimateResp, jobResp) => {
            if (
              jobResp.data.results.length ||
              estimateResp.data.results.length
            ) {
              setAssociatedItems(
                jobResp.data.results
                  .map(jobData => {
                    return {...jobData, itemType: "Job"};
                  })
                  .concat(
                    estimateResp.data.results.map(estimateData => {
                      return {...estimateData, itemType: "Quote"};
                    })
                  )
              );
              setTargetVehicle(e[0]);
              setModalOpen(true);
            } else {
              typeahead.getInstance().clear();
              vehicleUpdate(e[0]);
            }
          })
        );
    }
  };

  const handleSearch = query => {
    setLoading(true);
    let url = endpoint + `vehicles?search=${query}`;
    axios.get(url).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleVehicleType = customerVehicle => {
    const newVehicle = {vehicle_status: customerVehicle};
    handleVehicleChange(newVehicle);
  };

  return (
    <Box className="top-spacing">
      <h1 className="title">Vehicle</h1>
      {!disabled &&
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <AsyncTypeahead
                  useCache={false}
                  data-testid="vehicle-search"
                  id="typeahead"
                  labelKey="registration"
                  minLength={2}
                  name={"registration"}
                  onSearch={handleSearch}
                  onChange={handleVehicleSelect}
                  placeholder={"Search Vehicle Registration"}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="registration-ui"
                  isLoading={isLoading}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Button
              tabIndex={-1}
              onClick={() => window.open("/editvehicle", "_blank")}
              color="success"
              className="is-pulled-right"
            >
              Add a new Vehicle +
            </Button>
          </Columns.Column>
        </Columns>
      }

      <Columns.Column>
      <Form.Field>
        <Form.Label className="label">Registration</Form.Label>
        <Form.Control className="registration-wrapper">
          <Form.Input
            className="registration-field"
            type="text"
            data-testid={"vehicle_reg"}
            name="registration"
            onChange={handleChange}
            value={vehicle.registration}
            required
            disabled={true}
          />
        </Form.Control>
      </Form.Field>
      {vehicle.id &&
        <Button tabIndex={-1} color="info" onClick={() => window.open(`/editvehicle/${vehicle.id}`, "_blank")}>Vehicle
          Details</Button>
      }
      </Columns.Column>
      <Columns>
        <Columns.Column>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Make</Form.Label>
                  <Form.Input
                    type="text"
                    name="make"
                    onChange={handleChange}
                    data-testid={"make"}
                    value={vehicle.make ? vehicle.make.name : ""}
                    required
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Model</Form.Label>
                <Form.Input
                  type="text"
                  name="model"
                  onChange={handleChange}
                  data-testid={"model"}
                  value={vehicle.model ? vehicle.model.name : ""}
                  required
                  readOnly={true}
                  disabled={true}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Variant</Form.Label>
                  <Form.Input
                    type="text"
                    name="variant"
                    onChange={handleChange}
                    data-testid={"variant"}
                    value={vehicle.variant}
                    required
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Mileage</Form.Label>
                    <Form.Input
                      type="number"
                      name="mileage"
                      onChange={handleChange}
                      data-testid={"mileage"}
                      value={String(vehicle.mileage)}
                      required
                      readOnly={true}
                      disabled={true}
                    />
                  </Form.Control>
                </Form.Field>
            </Columns.Column>
            {type === "Job Card" &&
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Current Board</Form.Label>
                  <Form.Input
                    readOnly={true}
                    disabled={true}
                    data-testid={"vehicle-current-board"}
                    value={vehicle.key ? vehicle.key.board : ""}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            }
            {type === "Job Card" &&
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Current Key</Form.Label>
                  <Form.Input
                    readOnly={true}
                    disabled={true}
                    data-testid={"vehicle-current-key"}
                    value={vehicle.key ? vehicle.key.key_number : ""}
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            }
            {type === "Job Card" &&
            <Columns.Column>
              <KeyPicker
                keys={keys}
                onKeyChange={handleKeyChange}
                selectedKey={vehicle.key ? vehicle.key.id : null}
                filterField={keyFilterField}
                filter={keyFilter}
                disabled={disabled}
                onClear={onKeyClear}
              />
            </Columns.Column>
            }
          </Columns>
          <Form.Field>
            <Form.Control>
              <Form.Label>Vehicle Status</Form.Label>
              <Form.Input
                type="text"
                name="vehicle_status"
                value={vehicle.vehicle_status}
                required
                readOnly={true}
                disabled={true}
              />
            </Form.Control>
          </Form.Field>
          {vehicle.owner &&
              <Form.Field>
                <Form.Control>
                  <Form.Label>Customer Owner</Form.Label>
                  <Form.Input
                    type="text"
                    name="customer_owner"
                    value={vehicle.owner.display_name}
                    required
                    readOnly={true}
                    disabled={true}
                  />
                </Form.Control>
              </Form.Field>
          }

        </Columns.Column>
        <Columns.Column>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>LCV Sold</Form.Label>
                <input
                  className="checkbox"
                  type="checkbox"
                  name="lcv_sold"
                  readOnly={true}
                  checked={vehicle.lcv_sold}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>Vehicle Location</Form.Label>
              <Form.Input
                value={vehicle.location}
                readOnly={true}
                disabled={true}
                name={"vehicle_location"}
                type={"text"}
                data-testid={"vehicle-location"}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Manufacturer Warranty End Date</Form.Label>
                <Form.Input
                  type="date"
                  name="manufacturer_main_warranty_date"
                  value={vehicle.manufacturer_main_warranty_date}
                  required
                  readOnly={true}
                  disabled={true}
                  data-testid={"manufacturer-warranty-end-date"}
                  color={warrantyExpire(vehicle.manufacturer_main_warranty_date)}
                  textColor={warrantyExpire(vehicle.manufacturer_main_warranty_date)}
                  textWeight={warrantyExpire(vehicle.manufacturer_main_warranty_date) === "warning" ? "bold" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>Manufacturer Warranty Supplier</Form.Label>
              <Form.Input
                type="text"
                name="manufacturer_main_warranty_workshop"
                value={vehicle.manufacturer_main_warranty_workshop ? vehicle.manufacturer_main_warranty_workshop.display_name : ""}
                required
                readOnly={true}
                disabled={true}
                color={warrantyExpire(vehicle.manufacturer_main_warranty_date)}
                textColor={warrantyExpire(vehicle.manufacturer_main_warranty_date)}
                textWeight={warrantyExpire(vehicle.manufacturer_main_warranty_date) === "warning" ? "bold" : ""}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Driveline Warranty End Date</Form.Label>
                <Form.Input
                  type="date"
                  name="driveline_warranty_date"
                  value={vehicle.driveline_warranty_date}
                  required
                  readOnly={true}
                  disabled={true}
                  data-testid={"driveline-warranty-end-date"}
                  color={warrantyExpire(vehicle.driveline_warranty_date)}
                  textColor={warrantyExpire(vehicle.driveline_warranty_date)}
                  textWeight={warrantyExpire(vehicle.driveline_warranty_date) === "warning" ? "bold" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>Driveline Warranty Supplier</Form.Label>
              <Form.Input
                type="text"
                name="driveline_warranty_workshop"
                value={vehicle.driveline_warranty_workshop ? vehicle.driveline_warranty_workshop.display_name : ""}
                required
                readOnly={true}
                disabled={true}
                color={warrantyExpire(vehicle.driveline_warranty_date)}
                textColor={warrantyExpire(vehicle.driveline_warranty_date)}
                textWeight={warrantyExpire(vehicle.driveline_warranty_date) === "warning" ? "bold" : ""}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Aftermarket Warranty End Date</Form.Label>
                <Form.Input
                  type="date"
                  name="aftermarket_warranty_date"
                  value={vehicle.aftermarket_warranty_date}
                  required
                  readOnly={true}
                  disabled={true}
                  data-testid={"aftermarket-warranty-end-date"}
                  color={warrantyExpire(vehicle.aftermarket_warranty_date)}
                  textColor={warrantyExpire(vehicle.aftermarket_warranty_date)}
                  textWeight={warrantyExpire(vehicle.aftermarket_warranty_date) === "warning" ? "bold" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>Aftermarket Warranty Supplier</Form.Label>
              <Form.Input
                type="text"
                name="aftermarket_warranty_workshop"
                value={vehicle.aftermarket_warranty_workshop ? vehicle.aftermarket_warranty_workshop.display_name : ""}
                required
                readOnly={true}
                disabled={true}
                color={warrantyExpire(vehicle.aftermarket_warranty_date)}
                textColor={warrantyExpire(vehicle.aftermarket_warranty_date)}
                textWeight={warrantyExpire(vehicle.aftermarket_warranty_date) === "warning" ? "bold" : ""}
              />
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>R & M Warranty End Date</Form.Label>
                <Form.Input
                  type="date"
                  name="rm_warranty_date"
                  value={vehicle.rm_warranty_date}
                  required
                  readOnly={true}
                  disabled={true}
                  data-testid={"rm-warranty-end-date"}
                  color={warrantyExpire(vehicle.rm_warranty_date)}
                  textColor={warrantyExpire(vehicle.rm_warranty_date)}
                  textWeight={warrantyExpire(vehicle.rm_warranty_date) === "warning" ? "bold" : ""}
                />
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Label>R & M Warranty Supplier</Form.Label>
              <Form.Input
                type="text"
                name="rm_warranty_workshop"
                value={vehicle.rm_warranty_workshop ? vehicle.rm_warranty_workshop.display_name : ""}
                required
                readOnly={true}
                disabled={true}
                color={warrantyExpire(vehicle.rm_warranty_date)}
                textColor={warrantyExpire(vehicle.rm_warranty_date)}
                textWeight={warrantyExpire(vehicle.rm_warranty_date) === "warning" ? "bold" : ""}
              />
            </Columns.Column>
          </Columns>
        </Columns.Column>
        <Columns.Column>
          <Heading size={4}>Inspections</Heading>
          <Inspections vehicle={vehicle}/>
        </Columns.Column>
      </Columns>
      <ExistingItemModal
        open={modalOpen}
        setOpen={setModalOpen}
        items={associatedItems}
        onContinue={handleContinue}
        history={history}
        type={type}
        vehicle={targetVehicle}
      />
    </Box>
  );
}

export default VehicleJobSection;
