import React, { Component } from "react";

import { Link } from "react-router-dom";

import axios from "axios";
import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Table } from "react-bulma-components";
import Collapsible from "react-collapsible";
import { getQueryString } from "../../utils";

export default class CustomerRentalRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      results: [],
      count: 0,
      next: "",
      previous: "",
      current: 1,
      search_text: "",
      current_search: "",
      searching: false,
      labour_total: 0,
      total_part_cost: 0,
      customer: this.props.customer
    };
    this.loadRental = this.loadRental.bind(this);
  }

  componentDidMount() {
    this.loadRental({ page: 1 });
  }

  onPageChange = page => {
    let params = { page: page };
    if (this.state.searching) {
      params.search = this.state.current_search;
    }
    this.loadRental(params).then(this.setState({ current: page }));
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async loadRental(params) {
    params = { ...params };
    params.customer = this.props.customer;
    params.page_size = 10;
    const queryString = getQueryString(params);
    const promise = await axios.get(`/api/rentals${queryString}`);
    const status = promise.status;
    if (status === 200) {
      const data = promise.data;
      this.setState(data);
    }
  }

  getRow = value => {
    return (
      <tr key={value.id}>
        <td>
          <Link to={"/editrental/" + value.id}>
            {value.customer.display_name}
          </Link>
        </td>
        <td>{value.start_date} </td>
        <td>{value.finish_date} </td>
        <td>{value.rental_type} </td>
        <td>£{value.total || 0} </td>
      </tr>
    );
  };

  render() {
    return (
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Customer Rental Record"
        >
          <Columns>
            <Columns.Column>
              Total Income: £{this.state.total_cost || 0}
            </Columns.Column>
          </Columns>
          <Table striped={false} className="is-hoverable">
            <thead>
              <tr>
                <th>Customer</th>
                <th>Start</th>
                <th>End</th>
                <th>Type</th>
                <th>Cost</th>
              </tr>
            </thead>
            <tbody>{this.state.results.map(this.getRow)}</tbody>
          </Table>
          <Pagination
            showFirstLast={true}
            onChange={page => this.onPageChange(page)}
            current={this.state.current}
            total={Math.ceil(this.state.count / 5)}
          />
        </Collapsible>
      </Box>
    );
  }
}
