import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import FAIcon from "../components/Icon/FAIcon";
import React from "react";

function useInlineEditButton({
                               show,
                               onSave,
                               setEditable,
                               editable,
                               disabled
                             }) {
  if (show) {
    return (editable ? (<Form.Control>
        <Button
          className="small-row-white"
          color="success"
          onClick={onSave}
        >
          <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
        </Button>
      </Form.Control>
    ) : (
      <Form.Control>
        <Button
          className="small-row-black"
          onClick={() => setEditable(!editable)}
          disabled={disabled}
        >
          <FAIcon size="small" icon={["fas", "edit"]}/>
        </Button>
      </Form.Control>))
  }
  return null;
}

export default useInlineEditButton;
