import { Form } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableTimeCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control className={inlineEdit ? "time-cell" : ""}>
          <Form.Input
            className="small-row-black"
            onChange={onChange}
            type="time"
            name={propName}
            value={value || ""}
            disabled={!editable}
          />
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableTimeCell;
