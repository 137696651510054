import React from "react";
import {Box, Heading, Modal, Section, Table} from "react-bulma-components";
import PartRow from "./PartRow";




function ViewPartsModal({open, setOpen, workLookup}){

    return(
        <Modal
          closeOnBlur
          show={open}
          onClick={e => {e.stopPropagation()}}
          onClose={() => {
            setOpen(false);
          }}
        >
            <Modal.Content className="wider-modal">
                <Section style={{ backgroundColor: "grey" }}>
                    <Box>
                        <Heading>Parts</Heading>
                        <Table size={"fullwidth"}>
                            <tr>
                                <th>Part Name</th>
                                <th>Part Number</th>
                                <th>Part Supplier</th>
                                <th>Part Cost</th>
                            </tr>
                            <tbody>
                                {workLookup ? workLookup.part_work_lookup.map((part, index) => (
                                    <PartRow
                                        workLookup={workLookup}
                                        index={index}
                                        disabled={true}
                                    />
                                )) : <tr></tr>}
                            </tbody>
                        </Table>
                    </Box>
                </Section>
            </Modal.Content>
        </Modal>
    )


}
export default ViewPartsModal;