import React from "react";
import {Table} from "react-bulma-components";
import PrintJobRow from "./PrintJobRow";


class PrintJobList extends React.Component{
    render() {
        const jobs = this.props.jobs;
        return (
            <div>
                <Table bordered size={"fullwidth"}>
                    <tr>
                        <th>Id</th>
                        <th>Registration</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Customer</th>
                        <th>Description</th>
                        <th>Fitter</th>
                        <th>Fitter Order</th>
                    </tr>
                    {jobs.results.map((job, index) => (
                        <PrintJobRow job={job} index={index}/>
                    ))}
                </Table>
            </div>
        )

    }


}
export default PrintJobList