import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Heading } from "react-bulma-components";

export default function CreateModal({
  open,
  setOpen,
  id,
}) {

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading>Transaction Created</Heading>
          <p>Transaction created with id: <b>{id}</b></p>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
