import React, { Component } from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";

class InsuranceSection extends Component {
  render() {
    return (
      <Box>
        <Heading>Insurance</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Policy Number</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="policy_number"
                  onChange={this.props.onChange}
                  value={this.props.rental.policy_number}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Policy Name</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="policy_name"
                  onChange={this.props.onChange}
                  value={this.props.rental.policy_name}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Insurer</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="insurer"
                  onChange={this.props.onChange}
                  value={this.props.rental.insurer}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
    );
  }
}

export default InsuranceSection;
