import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React, { useState } from "react";
import { Icon } from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

function useInput({ type, label, initialValue, currency, onChange, size, ...inputProps}) {
  initialValue = initialValue || (type === "date" ? undefined : "");
  const onChangeFunc = onChange ? function(e) { setValue(e.target.value); onChange(e)} : (e => setValue(e.target.value))
  const [value, setValue] = useState(initialValue);
  const input = (
    <Columns.Column size={size}>
      <Form.Field>
        <Form.Label>{label}</Form.Label>
        <Form.Control iconLeft={!!currency}>
          <Form.Input
            value={value}
            onChange={onChangeFunc}
            type={type}
            {...inputProps}
          />
          {currency &&
              <Icon align={"left"} className={"small-icon"}>
                <FontAwesomeIcon icon={faPoundSign} />
              </Icon>
          }
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useInput;
