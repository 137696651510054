import React from "react";
import {useVehicleMakeData} from "../../../hooks/useVehicleMakeData";
import {Box} from "react-bulma-components";
import Spinner from "react-spinner-material";
import VehicleModel from "./VehicleModel/VehicleModel";
import VehicleMake from "./Vehicle Make/VehicleMake";
import {useSelector} from "react-redux";
import {getDataFresh} from "../../../state/selectors";


export default function VehicleDetails(props){
    const dataFresh = useSelector(getDataFresh);
    const makesLoading = useVehicleMakeData({page_size: 1000}, dataFresh)

    if (!makesLoading) {
        return (
          <Box>
            <Spinner
              className="spinner-centre"
              size={120}
              spinnerColor={"#3273dc"}
              spinnerWidth={2}
            />
          </Box>
        );
    }

    return (
        <div>
            <VehicleModel {...props}/>
            <VehicleMake {...props}/>
        </div>
    )


}