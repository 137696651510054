import React, { useState } from "react";
import { useDiaryData } from "../../hooks/useDiaryData";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh, getDiaryData } from "../../state/selectors";
import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Form } from "react-bulma-components";
import {getDate} from "../../utils";
import { Heading } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Table } from "react-bulma-components";
import NewDiaryEntry from "./NewDiaryEntry";
import useWeekInput from "../../hooks/useWeekInput";
import {
  formatDate,
  getLastDayOfWeek,
  getFirstDayOfWeek,
  readableDate
} from "../../utils";
import axios from "axios";
import {createSetDataFreshAction, createSetDiaryDataAction} from "../../actions/dataActions";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import DiaryRow from "./DiaryRow";

function DiaryView(props) {
  const dispatch = useDispatch();
  const [rangeType, setRangeType] = useState('all');

  const [date, dateInput, overrideDate] = useWeekInput({
    label: "Date Selection",
    initialValue: getFirstDayOfWeek(new Date()),
    weekly: rangeType === 'weekly'
  });

  const [completed, setCompleted] = useState(false);

  const [page, setPage] = useState(1);

  const dataFresh = useSelector(getDataFresh);

  const startDate = new Date(date);

  const params = {
    page, completed
  };

  if (rangeType !== 'all') {
      params.start_date = formatDate(rangeType === 'weekly' ? getFirstDayOfWeek(startDate) : startDate)
      params.end_date = formatDate(rangeType === 'weekly' ? getLastDayOfWeek(startDate) : startDate)
  }

  const complete = useDiaryData(
    params,
    dataFresh,
  );

  const onEntryUpdate = (index, newEntry) => {
    let newState = { ...data };
    newState.results[index] = newEntry;
    dispatch(createSetDiaryDataAction(newState));
  };

  const handleDelete = id => {
    const endpoint = "/api/diaryentry/" + id;
    const conf = { method: "delete", url: endpoint };
    axios(conf).then(() => dispatch(createSetDataFreshAction(dataFresh + 1)));
  };

  const updateRangeType = rangeType => {
    setPage(1);
    setRangeType(rangeType);
  };

  const handleCompleted = entry => {
    entry.completed = true;
    const endpoint = "/api/diaryentry/" + entry.id;
    const conf = { method: "put", url: endpoint, data: entry };
    axios(conf).then(() => dispatch(createSetDataFreshAction(dataFresh + 1)));
  };

  const data = useSelector(getDiaryData);

  if (!complete) return <p>Fail</p>;

  document.title = "Diary";

  return (
    <div>
      <NewDiaryEntry />
      <Box>
        <Heading>View Options</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Label>View</Form.Label>
                <Button
                  color={rangeType === 'all' ? "primary" : ""}
                  onClick={() => updateRangeType('all')}
                >
                  All
                </Button>
                <Button
                  color={rangeType === 'weekly' ? "primary" : ""}
                  onClick={() => {
                    updateRangeType('weekly');
                    overrideDate(formatDate(new Date()));
                  }}
                >
                  Weekly
                </Button>
                <Button
                  color={rangeType === 'daily' ? "primary" : ""}
                  onClick={() => {
                      overrideDate(formatDate(new Date())); //Set date to today
                      updateRangeType('daily');
                  }}
                >
                  Daily
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          {dateInput}
          <Columns.Column>
            <Form.Field>
              <Form.Label>Show Completed</Form.Label>
              <Form.Control>
                <Form.Checkbox
                  checked={completed}
                  type="checkbox"
                  name="completed"
                  onChange={() => setCompleted(!completed)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Heading>Diary</Heading>
        <Table size={"fullwidth"}>
          <thead>
            <tr>
              <th>Start</th>
              <th>Time</th>
              <th>Task For</th>
              <th>Comments</th>
              <th>End</th>
              {completed &&
                <th>Completed</th>
              }
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((entry, index) =>
              <DiaryRow
                entry={entry}
                index={index}
                showCompleted={completed}
                endpoint={props.endpoint}
                handleDelete={handleDelete}
                handleCompleted={handleCompleted}
                onEntryUpdate={onEntryUpdate}
              />
            )}


          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
    </div>
  );
}

export default DiaryView;
