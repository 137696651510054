import { Form } from "react-bulma-components";
import {Icon} from "react-bulma-components";
import React, {useState} from "react";
import axios from "axios";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import { Button } from "react-bulma-components";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function PaymentRow({
                                     endpoint,
                                     index,
                                     handleChange,
                                     handleDelete,
                                     payment,
                                     costCentres,
                                     costCentreObjects
                                   }) {
  const typeFields = ["BACS", "CASH", "CARD", "DIRECT DEBIT"];
  const [, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();

  const handleCustomerSelect = e => {
    handleChange(
      {
        target: {
          name: "contact",
          value: e[0]
        }
      },
      index
    );
  };

  const handleClear = e => {
    typeahead.getInstance().clear();
    handleChange(
      {
        target: {
          name: "contact",
          value: undefined
        }
      },
      index
    );
    // props.contactUpdate(undefined);
  };
  const onCCChange = (e, index) => {
    const toUpdate = {
      target: {
        name: "cost_centre",
        value: costCentreObjects[e.target.value]
      }
    };
    handleChange(toUpdate, index);
  };

  const handleSearch = query => {
    setLoading(true);
    axios.get(endpoint + `contacts?&search=${query}&contact_type=CC`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  return (
    <tr key={payment.key}>
      <td>
        <AsyncTypeahead
          useCache={false}
          isLoading={false}
          id="typeahead"
          labelKey="display_name"
          minLength={2}
          name={"display_name"}
          onSearch={handleSearch}
          onChange={handleCustomerSelect}
          placeholder={`Search Customer`}
          selected={payment.contact ? [payment.contact] : []}
          options={searchData}
          ref={typeahead => setTypeahead(typeahead)}
          className="typeahead"
        />
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="text"
            name="invoice_number"
            onChange={e => handleChange(e, index)}
            value={payment.invoice_number}
          />
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="number"
            name="amount"
            onChange={e => handleChange(e, index)}
            value={payment.amount}
          />
          <Icon align={"left"} className={"small-icon"}>
            <FontAwesomeIcon icon={faPoundSign}/>
          </Icon>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Select
            name="payment_type"
            value={payment.payment_type}
            onChange={e => handleChange(e, index)}
          >
            {typeFields.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Select
            name="cost_centre"
            value={payment.cost_centre ? payment.cost_centre.id : costCentres[0].id}
            onChange={e => onCCChange(e, index)}
          >
            {costCentres.map(value => (
              <option key={value.id} value={value.id}>
                {value.name}
              </option>
            ))}
          </Form.Select>
        </Form.Control>
      </td>
      <td>
        <Form.Control>
          <Form.Input
            type="date"
            value={payment.payment_date || ""}
            name="payment_date"
            onChange={e => handleChange(e, index)}
          />
        </Form.Control>
      </td>
      {useEditableTableTextAreaCell({
        propName: "comments",
        value: payment.comments,
        onChange: e => handleChange(e, index),
        editable: true
      })}
      <td>
        <Button
          color="danger"
          data-testid={`submit-payment-delete${payment.id}`}
          onClick={() => handleDelete(index)}
        >
          X
        </Button>
      </td>
    </tr>
  );
}
