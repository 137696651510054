import React from "react";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import WorkTotalBox from "./WorkTotalBox";

function WorkTotalBoxSection({ workData }) {
  return (
    <Columns>
      <Columns.Column>
        <Box>
          <Heading size={5}>Total Supplier Costs</Heading>
          <WorkTotalBox
            labourTotal={workData.total_labour}
            partsTotal={workData.total_part_cost}
          />
        </Box>
      </Columns.Column>
      <Columns.Column>
        <Box>
          <Heading size={5}>Total Chargeable</Heading>
          <WorkTotalBox
            labourTotal={workData.total_chargeable_customer_labour}
            partsTotal={workData.total_chargeable_customer_part_cost}
          />
        </Box>
      </Columns.Column>
    </Columns>
  );
}

export default WorkTotalBoxSection;
