import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Box } from "react-bulma-components";
import WorkTotals from "../WorkTotals/WorkTotals";
import { formatCurrency } from "../../utils";

export default function FinalizeModal({ open, setOpen, quote, onFinalize }) {
  const onClick = () => {
    onFinalize();
    setOpen(false);
  };

  const checkZeros = value => {
    return !value || value === 0 || value === "0";
  };

  const vehicle = quote.vehicle ? quote.vehicle : {};

  let zeros = false;
  let supplierTotals = {};
  let labourCosts = 0;

  quote.estimated_work_items.forEach(item => {
    labourCosts += item.estimated_labour_cost;
    if (
      checkZeros(item.estimated_labour_cost) ||
      checkZeros(item.estimated_customer_labour_cost)
    ) {
      zeros = true;
    }
    item.parts
      .filter(part => part.supplier)
      .forEach(part => {
        if (
          checkZeros(part.estimated_cost) ||
          checkZeros(part.estimated_customer_cost)
        ) {
          zeros = true;
        }
        if (!supplierTotals[part.supplier.display_name]) {
          supplierTotals[part.supplier.display_name] = 1 * part.estimated_cost;
        } else {
          supplierTotals[part.supplier.display_name] += 1 * part.estimated_cost;
        }
      });
  });

  const customer_label = quote.customer
    ? ` - (${quote.customer.display_name})`
    : "";
  const customer2_label = quote.customer2
    ? ` - (${quote.customer2.display_name})`
    : "";

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Are you sure you want to finalize?
          </Heading>
          <Columns>
            <Columns.Column>
              <p className="is-centered">
                Verify the following details before confirming:
              </p>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label className="label">Registration</Form.Label>
                <Form.Control className="registration-wrapper">
                  <Form.Input
                    className="registration-field"
                    type="text"
                    name="registration"
                    value={vehicle.registration}
                    disabled
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Control>
                  <Form.Label>Make</Form.Label>
                  <Form.Input
                    type="text"
                    name="make"
                    value={vehicle.make}
                    readOnly
                    disabled
                  />
                </Form.Control>
              </Form.Field>
            </Columns.Column>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Model</Form.Label>
                <Form.Input
                  type="text"
                  name="model"
                  value={vehicle.model ? vehicle.model.name : ""}
                  readOnly
                  disabled
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Field>
                <Form.Label>Estimated Total Labour Cost</Form.Label>
                <Form.Input
                  type="text"
                  name="labour"
                  value={formatCurrency(labourCosts)}
                  readOnly
                  disabled
                />
              </Form.Field>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Form.Label>Supplier Parts Breakdown</Form.Label>
              <Table>
                <thead>
                  <th>Supplier</th>
                  <th>Estimated Parts Cost</th>
                </thead>
                <tbody>
                  {Object.keys(supplierTotals).map(supplier => (
                    <tr>
                      <td>{supplier}</td>
                      <td>{formatCurrency(supplierTotals[supplier])}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Box>
                <Heading size={6}>Customer Quote{customer_label}</Heading>
                <WorkTotals
                  workItems={quote.estimated_work_items}
                  allItems={false}
                  chargeable={false}
                  labourFieldName="estimated_customer_labour_cost"
                  partFieldName="estimated_customer_cost"
                />
              </Box>
            </Columns.Column>
            <Columns.Column>
              <Box>
                <Heading size={6}>
                  Customer 2 Quote{customer2_label}
                </Heading>
                <WorkTotals
                  workItems={quote.estimated_work_items}
                  allItems={false}
                  chargeable={true}
                  labourFieldName="estimated_customer_labour_cost"
                  partFieldName="estimated_customer_cost"
                />
              </Box>
            </Columns.Column>
          </Columns>
          {zeros && (
            <Columns>
              <Columns.Column>
                <p className="is-centered">
                  <b>WARNING</b>: One or more items has been estimated to have a
                  cost/charge of £0. Please double check and confirm before
                  approving!
                </p>
              </Columns.Column>
            </Columns>
          )}
          <Columns>
            <Columns.Column>
              <Button color="info" fullwidth onClick={onClick}>
                Finalize
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
