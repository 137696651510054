import React, {useState} from "react";
import {Box, Button, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {useDispatch} from "react-redux";


function NewJobWorkshopTab(dataFresh){
    const [typeahead, setTypeahead] = useState();
    const [, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    let [supplier, setSupplier] = useState();
    const dispatch = useDispatch();

    const save = () => {
        const endpoint = "api/contacts/"+supplier.id;
        const conf ={
            method: "put",
            data: supplier,
            url: endpoint
        }

        return axios(conf).then((resp) => {
            dispatch(createSetDataFreshAction(dataFresh +1));
        }).catch(err => {NotificationManager.error("An error occurred!")});
    }

    const onClick = (e) => {
        supplier.job_tab = true;
        save().then((resp) => {setSupplier("")}).catch(err => {NotificationManager.error("An error occurred!")});
    }
    const handleCustomerSelect = (e) => {
        setSupplier(e[0]);
    }
    const handleSearch = query => {
        setLoading(true);
        axios.get(`api/suppliers?&search=${query}`).then(resp => {
            setSearchData(resp.data.results);
            setLoading(false);
        });
    };

    return (
        <div>
           <Box>
               <Collapsible
                   className="title"
                   triggerOpenedClassName="title"
                   trigger="Add Job Screen Workshop Tab"
               >
                   <Columns>
                       <Columns.Column>
                       <AsyncTypeahead
                           useCache={false}
                           isLoading={false}
                           id="typeahead"
                           labelKey="display_name"
                           minLength={2}
                           name={"display_name"}
                           onSearch={handleSearch}
                           onChange={handleCustomerSelect}
                           placeholder={`Search Workshop`}
                           selected={supplier ? [supplier] : []}
                           options={searchData}
                           ref={typeahead => setTypeahead(typeahead)}
                           className="typeahead"
                       />
                       </Columns.Column>
                   </Columns>
                   <Columns>
                       <Columns.Column>
                       <Button
                           color={"success"}
                           onClick={onClick}
                           fullwidth={true}
                       >
                           Add
                       </Button>
                       </Columns.Column>
                   </Columns>
               </Collapsible>
           </Box>
        </div>
    )




}
export default NewJobWorkshopTab;