import {createSetWorkLookupDataAction} from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const useWorkLookupsData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
    "worklookup",
    params,
    createSetWorkLookupDataAction,
    dataFresh,
    nofetch
  );
};
