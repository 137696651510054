import React, {useEffect, useState} from "react";
import {Box, Button, Columns, Form, Heading} from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {fixEmptyDepartment, fixEmptyDates} from "../../../utils";
import {createSetJobAction} from "../../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../../state/selectors";
import ImageDisplay from "./ImageDisplay";
import {triggerUpload, onFileChange} from "../../../utils";


const AsyncTypeahead = asyncContainer(Typeahead);


export default function DefectForm(props) {

  let [selectedVehicle, setSelectedVehicle] = React.useState();
  const [fileList, setFileList] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();
  const [defectText, setDefectText] = useState("");
  const [statusText, setStatusText] = useState("DRIVEABLE");
  const [locationText, setLocationText] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState("");
  const [mileage, setMileage] = useState(0);
  const [uploadUrl, setUploadUrl] = useState("");
  let dataFresh = useSelector(getDataFresh);
  const dateFields = ["book_in_date", "workshop_date", "finish_date"];

  const dispatch = useDispatch();



  const handleSearch = query => {
    setLoading(true);
    let url = props.endpoint + `vehicles?search=${query}`;
    axios.get(url).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  };

  const handleVehicleSelect = e => {
    setSelectedVehicle(e[0]);
  }

  const deleteImage = id => {
    const conf = {
          url: "/api/deleteinternalimage/"+id,
          method: "delete"
    };
    axios(conf);
  }

  const onSave = () => {
    let dataToSave = {
      vehicle: selectedVehicle,
      defect_description: defectText,
      defect_status: statusText,
      location_of_van: locationText,
      defect_driver_name: driverName,
      defect_driver_number: driverPhone,
      is_defect: true,
      expected_mileage: mileage,
      work_items: []
    };
    dataToSave = fixEmptyDates(dataToSave, dateFields);
    dataToSave = fixEmptyDepartment(dataToSave);
    const conf = {
      method: "post",
      data: dataToSave,
      url: "api/createdefect"
    };
    axios(conf).then((res) => {
      dispatch(createSetJobAction(res.data));
      if (res) {
        if(fileList){
          triggerUpload(fileList, res.data.id, selectedVehicle, setFileList, deleteImage, dataFresh, dispatch, "job");
        }
        props.history.push("/editjob/" + res.data.id)
      }
    }).catch(err => {
      if (err.response.data) {
        NotificationManager.error(
          JSON.stringify(err.response.data),
          "Error!",
          10000
        );
      }
    });

  }

  const onFileDelete = (e, index) => {
    let dataTransfer = new DataTransfer();
    for(let i=0; i<fileList.length;i++){
      if(i !== index){
        dataTransfer.items.add(fileList[i]);
      }
    }
    setFileList(dataTransfer.files);
  }


  return (
    <div>
      <Box>
        <Heading>Defect</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Vehicle</Form.Label>
              <Form.Control>
                <AsyncTypeahead
                  useCache={false}
                  data-testid="vehicle-search"
                  id="typeahead"
                  labelKey="registration"
                  minLength={2}
                  name={"registration"}
                  onSearch={handleSearch}
                  onChange={handleVehicleSelect}
                  placeholder={"Search Vehicle Registration"}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="typeahead"
                  isLoading={isLoading}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Label>Registration</Form.Label>
            <Form.Input
              value={selectedVehicle ? selectedVehicle.registration : ""}
              disabled={true}
              readOnly={true}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Label>Make</Form.Label>
            <Form.Input
              value={selectedVehicle ? selectedVehicle.make ? selectedVehicle.make.name : "" : ""}
              disabled={true}
              readOnly={true}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Label>Model</Form.Label>
            <Form.Input
              value={selectedVehicle ? selectedVehicle.model ? selectedVehicle.model.name : "" : ""}
              disabled={true}
              readOnly={true}
            />
          </Columns.Column>
        </Columns>
        <br/>
        <Columns>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Description of problem</Form.Label>
              <Form.Textarea
                minLength={10}
                onChange={(e) => {
                  setDefectText(e.target.value)
                }}
                value={defectText}
              />
            </Form.Control>
          </Columns.Column>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Status of Vehicle</Form.Label>
              <Form.Select
                onChange={(e) => {
                  setStatusText(e.target.value)
                }}
              >
                <option key={"DRIVEABLE"} value={"DRIVEABLE"}>DRIVEABLE</option>
                <option key={"NOT_DRIVEABLE"} value={"NOT_DRIVEABLE"}>NOT DRIVEABLE</option>
              </Form.Select>
            </Form.Control>
          </Columns.Column>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Mileage of Vehicle</Form.Label>
              <Form.Input
                type={"number"}
                value={mileage}
                onChange={(e) => {
                  setMileage(e.target.value)
                }}
              />
            </Form.Control>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Location of Van</Form.Label>
              <Form.Input
                type={"text"}
                value={locationText}
                onChange={(e) => {
                  setLocationText(e.target.value)
                }}
              />
            </Form.Control>
          </Columns.Column>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Driver Name</Form.Label>
              <Form.Input
                type={"text"}
                value={driverName}
                onChange={(e) => {
                  setDriverName(e.target.value)
                }}
              />
            </Form.Control>
          </Columns.Column>
          <Columns.Column>
            <Form.Control>
              <Form.Label>Driver Phone Number</Form.Label>
              <Form.Input
                type={"text"}
                value={driverPhone}
                onChange={(e) => {
                  setDriverPhone(e.target.value)
                }}
              />
            </Form.Control>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Control>
              <Form.InputFile id={'defect-files'} data-testid={"defect-file-upload"} value={fileList} onChange={(e) => onFileChange(e, fileList, setFileList)} inputProps={{multiple: true}}></Form.InputFile>
            </Form.Control>
          </Columns.Column>
        </Columns>
        { fileList &&
          <ImageDisplay
            dataFresh={dataFresh}
            fileList={fileList}
            onImageDelete={onFileDelete}
          />
        }
      </Box>
      <Box>
        <Form.Control>
          <Button
            color={"success"}
            fullwidth
            onClick={onSave}
            id={"defect-save"}
            data-testid={"defect-save-button"}
          >
            Create Defect
          </Button>
        </Form.Control>
      </Box>
    </div>
  )


}