import React, {useState} from "react";
import {Columns, Heading, Modal, Form, Section, Icon, Button} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function AddPartModal({workLookup, setWorkLookup, open, setOpen, dataFresh}){
    const [partName, setPartName] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [partCost, setPartCost] = useState();
    const [partSupplier, setPartSupplier] = useState();
    const [isLoading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [, setTypeahead] = useState();
    const dispatch = useDispatch();


    const handleSearch = e => {
        setLoading(true);
        axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
          setSearchData(resp.data.results);
          setLoading(false);
        });
    }

    const handleSupplierSelect = (e) =>{
        setPartSupplier(e[0]);
    }

    const partValidator = (part) =>{
        return !!(part.name && part.part_number && part.cost && part.supplier);
    }

    const addPart = () =>{
        let newWork = {...workLookup}

        let newPart = {
            name: partName,
            part_number: partNumber,
            cost: partCost,
            supplier: partSupplier
        };
        if(partValidator(newPart)){
            let oldParts = workLookup.part_work_lookup ? workLookup.part_work_lookup : [];
            oldParts.push(newPart);
            newWork['part_work_lookup'] = oldParts;
            setWorkLookup(newWork);
        }
        else{
            NotificationManager.error("All fields are required");
        }
        setSearchData([]);

    }
    return (
        <Modal
          closeOnBlur
          show={open}
          onClick={e => {e.stopPropagation()}}
          onClose={() => {
            setOpen(false);
          }}
        >
            <Modal.Content>
                <Section style={{backgroundColor: "white"}}>
                    <Heading>Add Part</Heading>
                    <Columns>
                        <Columns.Column>
                            <Form.Control>
                                <Form.Label>Part Number</Form.Label>
                                <Form.Input
                                    type={"text"}
                                    name={""}
                                    onChange={(e) => {
                                        setPartNumber(e.target.value);
                                    }}
                                />
                            </Form.Control>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Control>
                                <Form.Label>Part Name</Form.Label>
                                <Form.Input
                                    type={"text"}
                                    name={""}
                                    onChange={(e) => setPartName(e.target.value)}
                                />
                            </Form.Control>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Control>
                                <Form.Label>Supplier</Form.Label>
                                <AsyncTypeahead
                                  useCache={false}
                                  id="typeahead"
                                  labelKey="display_name"
                                  minLength={2}
                                  name={"supplier"}
                                  onSearch={handleSearch}
                                  onChange={handleSupplierSelect}
                                  placeholder={"Search Supplier"}
                                  disabled={false}
                                  options={searchData}
                                  ref={typeahead => setTypeahead(typeahead)}
                                  className="typeahead"
                                  isLoading={isLoading}
                                  selected={partSupplier ? [partSupplier] : []}
                                />
                            </Form.Control>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Label>Cost</Form.Label>
                            <Form.Control className={"has-icons-left"}>
                                <Form.Input
                                    type={"number"}
                                    name={""}
                                    onChange={(e) => setPartCost(e.target.value)}
                                />
                                <Icon align={"left"} className={"small-icon"}>
                                    <FontAwesomeIcon icon={faPoundSign}/>
                                </Icon>
                            </Form.Control>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                fullwidth
                                color={"success"}
                                onClick={() => {
                                    addPart();
                                    setOpen(false);
                                }}
                            >
                                Add Part +
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )




}