import React from "react";
import { useGoogleAuth } from "./GoogleContext";
import { useDispatch } from "react-redux";
import { createSetUserDataAction } from "../actions/dataActions";
import { Box } from "react-bulma-components";

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const { signIn } = useGoogleAuth();

  const handleSignIn = async () => {
    const googleUser = await signIn();
    const tokenBlob = new Blob(
      [JSON.stringify({ access_token: googleUser.accessToken }, null, 2)],
      { type: "application/json" }
    );
    const options = {
      method: "POST",
      body: tokenBlob,
      mode: "cors",
      cache: "default"
    };
    fetch("/api/social/google-oauth2", options).then(r => {
      r.json().then(resp => {
        if (resp.token) {
          localStorage.setItem("token", resp.token);
          dispatch(createSetUserDataAction(googleUser));
          window.location.href = "/";
        }
      });
    });
  };

  return (
    <Box>
      <h1>Login</h1>
      <button onClick={handleSignIn}>Sign in with Google</button>
    </Box>
  );
};

export default GoogleLoginButton;
