import React from "react";
import {Form} from "react-bulma-components";

export default function QuoteTypeSelector({onChange, selectedtype}){


    return(
        <div>
            <Form.Label>Quote Type</Form.Label>
            <Form.Control>
                <Form.Select
                    name={"purpose"}
                    value={selectedtype ? selectedtype : 0}
                    onChange={onChange}
                >
                    <option key={"NONE"} value={"NONE"}>NONE</option>
                    <option key={"OFF HIRE"} value={"OFF HIRE"}>OFF HIRE</option>
                    <option key={"INSURANCE CLAIM"} value={"INSURANCE CLAIM"}>INSURANCE CLAIM</option>
                    <option key={"CUSTOMER VEHICLE"} value={"CUSTOMER VEHICLE"}>CUSTOMER VEHICLE</option>
                </Form.Select>
            </Form.Control>
        </div>
    )





}