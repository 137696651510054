import React, {useState} from "react";
import {Box, Columns, Modal, Section, Form, Button} from "react-bulma-components";
import {useImagesData} from "../../hooks/useImagesData";
import {useDispatch, useSelector} from "react-redux";
import {getImagesData} from "../../state/selectors";
import Spinner from "react-spinner-material";
import ImageDisplay from "./Defects/ImageDisplay";
import {NotificationManager} from "react-notifications";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {onFileChange, triggerUpload} from "../../utils";


export default function ImageModal({open, setOpen, job, dataFresh, type}){
    const dispatch = useDispatch();
    const [fileList, setFileList] = useState();
    let params = {type: type}
    if(job && job.vehicle){
        params.blob_name = job.vehicle.registration+"-"+job.id;
        params.id = job.id
    }
    let imagesLoaded = useImagesData(params, dataFresh);
    let images = useSelector(getImagesData);

    const deleteImage = id => {
        const conf = {
              url: "/api/deleteinternalimage/"+id,
              method: "delete"
        };
        axios(conf);
    }

    if(!imagesLoaded){
        return (
            <Box>
                <Spinner
                  className="spinner-centre"
                  size={120}
                  spinnerColor={"#3273dc"}
                  spinnerWidth={2}
                />
            </Box>
        );
    }

    const onUploadedFileDelete = (e, index) =>{
        //get blob name from url
        let url = images[index].url;
        // url format : https://storage.googleapis.com/bucket_name/blob_name?googleaddedstuff
        if(url){
            let split_url = url.split("/") // Splits into 5 chunks 0-4
            let blob_name = split_url[4].split("?")[0]
            const conf = {
                url: "/api/deleteimage?blob_name="+blob_name+"&blob_prefix="+job.vehicle.registration+"-"+job.id,
                method: "post"
            }
            axios(conf).then(res=>{
                dispatch(createSetDataFreshAction(dataFresh + 1))
            }).catch(err => {
                NotificationManager.error("There was an error")
            })
        }

    };


    const onFileDelete = (e, index) => {
        let dataTransfer = new DataTransfer();
        for(let i=0; i<fileList.length;i++){
          if(i !== index){
            dataTransfer.items.add(fileList[i]);
          }
        }
        setFileList(dataTransfer.files);
    }
    return(
        <Modal
            show={open}
            closeOnBlur
            onClose={() => {
                setFileList(undefined)
                setOpen(false)
            }}
        >
            <Modal.Content
                className={"wider-modal"}
            >
                <Section style={{backgroundColor: "white"}}>
                    <Columns>
                        <Columns.Column>
                            <ImageDisplay
                                imageList={images}
                                onImageDelete={onUploadedFileDelete}
                            />
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        {fileList &&
                            <Columns.Column>
                                <h2>Images to Upload</h2>
                                <ImageDisplay
                                    displayHeader={false}
                                    fileList={fileList}
                                    onImageDelete={onFileDelete}
                                />
                            </Columns.Column>
                        }
                        <Columns.Column>
                            <Form.Label>File Upload</Form.Label>
                            <Form.Control>
                                <Form.InputFile value={fileList} onChange={(e) => onFileChange(e, fileList, setFileList)} inputProps={{multiple: true}}/>
                            </Form.Control>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                fullwidth={true}
                                onClick={() => {
                                    triggerUpload(fileList, job.id, job.vehicle, setFileList, deleteImage, dataFresh, dispatch, type);
                                }}
                            >
                                Upload
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )

}