import { Form } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableTextCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Input
            className="small-row-black"
            onChange={onChange}
            type="text"
            name={propName}
            value={value}
            disabled={!editable}
          />
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableTextCell;
