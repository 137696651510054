import React from "react";
import {Box, Heading, Table} from "react-bulma-components";
import {useFittersData} from "../../../hooks/useFittersData";
import {useSelector} from "react-redux";
import {getDataFresh, getFittersData} from "../../../state/selectors";
import NewFitter from "./NewFitter";
import FitterRow from "./FitterRow";
import Spinner from "react-spinner-material";



function FitterView(props){
    let dataFresh = useSelector(getDataFresh);
    const loading = useFittersData({},dataFresh);
    const data = useSelector(getFittersData);
    if (!loading) {
        return (
        <Box>
            <Spinner
                className="spinner-centre"
                size={120}
                spinnerColor={"#3273dc"}
                spinnerWidth={2}
            />
        </Box>
        );
    }

    return (
        <div>
            <br/>
            <NewFitter/>
            <Box>
                <Heading>Fitters</Heading>
                <Table size={"fullwidth"}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    {data.results.map(value => (
                         <FitterRow fitter={value} />
                    ))}
                </Table>
            </Box>
        </div>
    )

}
export default FitterView;