// reducers.js
import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {
  getEstimatedValue,
  getOutstandingBalance,
  getHpInterestRate,
  getInternalSalePrice
} from "../utils";
import {getLastMonthInterest, getRemainingInterest, getThisMonthsPayment} from "../financeUtils";

const initialState = {
  vehicle: {inspections: [], finance_agreements: [], finance_type: "NONE"},
  figures: {},
  dataFresh: 0,
  inspections: {results: [], count: 0},
  parts: {results: [], count: 0},
  partLookup: {results: [], count: 0},
  quickLinks: {results: [], count: 0}
};

export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
      case "Data/SetJobsData":
        return {
          ...state,
          jobs: action.data
        };
      case "Data/SetQuotesData":
        return {
          ...state,
          quotes: action.data
        };
      case "Data/SetPartsData":
        return {
          ...state,
          parts: action.data
        };
      case "Data/SetPartLookupData":
        return {
          ...state,
          partLookup: action.data
        };
      case "Data/SetWorkLookupData":
        return {
          ...state,
          workLookup: action.data
        };
      case "Data/SetRentalData":
        return {
          ...state,
          rentals: action.data
        };
      case "Data/SetUserData":
        return {
          ...state,
          user: action.data
        };
      case "Data/SetTransactionData":
        return {
          ...state,
          transactions: action.data
        };
      case "Data/SetWeeklyTransactionData":
        return {
          ...state,
          weeklyTransactions: action.data
        };
      case "DataFresh/SetDataFresh":
        return {
          ...state,
          dataFresh: action.dataFresh
        };
      case "Data/SetPartsFiguresData":
        return {
          ...state,
          figures: {
            ...state.figures,
            partFigures: action.data
          }
        };
      case "Data/SetWorkFiguresData":
        return {
          ...state,
          figures: {...state.figures, workFigures: action.data}
        };
      case "Data/SetTransactionFiguresData":
        return {
          ...state,
          figures: {...state.figures, transactionData: action.data}
        };
      case "Data/SetRentalFiguresData":
        return {
          ...state,
          figures: {...state.figures, rentalFigures: action.data}
        };
      case "Data/SetVehicleFiguresData":
        return {
          ...state,
          figures: {...state.figures, vehicleFigures: action.data}
        };
      case "Data/SetTargetFiguresData":
        return {
          ...state,
          figures: {...state.figures, target: action.data}
        };
      case "Data/SetTargetsData":
        return {
          ...state,
          targets: action.data
        };
      case "Data/SetInspectionTypesData":
        return {
          ...state,
          inspectionTypes: action.data
        };
      case "Data/SetInspectionData":
        return {
          ...state,
          inspections: action.data
        };
      case "Data/SetPaymentsData":
        return {
          ...state,
          payments: action.data
        };
      case "Data/SetRecurringPaymentsData":
        return {
          ...state,
          recurringPayments: action.data
        };
      case "Data/SetVehicleTypesData":
        return {
          ...state,
          vehicleTypes: action.data
        };
      case "Data/SetTransactionCategoriesData":
        return {
          ...state,
          transactionCategories: action.data
        };
      case "Data/SetTransactionReportingCategoriesData":
        return {
          ...state,
          transactionReportingCategories: action.data
        };
      case "Data/SetCostCentresData":
        return {
          ...state,
          costCentres: action.data
        };
      case "Data/SetVehicleData":
        return {
          ...state,
          vehicle: {
            ...action.data,
            finance_agreements: action.data.finance_agreements.map(agreement => {
              return {
                ...agreement,
                remaining_balance: getOutstandingBalance({...agreement}),
                hp_remaining_interest: getRemainingInterest({...agreement}, new Date(Date.now())),
                early_settlement_charges: 1 * getOutstandingBalance({...agreement}) + 1 * getLastMonthInterest({...action.data}, new Date(Date.now())),
                estimated_value: getEstimatedValue({...agreement}),
                this_months_payment: getThisMonthsPayment(
                  {...agreement},
                  new Date(Date.now())
                )
              }
            }),
            remaining_balance: getOutstandingBalance({...action.data}),
            hp_remaining_interest: getRemainingInterest({...action.data}, new Date(Date.now())),
            early_settlement_charges: 1 * getOutstandingBalance({...action.data}) + 1 * getLastMonthInterest({...action.data}, new Date(Date.now())),
            // estimated_value: getEstimatedValue({...action.data})
          }
        };
      case "Data/SetVehicleTrackersData":
        return {
          ...state,
          trackers: action.data ?? state.trackers
        };
      case "Data/SetVehicleField":
        return {
          ...state,
          vehicle: {
            ...state.vehicle,
            ...action.data,
            remaining_balance: getOutstandingBalance({
              ...state.vehicle,
              ...action.data
            }),
            early_settlement_charges: getOutstandingBalance({
              ...state.vehicle,
              ...action.data
            }) + getLastMonthInterest({...action.data}, new Date(Date.now())),
            hp_remaining_interest: getRemainingInterest(
              {
                ...state.vehicle,
                ...action.data
              }, new Date(Date.now())
            ),
            // estimated_value: getEstimatedValue({
            //   ...state.vehicle,
            //   ...action.data
            // }),
            hp_interest_rate: getHpInterestRate({
              ...state.vehicle,
              ...action.data
            }),
            internal_sale_price: getInternalSalePrice({
              ...state.vehicle,
              ...action.data
            }),
            finance_agreements: action.data.finance_agreements ? action.data.finance_agreements.map(agreement => {
              return {
                ...agreement,
                remaining_balance: getOutstandingBalance({...agreement}),
                hp_remaining_interest: getRemainingInterest({...agreement}, new Date(Date.now())),
                early_settlement_charges: 1 * getOutstandingBalance({...agreement}) + 1 * getLastMonthInterest({...agreement}, new Date(Date.now())),
                estimated_value: getEstimatedValue({...agreement}),
                hp_interest_rate: getHpInterestRate({
                  ...agreement,
                }),
                internal_sale_price: getInternalSalePrice({
                  ...agreement
                }),
                this_months_payment: getThisMonthsPayment(
                  {...agreement},
                  new Date(Date.now())
                )
              }
            }) : state.vehicle.finance_agreements,
          }
        };
      case "Data/SetVehicleSummaryData":
        return {
          ...state,
          vehicleSummary: {
            ...action.data
          }
        };
      case "Data/SetJobData":
        return {
          ...state,
          job: {
            ...action.data
          }
        };
      case "Data/SetQuoteData":
        return {
          ...state,
          quote: {
            ...action.data
          }
        };
      case "Data/SetJobField":
        return {
          ...state,
          job: {
            ...state.job,
            ...action.data
          }
        };
      case "Data/SetQuoteField":
        return {
          ...state,
          quote: {
            ...state.quote,
            ...action.data
          }
        };
      case "Data/SetDiaryData":
        return {
          ...state,
          diary: action.data
        };
      case "Data/SetWorkData":
        return {
          ...state,
          work: action.data
        };
      case "Data/SetFinanceData":
        let agreements = action.data;
        agreements.results = action.data.results.map(agreement => {
          return {
            ...agreement,
            remaining_balance: getOutstandingBalance({...agreement}),
            hp_remaining_interest: getRemainingInterest({...agreement}, new Date(Date.now())),
            early_settlement_charges: 1 * getOutstandingBalance({...agreement}) + 1 * getLastMonthInterest({...agreement}, new Date(Date.now())),
            estimated_value: getEstimatedValue({...agreement}),
            hp_interest_rate: getHpInterestRate({...agreement}),
            this_months_payment: getThisMonthsPayment(
              {...agreement},
              new Date(Date.now())
            )
          }
        });
        return {
          ...state,
          finance: agreements
        };
      case
      "Data/SetVehiclesData":
        let newVehicles = action.data;
        newVehicles.results = newVehicles.results.map(vehicle => {
          return {
            ...vehicle,
            remaining_balance: getOutstandingBalance(vehicle),
            early_settlement_charges: getOutstandingBalance(vehicle) + getLastMonthInterest(vehicle, new Date(Date.now())),
            hp_remaining_interest: getRemainingInterest(vehicle, new Date(Date.now())),
            // estimated_value: getEstimatedValue(vehicle),
            hp_interest_rate: getHpInterestRate(vehicle),
            finance_agreements: vehicle.finance_agreements.map(agreement => {
              return {
                ...agreement,
                remaining_balance: getOutstandingBalance({...agreement}),
                hp_remaining_interest: getRemainingInterest({...agreement}, new Date(Date.now())),
                early_settlement_charges: 1 * getOutstandingBalance({...agreement}) + 1 * getLastMonthInterest({...action.data}, new Date(Date.now())),
                estimated_value: getEstimatedValue({...agreement}),
                hp_interest_rate: getHpInterestRate({...agreement}),
                this_months_payment: getThisMonthsPayment(
                  {...agreement},
                  new Date(Date.now())
                )
              }
            }),
          };
        });
        return {
          ...state,
          vehicles: newVehicles
        };
      case
      "Data/SetContactData"
      :
        return {
          ...state,
          contact: {...state.contact, ...action.data}
        };
      case
      "Data/SetContactsData"
      :
        return {
          ...state,
          contacts: action.data
        };
      case
      "Data/SetBlankContactData"
      :
        return {
          ...state,
          contact: {...action.data}
        };
      case
      "Data/SetAppDefaultsData"
      :
        return {
          ...state,
          appDefaults: action.data
        };
      case "Data/SetQuickLinksData":
        return {
          ...state,
          quickLinks: action.data
        };
      case "Data/SetDepartmentsData":
        return {
          ...state,
          departments: action.data
        };
      case "Data/SetFittersData":
        return {
          ...state,
          fitters: action.data
        };
      case "Data/SetKeysData":
        return{
          ...state,
          keys: action.data
        }
      case "Data/SetDefectCountData":
        return{
          ...state,
          defectCount: action.data
        }
      case "Data/SetEnquiryCountData"  :
        return{
          ...state,
          enquiryCount: action.data
        }
      case "Data/SetImagesData":
        return{
          ...state,
          images: action.data
        }
      case "Data/SetVehicleTrackerTypeData":
        return{
          ...state,
          vehicleTrackerType: action.data
        }
      case "Data/SetVehicleTrackerFigureData":
        return{
          ...state,
          vehicleTrackerFigures: action.data
        }
      case "Data/SetVehicleMakeData":
        return{
          ...state,
          vehicleMake: action.data
        }
      case "Data/SetVehicleModelData":
        return{
          ...state,
          vehicleModel: action.data
        }
      default:
        return state;
    }
  }
;

export default history =>
  combineReducers({
    router: connectRouter(history),
    data: dataReducer
  });
