import React from "react"
import Collapsible from "react-collapsible";
import {Box, Button, Columns} from "react-bulma-components";
import useInput from "../../../hooks/useInput";
import usePostDepartment from "../../../hooks/usePostDepartment";


function NewDepartment(){
    let [departmentName, departmentNameInput, setDepartmentName] = useInput({
        type: "text",
        label: "Department Name"
    });
    const [, postDepartment] = usePostDepartment({
        name: departmentName
    });
    const onClick = () => {
        postDepartment().then(setDepartmentName(""));
    };

    return (
        <Box>
            <Collapsible
                className="title"
                triggerOpenedClassName="title"
                trigger="Add Department"
            >
                <Columns.Column>
                    {departmentNameInput}
                </Columns.Column>
                <Columns.Column>
                    <Button
                       fullwidth={true}
                       color={"success"}
                       onClick={onClick}
                    >
                        Add +
                    </Button>
                </Columns.Column>
            </Collapsible>
        </Box>
    )
}
export default NewDepartment;