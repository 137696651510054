import React from "react";
import {Columns, Form} from "react-bulma-components";



export default function HireAgreementSection({financeAgreement, onChange}){

    return (
        <div>
            <Columns>
                <Columns.Column>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control>
                        <Form.Input
                            type={"date"}
                            onChange={(e) => onChange(e)}
                            value={financeAgreement.hire_start_date}
                            name={"hire_start_date"}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control>
                        <Form.Input
                            type={"date"}
                            onChange={(e) => onChange(e)}
                            value={financeAgreement.end_date}
                            name={"end_date"}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column>
                    <Form.Label>Daily Rate</Form.Label>
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            onChange={(e) => onChange(e)}
                            value={financeAgreement.daily_rate}
                            name={"daily_rate"}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>
            <Columns>
                <Columns.Column>
                    <Form.Label>Mileage Allowance</Form.Label>
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            onChange={(e) => onChange(e)}
                            value={financeAgreement.mileage_allowance}
                            name={"mileage_allowance"}
                        />
                    </Form.Control>
                </Columns.Column>
                <Columns.Column>
                    <Form.Label>On Hire Mileage</Form.Label>
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            onChange={(e) => onChange(e)}
                            value={financeAgreement.on_hire_mileage}
                            name={"on_hire_mileage"}
                        />
                    </Form.Control>
                </Columns.Column>
            </Columns>
        </div>
    )



}