import React, {useState} from "react";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import useInput from "../../../../hooks/useInput";
import usePostTransactionCategory from "../../../../hooks/usePostTransactionCategory";
import { Button } from "react-bulma-components";
import Collapsible from "react-collapsible";
import { Form } from "react-bulma-components";
import {useSelector} from "react-redux";
import {getTransactionReportingCategoriesData} from "../../../../state/selectors";

function TransactionCategory() {
  let [transactionCategory, nameInput] = useInput({
    category: "text",
    label: "Transaction Name"
  });


  const reportingCategories = useSelector(getTransactionReportingCategoriesData).results;

  const reportingCategoryMap = reportingCategories.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const [reportingCategory, setReportingCategoryType] = useState(reportingCategories.length > 0 ? reportingCategories[0] : null);

  const [, postTransactionCategory] = usePostTransactionCategory({
    name: transactionCategory,
    transaction_reporting_category: reportingCategory
  });

  const handleSubmit = () => {
    postTransactionCategory();
  };

  const handleReportingCategoryUpdate = (e) => {
    setReportingCategoryType(reportingCategoryMap[e.target.value]);
  };

  return (
    <Box className="settings_type">
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Transaction Category"
      >
        <Columns>
          {nameInput}
          <Columns.Column>
          <Form.Field>
            <Form.Control>
              <Form.Label>Type</Form.Label>
              <Form.Select
                onChange={handleReportingCategoryUpdate}
                name="type"
                value={reportingCategory ? reportingCategory.id : reportingCategories.length > 0 ? reportingCategories[0].id : null}
              >
                {reportingCategories.map(value => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>
          </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default TransactionCategory;
