import React from "react";
import {Button, Columns, Heading, Modal, Section} from "react-bulma-components";



export default function VehicleMileageWarning({open, setOpen, job}){

    return (
        <Modal
          closeOnBlur
          show={open}
          onClose={() => {
            setOpen(false);
          }}
        >
            <Modal.Content>
                <Section style={{ backgroundColor: "white" }}>
                    <Columns>
                        <Columns.Column>
                            <Heading>Vehicle Mileage Warning</Heading>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <p>This vehicle is approaching its mileage
                                limit {job.vehicle ? job.vehicle.model ? "of " + job.vehicle.model.mileage_limit : "" : ""}</p>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                           <Button
                                color={"info"}
                                onClick={(e) => setOpen(false)}
                                fullwidth
                            >
                                Close
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )


}