import React from "react";
import useInput from "../../../hooks/useInput";
import {Box, Button, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import {usePostFitter} from "../../../hooks/usePostFitter";


function NewFitter(){

    let [fitterName, fitterInput, setFitterName] = useInput({
        type: "text",
        label: "Fitter Name"
    });

    const [,postFitter] = usePostFitter({
        name: fitterName
    });
    const handleSubmit = () => {
        postFitter().then(setFitterName(""));
    }
    return (
        <Box>
            <Collapsible
                className={"title"}
                triggerOpenedClassName={"title"}
                trigger={"Add Fitter"}
            >
                <Columns.Column>
                    {fitterInput}
                </Columns.Column>
                <Columns.Column>
                    <Button
                        onClick={handleSubmit}
                        fullwidth={true}
                        color={"success"}
                    >
                        Add Fitter
                    </Button>
                </Columns.Column>
            </Collapsible>
        </Box>
    )

}
export default NewFitter;