import React, { useState } from "react";
import { useFiguresData } from "../../hooks/useFiguresData";
import { useSelector } from "react-redux";
import { getPartFiguresData, getWorkFiguresData } from "../../state/selectors";
import { Heading } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import Figure from "../Homepage/Figure";
import { Box } from "react-bulma-components";
import { formatDate, getLastDayOfWeek, getFirstDayOfWeek } from "../../utils";
import { Form } from "react-bulma-components";
function Figures(props) {
  const [start, setStart] = useState(getFirstDayOfWeek(new Date()));
  const [end, setEnd] = useState(getLastDayOfWeek(start));

  const onWeekSelect = e => {
    let first = getFirstDayOfWeek(new Date(e.target.value));
    let finish_date = getLastDayOfWeek(first);
    setStart(first);
    setEnd(finish_date);
  };

  let dates = { start: formatDate(start), end: formatDate(end) };
  let customers = []
  let params = {start: dates.start, end: dates.end}
  if(props.department){
    params.department = props.department;
  }
  const complete = useFiguresData(params);

  const getTotalWorkshopProfitLoss = (part, work) => {
    return (
      work.total_customer_labour_cost +
      part.total_customer_part_cost -
      work.total_labour_cost -
      part.total_part_cost
    );
  };

  const mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
  }



  const data = {
    work: {
      loaded: complete.workLoading,
      data: useSelector(getWorkFiguresData)
    },
    part: {
      loaded: complete.partsLoading,
      data: useSelector(getPartFiguresData)
    }
  };

  if (data.work.loaded && data.part.loaded) {
    mergeByProperty(data.work.data.customers, data.part.data.customers, 'display_name');
  }

  const dataLoaded = data.part.loaded && data.work.loaded;

  return (
    <Box>
      <Heading size={5}>Workshop Weekly Figures</Heading>
      <Form.Label>Select a Date</Form.Label>
      <Form.Field className="contact-section has-addons">
        <Form.Control>
          <Form.Input
            type="date"
            value={formatDate(start)}
            name="day"
            onChange={onWeekSelect}
          />
        </Form.Control>
      </Form.Field>
      <Heading size={6}>
        {start.toDateString()} - {end.toDateString()}
      </Heading>
      <Columns>
        <Figure
          label="Total Parts (All Suppliers)"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_part_cost : 0}
        />
        <Figure
          label="Total Labour (All Suppliers)"
          loaded={data.part.loaded}
          value={data.work.loaded ? data.work.data.total_labour_cost : 0}
        />
        <Figure
          label="Total Customer Part Charges"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_customer_part_cost : 0}
        />
        <Figure
          label="Total Customer Labour Charges"
          loaded={data.work.loaded}
          value={
            data.work.loaded ? data.work.data.total_customer_labour_cost : 0
          }
        />
        <Figure
          label="Total Workshop Parts/Labour Profit"
          loaded={data.work.loaded && data.part.loaded}
          value={
            data.work.loaded && data.part.loaded
              ? getTotalWorkshopProfitLoss(data.part.data, data.work.data)
              : 0
          }
        />
      </Columns>
      <Columns>
        <Figure
          label="Internal Accts. Part Charges"
          loaded={data.part.loaded}
          value={data.part.loaded ? data.part.data.total_internal_accounts_charges : 0}
        />
        <Figure
          label="Internal Accts. Labour Charges"
          loaded={data.work.loaded}
          value={
            data.work.loaded ? data.work.data.total_internal_accounts_charges : 0
          }
        />
        <Figure
          label="Internal Accts. Total Charges (Fleet Maintenance Cost)"
          loaded={data.part.loaded && data.work.loaded}
          value={
            data.work.loaded && data.part.loaded
              ? data.work.data.total_internal_accounts_charges +
                data.part.data.total_internal_accounts_charges
              : 0
          }
        />
      </Columns>
      {dataLoaded ? data.work.data.customers.map((customer, index) =>
        <Columns key={customer.id}>
          <Figure
            label={`${customer.display_name} Supplier Part Cost`}
            loaded
            value={customer.part_cost}
          />
          <Figure
            label={`${customer.display_name} Supplier Labour Cost`}
            loaded
            value={ customer.labour_cost }
          />
          <Figure
            label={`${customer.display_name} Total Supplier Cost`}
            loaded
            value={
              customer.labour_cost + customer.part_cost
            }
          />
        </Columns>
      ) : <></>}
    </Box>
  );
}

export default Figures;
