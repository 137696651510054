import React from "react"
import {useSelector} from "react-redux";
import {getDataFresh, getDepartmentsData} from "../../../state/selectors";
import {useDepartmentsData} from "../../../hooks/useDepartmentsData";
import {Box, Heading, Table} from "react-bulma-components";
import Spinner from "react-spinner-material";
import DepartmentRow from "./DepartmentRow";
import NewDepartment from "./NewDepartment";




function DepartmentView(props){
    let params = {showhide: true};
    let dataFresh = useSelector(getDataFresh);

    let loading = useDepartmentsData(params, dataFresh);
    let departments = useSelector(getDepartmentsData)

    if (!loading) {
        return (
        <Box>
            <Spinner
                className="spinner-centre"
                size={120}
                spinnerColor={"#3273dc"}
                spinnerWidth={2}
            />
        </Box>
        );
    }


    return (
        <div>
        <br/>
        <NewDepartment/>
        <Box>
            <Heading>Departments</Heading>
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Hide</th>
                        <th></th>
                    </tr>
                </thead>
                {departments.results.map(department => (
                    <DepartmentRow
                        department={department}
                        dataFresh={dataFresh}
                    />
                ))}
            </Table>
        </Box>
        </div>
    )
};
export default DepartmentView;