import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import useInput from "../../hooks/useInput";

export default function MultipleEditModalWorkshop({
  open,
  setOpen,
  selected,
  saveSelected
}) {
  const [paymentDate, paymentDateInput, setPaymentDate] = useInput({
    type: "date",
    label: "Payment Date"
  });
  const [invoiceNumber, invoiceNumberInput, setInvoiceNumber] = useInput({
    type: "text",
    label: "Workshop Invoice Number"
  });

  const onSave = () => {
    saveSelected(invoiceNumber, paymentDate).then(onClose);
  };

  const onClose = () => {
    setOpen(false);
    setInvoiceNumber("");
    setPaymentDate("");
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Columns>
            {invoiceNumberInput}
            {paymentDateInput}
          </Columns>
          <Form.Field>
            <p>You are editing {Object.keys(selected).length} work items</p>
          </Form.Field>
          <Button color="success" onClick={onSave}>
            Save
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
