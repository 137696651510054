import React from "react";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import useInput from "../../hooks/useInput";
import usePostTarget from "../../hooks/usePostTarget";
import { Button } from "react-bulma-components";
import Collapsible from "react-collapsible";

function NewTarget() {
  let [weekly_target, amountInput] = useInput({
    type: "number",
    label: "Amount",
    currency: true
  });
  const [active_from, dateInput] = useInput({
    type: "date",
    label: "Active from"
  });
  let [active_to, activeToInput] = useInput({
    type: "date",
    label:
      "Active To (Leave blank if this is a new weekly target going forward)"
  });

  active_to = active_to ? active_to : undefined;
  weekly_target = parseFloat(weekly_target);

  const [, postTarget] = usePostTarget({
    weekly_target,
    active_from,
    active_to
  });

  const handleSubmit = () => {
    postTarget();
  };

  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Target"
      >
        <Columns>
          {dateInput}
          {activeToInput}
          {amountInput}
        </Columns>

        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default NewTarget;
