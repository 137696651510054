import React, {Component} from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import { Button } from "react-bulma-components";
import {formatDate} from "../../utils";
import Collapsible from "react-collapsible";
import ContactSection from "../Contact/ContactSection";

function CustomerOwnerSection(props) {
  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Customer Owner Info"
      >
        <ContactSection
          title="Customer"
          contactUpdate={customer => props.onChange({target: {name: 'owner', value: customer}})}
          endpoint={props.endpoint + "contacts"}
          contact={props.data.owner || {}}
          type="CC"
        />
      </Collapsible>
    </Box>
  );
}

export default CustomerOwnerSection;
