import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React, { useState } from "react";
import { formatDate, getFirstDayOfWeek } from "../utils";

function useWeekInput({ label, initialValue, weekly, disabled, callback }) {
  const [value, setValue] = useState(formatDate(initialValue) || "");

  const onChange = e => {
    setValue(e.target.value);
    if(callback) {
      callback();
    }
  }
  const setDate = date => {
    // const selected = new Date(date);
    // const value = weekly && selected ? formatDate(getFirstDayOfWeek(selected)) : date;
    // setValue(formatDate(value));
    // setValue(value);
  };
  const input = (
    <Columns.Column>
      <Form.Field>
        <Form.Label>{label}</Form.Label>
        <Form.Control>
          <Form.Input
            value={value}
            onChange={onChange}
            type={"date"}
            disabled={disabled}
          />
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useWeekInput;
