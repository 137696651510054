import React, {useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {NotificationManager} from "react-notifications";
import {Button, Form, Icon} from "react-bulma-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";




export function VehicleTypeTrackerRow({type, dataFresh, onVehicleTrackerTypeUpdate, index}){
    const dispatch = useDispatch();
    const [typeData, setTypeData] = useState(type);
    const [editable, setEditable] = useState(false);

    const onDelete = () => {
        const conf = {
            url: "api/vehicletrackertype/"+type.id,
            method: "delete"
        };
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
        }).catch(err => {
            if(err){
                NotificationManager.error("There was an error")
            }
        })
    };

    const onEdit = e => {
        e.stopPropagation();
        setEditable(!editable);
    };

    const submitUpdate = e => {
        e.stopPropagation();
        const url = "api/vehicletrackertype/" + typeData.id;
        const conf = {
          method: "put",
          data: typeData,
          url
        };

        axios(conf).then(() => {
          setEditable(false);
          dispatch(createSetDataFreshAction(dataFresh + 1));
        });
    };

    const onChange = e => {
        let newVehicleTrackerType = { ...type };
        newVehicleTrackerType[e.target.name] = e.target.value || null;
        setTypeData(newVehicleTrackerType);
        onVehicleTrackerTypeUpdate(index, newVehicleTrackerType);
    };


    return (
        <tr key={type.id}>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"text"}
                            name={"name"}
                            value={typeData.name}
                            disabled={!editable}
                            onChange={onChange}
                        />
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            name={"cost"}
                            value={typeData.cost}
                            disabled={!editable}
                            onChange={onChange}
                        />
                        <Icon align={"left"} className={"small-icon"}>
                            <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
                    <Form.Control>
                        <Form.Input
                            type={"number"}
                            name={"upfront_cost"}
                            value={typeData.upfront_cost}
                            disabled={!editable}
                            onChange={onChange}
                        />
                        <Icon align={"left"} className={"small-icon"}>
                            <FontAwesomeIcon icon={faPoundSign}/>
                        </Icon>
                    </Form.Control>
                    {editable ? (
                        <Form.Control>
                            <Button color="success" onClick={submitUpdate}>
                                Save
                            </Button>
                        </Form.Control>
                    ) : (
                        <Form.Control>
                            <Button onClick={onEdit}>Edit</Button>
                        </Form.Control>
                    )}
                </Form.Field>
            </td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    )

}