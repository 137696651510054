import React from "react";
import Collapsible from "react-collapsible";
import {Box, Button, Columns} from "react-bulma-components";
import useInput from "../../../hooks/useInput";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../../actions/dataActions";

export function NewVehicleTrackerType({dataFresh}){
    const dispatch = useDispatch();
    const [name, nameInput, setName] = useInput({
        type: "text",
        label: "Name"
    });
    const [cost, costInput, setCost] = useInput({
        type: "number",
        currency: true,
        label: "Monthly Cost"
    });
    const [upFrontCost, upFrontCostInput, setUpFrontCost] = useInput({
        type: "number",
        currency: true,
        label: "UpFront Cost"
    });

    const clear = () =>{
        setCost(0);
        setName("");
        setUpFrontCost(0);
    }

    const onAdd = () => {
        const conf = {
            data: {name:name, cost:cost, upfront_cost:upFrontCost},
            method: "post",
            url: "api/vehicletrackertype"
        }
        axios(conf).then((res)=> {
            dispatch(createSetDataFreshAction(dataFresh + 1));
            clear()
        }).catch(err => {
            if(err){
                NotificationManager.error("There was an error");
            }
        })
    }

    return (
        <div>
            <br/>
            <Box>
                <Collapsible
                    trigger="New Vehicle Tracker Type"
                    className={"title"}
                    triggerOpenedClassName={"title"}
                >
                    <Columns>
                        {nameInput}
                        {costInput}
                        {upFrontCostInput}
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                fullwidth
                                color={"success"}
                                onClick={() => onAdd()}
                            >
                                Add +
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
            <br/>
        </div>
    )


}