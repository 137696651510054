import React from "react";
import logo from "../Nav/img/Logo.jpg";
import {Columns, Table} from "react-bulma-components";
import PrintWashRow from "./PrintWashRow";


class PrintListWash extends React.Component {
    render(){
        let jobs = this.props.data.results //list of all the jobs
        let totalRows = jobs.length;
        const additionalRows = 15 - totalRows
        let rows = []
        for (let i = 0; i < additionalRows; i++) {
            rows.push(
                <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            );
        }
        return (
            <div>
                <Columns>
                    <img src={logo} style={{width: '110px', height: '35px'}} alt="Rentals Direct Ltd"/>
                    <h2 className="job-card-print-title" data-testid={"title"}>
                    Wash Needed
                    </h2>
                </Columns>
                <Table bordered size={"fullwidth"}>
                    <thead>
                        <th>Registration</th>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Customer</th>
                        <th>Mileage</th>
                        <th>Start Time</th>
                        <th>Finish Time</th>
                        <th>Charge</th>
                    </thead>
                    <tbody>
                        {jobs.map((job, index) => (
                           <PrintWashRow job={job} index={index}/>
                        ))}
                        {
                            rows.map(row => row)
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}
export default PrintListWash;