import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableNumberCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  inlineEdit,
  replaceInput,
}) {
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control>
          {replaceInput ? replaceInput :
            <Form.Input
              className="small-row-black"
              onChange={onChange}
              type="number"
              name={propName}
              value={value}
              disabled={!editable}
            />
          }
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableNumberCell;
