import React, { Component } from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { useSelector } from "react-redux";
import { getVehicleSummaryData } from "../../../state/selectors";

export default function VehicleTotals() {
  const data = useSelector(getVehicleSummaryData);
  return (
    <Box>
      <Columns>
        <Columns.Column>
          Total Purchase Price: £
          {data.total_purchase_price.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
        <Columns.Column>
          Total Estimated Value: £
          {data.total_estimated_value.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
        <Columns.Column>
          Total Monthly Payment: £
          {data.total_monthly_payment.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
        <Columns.Column>
          Total Annual Tax: £
          {data.total_tax.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
        <Columns.Column>
          Total Remaining Finance: £
          {data.total_remaining_finance.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
        <Columns.Column>
          Difference: £
          {(
            data.total_estimated_value - data.total_remaining_finance
          ).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}
        </Columns.Column>
      </Columns>
    </Box>
  );
}
