import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Tile } from "react-bulma-components";
import _ from "lodash";
import RentalTile from "./RentalTile";
import React, { Component } from "react";
import axios from "axios";
import { readableDate, sortRentals } from "../../utils";

export default class Weekly extends Component {
  constructor(props) {
    super(props);
    this.state = sortRentals(this.props.rentals, this.props.startDate);
  }

  DATES = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  state = {
    rentals: this.props.rentals,
    day_rentals: [[], [], [], [], [], [], []],
    day_totals: [0, 0, 0, 0, 0, 0, 0]
  };

  componentDidMount() {
    axios
      .get("/api/target")
      .then(resp =>
        this.setState({ weekly_target: resp.data.results[0]?.weekly_target ?? 0 })
      );
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(prevProps.rentals, this.props.rentals) ||
      prevProps.startDate !== this.props.startDate
    ) {
      this.setState(sortRentals(this.props.rentals, this.props.startDate));
    }
  }

  render() {
    return (
      <div>
        <Box>
          <Columns>
            <Columns.Column>
              Weekly Total: £
              {this.state.day_totals
                .reduce((total, num) => total + num, 0)
                .toFixed(2)}
            </Columns.Column>
            <Columns.Column>
              Weekly Target: £{this.state.weekly_target}
            </Columns.Column>
            <Columns.Column>
              Against Weekly Target: £
              {(
                this.state.day_totals.reduce((total, num) => total + num, 0) -
                this.state.weekly_target
              ).toFixed(2)}
            </Columns.Column>
            {!!this.props.customerTarget && (
              <Columns.Column>
                {this.props.customerName} Target: £{this.props.customerTarget}
              </Columns.Column>
            )}
            {!!this.props.customerTarget && (
              <Columns.Column>
                Against {this.props.customerName} Target: £
                {(
                  this.state.day_totals.reduce((total, num) => total + num, 0) -
                  this.props.customerTarget
                ).toFixed(2)}
              </Columns.Column>
            )}
          </Columns>
        </Box>
        <Box>
          <h1 className="title">Rentals</h1>
          <Columns>
            {this.DATES.map((day, index) => {
              let monday = new Date(this.props.startDate);
              monday.setDate(monday.getDate() + index);

              return (
                <Columns.Column className="rental-column" key={day}>
                  <Heading>{day}</Heading>
                  <Heading subtitle>
                    {readableDate(this.props.formatDate(monday))}
                  </Heading>
                  <Heading>£{this.state.day_totals[index].toFixed(2)}</Heading>
                  <Tile kind="parent" vertical>
                    {this.state.day_rentals[index].map(rental => (
                      <RentalTile key={rental.id} rental={rental} />
                    ))}
                  </Tile>
                </Columns.Column>
              );
            })}
          </Columns>
          <Pagination
            showFirstLast={true}
            onChange={page => this.props.onPageChange(page)}
            current={this.props.current}
            total={Math.ceil(this.props.count / 25)}
          />
        </Box>
      </div>
    );
  }
}
