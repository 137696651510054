import React, { Component } from "react";
import { Heading } from "react-bulma-components";
import { Tile } from "react-bulma-components";
import { Link } from "react-router-dom";

export default class RentalTile extends Component {
  render = () => (
    <Tile renderAs="article" kind="child" className="rental-tile">
      <Link target="_self" to={"/editrental/" + this.props.rental.id}>
        <Heading subtitle className="no-underline">
          {this.props.rental.customer.display_name}
        </Heading>
        <Heading className="no-underline registration-field">
          {this.props.rental.vehicle.registration}
        </Heading>
      </Link>
    </Tile>
  );
}
