import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React, { useState } from "react";

function useInput({ label, initialValue, options, testId, callback }) {
  const [value, setValue] = useState(initialValue || "");

  const onChange = e => {
    setValue(e.target.value);
    if (callback) {
      callback();
    }
  };

  const input = (
    <Columns.Column>
      <Form.Field>
        <Form.Label>{label}</Form.Label>
        <Form.Control>
          <Form.Select value={value} onChange={onChange} data-testid={testId}>
            {options.map(item => (
              <option key={item.name} value={item.value}>
                {item.name}
              </option>
            ))}
          </Form.Select>
        </Form.Control>
      </Form.Field>
    </Columns.Column>
  );
  return [value, input, setValue];
}

export default useInput;
