import { Section } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React, {useState} from "react";
import { Modal } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Box } from "react-bulma-components";
import axios from "axios";
import { Columns } from "react-bulma-components";
import ContactSection from "../Contact/ContactSection";
import useInput from "../../hooks/useInput";
import {NotificationManager} from "react-notifications";

const PartRow = ({part}) => {
  return (
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td>{part.name}</td>
      <td>{part.supplier && part.supplier.display_name}</td>
      <td>{part.part_number}</td>
      <td>{part.customer_cost}</td>
    </tr>
  );
};

const WorkRow = ({work, onCheck, selected}) => {
  const rows = [];

  rows.push(
    <tr
      className="clickable table-highlight-orange small-row-white"
      onClick={() => window.open(`/editjob/${work.job}`, "_self")}
      key={work.id}
    >
      <td>{work.description}</td>
      <td>{work.labour_hours}</td>
      <td>
        £
        {(work.customer_labour_cost | 0).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}
      </td>
      <td></td>
      <td></td>
      <td>Parts Total:</td>
      <td>
        £
        {work.parts
          .reduce((total, part) => total + part.customer_cost, 0)
          .toFixed(2)}
      </td>
      <td onClick={e => e.stopPropagation()}>
        <Form.Field>
          <Form.Control className="table-checkbox">
            <Form.Checkbox
              checked={selected}
              type="checkbox"
              readOnly
              onClick={() => onCheck(work)}
            />
          </Form.Control>
        </Form.Field>
      </td>
    </tr>
  );

  let partRows = work.parts.map(part => <PartRow part={part}/>);

  return rows.concat(partRows);
};

export default function CreateJobCardModal({
                                             modalOpen,
                                             setModalState,
                                             workItems,
                                             quote,
                                             endpoint,
                                             history
                                           }) {
  const setModalOpen = val => {
    setModalState({
      modalOpen: val
    });
  };

  const [selected, setSelected] = useState({});
  const [workshop, setWorkshop] = useState();

  const [bookInDate, bookInDateInput] = useInput({
    type: "date",
    label: "Workshop Date",
    initialValue: null
  });

  const [startDate, startDateInput] = useInput({
    type: "date",
    label: "Book In Date",
    initialValue: null
  });

  const onCheck = item => {
    let newSelected = {...selected};
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newSelected[item.id.toString()] = item;
    } else {
      delete newSelected[item.id.toString()];
    }
    setSelected(newSelected);
  };

  const onClick = e => {
    let data = {
      quote: quote,
      items: Object.values(selected),
      customerInvoiceNumber: quote.customer_invoice_number,
      customer2InvoiceNumber: quote.customer2_invoice_number,
      workshop,
      bookInDate,
      startDate
    };

    if (!bookInDate || !startDate || !workshop) {
      NotificationManager.warning(
        `You must specify a Workshop, Book In Date and Start Date`,
        "Missing Data",
        10000
      );
    } else {
      const method = "post";
      const url = endpoint + "createquotejob";
      const conf = {
        method,
        data,
        url
      };
      axios(conf).then(response =>
        history.push("/editjob/" + response.data.id)
      );
    }
  };

  return (
    <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Content className="part-search-content">
        <Section style={{backgroundColor: "grey"}}>
          <Box>
            <Columns>
              <Columns.Column>
                <ContactSection
                  title="Workshop"
                  contactUpdate={setWorkshop}
                  endpoint={endpoint + "contacts"}
                  contact={workshop || {}}
                  type="SP"
                />
              </Columns.Column>
              <Columns.Column>
                <Columns>{bookInDateInput}</Columns>
                <Columns>{startDateInput}</Columns>
                <Columns>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Customer 1 Invoice Number</Form.Label>
                      <Form.Control>
                        <Form.Input
                          type="text"
                          name="customer_invoice_number"
                          value={quote.customer_invoice_number}
                          disabled
                          readOnly
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column>
                    <Form.Field>
                      <Form.Label>Customer 2 Invoice Number</Form.Label>
                      <Form.Control>
                        <Form.Input
                          type="text"
                          name="customer2_invoice_number"
                          value={quote.customer2_invoice_number}
                          disabled
                          readOnly
                        />
                      </Form.Control>
                    </Form.Field>
                  </Columns.Column>
                </Columns>
              </Columns.Column>
            </Columns>
          </Box>
          <Box>
            <Table>
              <thead>
              <tr>
                <th>Work Description</th>
                <th>Labour Hours</th>
                <th>Labour Cost</th>
                <th>Part Name</th>
                <th>Part Supplier</th>
              </tr>
              </thead>
              <tbody>
              {workItems.map(item => (
                <WorkRow
                  key={item.id}
                  work={item}
                  onCheck={onCheck}
                  selected={
                    item.id &&
                    Object.keys(selected).indexOf(item.id.toString()) !== -1
                  }
                />
              ))}
              </tbody>
            </Table>
            <Button color="success" onClick={onClick}>
              Create Job Card
            </Button>
          </Box>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
