import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableTextAreaCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit,
  doubleWide
}) {
  const styles = `small-row-black ${doubleWide ? "double-wide-textarea" : "min-column-width"}`;
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Textarea
            rows={2}
            className={styles}
            onChange={onChange}
            type="text"
            name={propName}
            value={value}
            disabled={!editable}
          />
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, disabled, setEditable, editable})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableTextAreaCell;
