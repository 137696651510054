import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import React from "react";

export const FiguresSection = props => {
  return (
    <Box>
      <Columns>
        {props.figures.map(figure => (
          <Columns.Column key={figure.name}>
            {figure.name}: £
            {(props.data[figure.prop] || 0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Columns.Column>
        ))}
        <Columns.Column>
          Total: £
          {props.figures
            .reduce((total, figure) => {
              return (total += props.data[figure.prop]);
            }, 0)
            .toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
        </Columns.Column>
      </Columns>
    </Box>
  );
};
