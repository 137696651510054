import React from "react";
import { Icon } from "react-bulma-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faSearch,
  faEdit,
  faSave,
  faCheck,
  faCheckCircle,
  faTimes,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faCoffee,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare,
  faSearch,
  faEdit,
  faSave,
  faCheck,
  faCheckCircle,
  faTimes,
  faExclamationTriangle
);

/**
 * @see https://bulma.io/documentation/elements/icon/#sizes
 * @param {String} size
 */
const getFASize = size => {
  switch (size) {
    case "small":
      return "sm";
    case "medium":
      return "2x";
    case "large":
      return "3x";
    default:
      return "lg";
  }
};

export default props => (
  <Icon size={props.size}>
    <FontAwesomeIcon {...props} size={getFASize(props.size)} />
  </Icon>
);
