import React, {useEffect, useState} from "react";
import axios from "axios";
import {createSetDataFreshAction} from "../../actions/dataActions";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";
import { Button } from "react-bulma-components";
import {readableDate} from "../../utils";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";
import {updateWorkshopInvoice} from "../Invoicing/updateInvoice";
import useSafeSave from "../../hooks/useSafeSave";
import useEditableTableTextAreaCell from "../../hooks/useEditableTableTextAreaCell";
import useEditableTableTextCell from "../../hooks/useEditableTableTextCell";
import useEditableTableCostCell from "../../hooks/useEditableTableCostCell";
import DeleteWarningModal from "../Modal/DeleteWarningModal";
import useEditableTableDropdownCell from "../../hooks/useEditableTableDropdownCell";
import { Form } from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import FAIcon from "../Icon/FAIcon";
import VehicleMakePicker from "../Vehicle/VehicleMakePicker";
import useInlineEditButton from "../../hooks/useInlineEditButton";
import VehicleModelPicker from "../Vehicle/VehicleModelPicker";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function PartLookupRow({partLookup, endpoint, updateValue, vehicleTypes, selected, onSelectCheck, models, makes}) {
  let dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [editable, setEditable] = useState(false);
  const [, setTypeahead] = useState();
  const [isLoading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [lastEdited, setLastEdited] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  let filteredModels = models ? [...models.results] : [];

  if(partLookup.make && partLookup.make.id && models){
    filteredModels = filteredModels.filter((model) => model.make && (model.make.id === partLookup.make.id));
  }


  const onSave = e => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "put",
      data: partLookup,
      url
    };
    setEditable(false);
    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onCheck = e => {
    e.stopPropagation();
    onSelectCheck(partLookup);
  };


  const handleDelete = e => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "delete",
      url
    };
    setEditable(false);

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  }

  const checkUpdated = () => {
    return axios
      .get(endpoint + "partlookup/update/" + partLookup.id)
      .then(
        checkPartLookup =>
          (new Date(checkPartLookup.data.last_saved).getTime() -
            new Date(partLookup.last_saved).getTime()) /
          1000 <
          1
      );
  };


  const [makeDirty, safeSave] = useSafeSave(
    onSave,
    lastEdited,
    undefined,
    checkUpdated
  );

  const typeFields = vehicleTypes;
  const typeObjects = typeFields.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const makeObjects = makes.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  const modelObjects = models.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});


  const onTypeChange = (e, index) => {
    const toUpdate = {
      target: {
        name: "vehicle_type",
        value: typeObjects[e.target.value]
      }
    };
    onChange(toUpdate, index);
  };

  const onChange = e => {
    makeDirty();
    setLastEdited(new Date());
    let newPartLookup = {...partLookup};
    newPartLookup[e.target.name] = e.target.value;
    updateValue(newPartLookup);
  };

  const onMakeChange = e => {
    const toUpdate = {
      target :{
        name:"make",
        value: e.target.value === "BLANK" ? null : makeObjects[e.target.value]
      }
    }
    onChange(toUpdate)
  }

  const onModelChange = e => {
    const toUpdate = {
      target :{
        name:"model",
        value: e.target.value === "BLANK" ? null : modelObjects[e.target.value]
      }
    }
    onChange(toUpdate)
  }

  const handleSearch = e => {
    setLoading(true);
    axios.get(`/api/contacts?contact_type=SP&search=${e}`).then(resp => {
      setSearchData(resp.data.results);
      setLoading(false);
    });
  }

  const handlePartSupplierSelect = supplier => {
    let newPartLookup = {...partLookup}
    newPartLookup.supplier = supplier[0];
    updateValue(newPartLookup);
  };


  const saveAfterButtonClick = () => {
    const url = endpoint + "partlookup/update/" + partLookup.id;
    const conf = {
      method: "put",
      data: partLookup,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const buttonClick = prop => {
    partLookup[prop] = !partLookup[prop];
    saveAfterButtonClick();
  };

  return (
      <tr key={partLookup.id}>
        <td>
          <Form.Field className={"has-addons"}>
            <VehicleMakePicker
                onChange={onMakeChange}
                makes={makes}
                vehicle={partLookup}
                disabled={!editable}
                showTitle={false}
            />
            {useInlineEditButton({show: true, onSave, setEditable, editable})}
          </Form.Field>
        </td>
        <td>
          <Form.Field className={"has-addons"}>
            <VehicleModelPicker
                onChange={onModelChange}
                models={{results: filteredModels}}
                vehicle={partLookup}
                disabled={!editable}
                showTitle={false}
            />
            {useInlineEditButton({show: true, onSave, setEditable, editable})}
          </Form.Field>
        </td>
        {useEditableTableTextAreaCell({
              propName: "variant",
              value: partLookup.variant,
              onChange: onChange,
              onSave: safeSave,
              editable,
              setEditable,
              inlineEdit: true
            }
        )}
        {useEditableTableDropdownCell({
              propName: "vehicle_type",
              value: partLookup.vehicle_type,
              onChange: onTypeChange,
              onSave: safeSave,
              options: vehicleTypes,
              editable,
              setEditable,
              inlineEdit: true
            }
        )}
        {useEditableTableTextCell({
              propName: "name",
              value: partLookup.name,
              onChange: onChange,
              onSave: safeSave,
              editable,
              setEditable,
              inlineEdit: true
            }
        )}
        {useEditableTableTextCell({
              propName: "part_number",
              value: partLookup.part_number,
              onChange: onChange,
              onSave: safeSave,
              editable,
              setEditable,
              inlineEdit: true
            }
        )}
        {useEditableTableCostCell({
          propName: "cost",
          value: partLookup.cost,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        <td>
          <Form.Field className="has-addons">
            <Form.Control>
              <AsyncTypeahead
                  useCache={false}
                  id="typeahead"
                  labelKey="display_name"
                  minLength={2}
                  name={"supplier"}
                  onSearch={handleSearch}
                  onChange={handlePartSupplierSelect}
                  placeholder={"Search Supplier"}
                  disabled={!editable}
                  options={searchData}
                  ref={typeahead => setTypeahead(typeahead)}
                  className="typeahead"
                  isLoading={isLoading}
                  selected={partLookup.supplier && partLookup.supplier.id ? [partLookup.supplier] : []}
              />
            </Form.Control>
            {editable ? (
                <Form.Control>
                  <Button
                      className="small-row-white"
                      color="success"
                      onClick={onSave}
                  >
                    <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                  </Button>
                </Form.Control>
            ) : (
                <Form.Control>
                  <Button
                      className="small-row-black"
                      onClick={() => setEditable(!editable)}
                  >
                    <FAIcon size="small" icon={["fas", "edit"]}/>
                  </Button>
                </Form.Control>
            )}
          </Form.Field>
        </td>
        {useEditableTableNumberCell({
          propName: "stock_volume",
          value: partLookup.stock_volume,
          onChange: onChange,
          onSave: safeSave,
          editable,
          setEditable,
          inlineEdit: true
        })}
        {useEditableTableTextAreaCell({
              propName: "comments",
              value: partLookup.comments,
              onChange: onChange,
              onSave: safeSave,
              editable,
              setEditable,
              inlineEdit: true
            }
        )}
        <td onClick={e => e.stopPropagation()}>
          <Form.Field>
            <Form.Control className="table-checkbox">
              <Form.Checkbox
                  checked={selected}
                  type="checkbox"
                  onClick={onCheck}
                  readOnly
              />
            </Form.Control>
          </Form.Field>
        </td>
        <td className="float-right">
          <Button
              color="danger"
              data-testid={`submit-part-delete${partLookup.id}`}
              onClick={setDeleteModalOpen}
          >
            Delete
          </Button>
        </td>
        <DeleteWarningModal
            open={deleteModalOpen}
            setOpen={setDeleteModalOpen}
            onDelete={handleDelete}
        />
      </tr>
  );
}
