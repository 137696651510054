import React, {useEffect, useState} from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Table } from "react-bulma-components";
import {useDispatch, useSelector} from "react-redux";
import {
  getCostCentresData,
  getDataFresh, getRecurringPaymentsData,
} from "../../state/selectors";
import { Pagination } from "react-bulma-components";
import RecurringPaymentRow from "./RecurringPaymentRow";
import {
  createSetRecurringPaymentsDataAction,
} from "../../actions/dataActions";
import CreateRecurringPaymentModal from "./CreateRecurringPaymentModal";
import {useRecurringPaymentData} from "../../hooks/useRecurringPaymentData";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";

function RecurringPayment(props) {
  const [searchText, setSearchText] = useState("");
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch();

  const [createRecurringPaymentModalOpen, setCreateRecurringPaymentModalOpen] = useState(false);

  const headings = [
    {
      label: "Contact",
      field: "contact",
      labelField: "contact__display_name"
    },
    {
      label: "Payment Type",
      field: "payment_type",
      labelField: "payment_type"
    },
    {
      label: "Payment Interval",
    },
    {
      label: "Interval Days",
    },
    {
      label: "Payment Amount",
    },
    {
      label: "Cost Centre",
      field: "cost_centre",
      labelField: "cost_centre__name"
    },
    {
      label: "Start Date",
    },
    {
      label: "End Date",
    },
    {
      label: "Comments"
    }
  ];

  let params = {
    page,
    search,
  };

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter] || (filters[filter][0] && filters[filter][0][filter] === 0)) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const recurringPaymentsLoaded = useRecurringPaymentData(params, dataFresh);

  const data = useSelector(getRecurringPaymentsData);
  const costCentres = useSelector(getCostCentresData);

  const updateValue = (index, newRecurringPayment) => {
    let currentData = { ...data };
    currentData.results[index] = {...newRecurringPayment};
    dispatch(createSetRecurringPaymentsDataAction(currentData));
  };

  if (!recurringPaymentsLoaded) return <div>Loading</div>;

  const costCentreObjects = costCentres.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {});

  document.title = "Recurring Payments";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Form.Label>Recurring Payment Search</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setSearch(searchText)}
                  data-testid="part-search"
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Form.Control>
              <Form.Control>
                <Button
                  data-testid="search-button"
                  color="success"
                  onClick={() => {
                    setSearch(searchText);
                  }}
                >
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button
                  color="warning"
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => setCreateRecurringPaymentModalOpen(true)}
                  color="success"
                  className="is-pulled-right"
                >
                  New Recurring Payment +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table>
          <thead>
            <tr>
               {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.results.map((recurringPayment, index) => (
              <RecurringPaymentRow
                key={recurringPayment.id}
                recurringPayment={recurringPayment}
                endpoint={props.endpoint}
                updateValue={newRecurringPayment => updateValue(index, newRecurringPayment)}
                costCentres={costCentres.results}
                costCentreObjects={costCentreObjects}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        />
      </Box>
      <CreateRecurringPaymentModal
        open={createRecurringPaymentModalOpen}
        setOpen={setCreateRecurringPaymentModalOpen}
        endpoint={props.endpoint}
        costCentres={costCentres.results}
        costCentreObjects={costCentreObjects}
      />
    </div>
  );
}

export default RecurringPayment;
