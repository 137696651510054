import React from "react";
import {Form} from "react-bulma-components";
import useInlineEditButton from "../../hooks/useInlineEditButton";

function FitterSelector({fitters, triggerChange, value, editable, setEditable, inlineEdit, onSave, disabled}){
    const fitterObjects = fitters ? fitters.results.reduce((obj, item) => {
        return {
            ...obj,
            [item["id"]]: item
        };
    }, {}) : [];
    const onChange = e =>{
        if(e.target.value === "No Fitter"){
            triggerChange({fitter: null});
        }
        else{
            triggerChange({fitter: fitterObjects[e.target.value]});
        }
    }
    return (
        <Form.Field className={"has-addons"}>
            <Form.Control>
                <Form.Select
                    onChange={(e) =>(onChange(e)) }
                    value={value}
                    disabled={!editable}
                >
                    <option key={"blank"} value={null}>No Fitter</option>
                    {fitters.results.map(item => (
                        <option key={item.name} value={item.id}>
                            {item.name}
                        </option>
                    ))}
                </Form.Select>
            </Form.Control>
            {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
        </Form.Field>
    )

}

export default FitterSelector;