import { useState, useEffect, useCallback } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { createSetDataFreshAction } from "../actions/dataActions";
import { getDataFresh } from "../state/selectors";

const clearSavedNotification = () => {
  const saveNotification = NotificationManager.listNotify.find(
    notif => notif.title === "Warning: Data not yet saved!"
  );
  if (saveNotification) {
    NotificationManager.remove(saveNotification);
  }
};

const doNotSavedNotification = message => {
  const saveNotification = NotificationManager.listNotify.find(
    notif => notif.title === "Warning: Data not yet saved!"
  );
  if (!saveNotification || (message && saveNotification.message !== message)) {
    clearSavedNotification();
    NotificationManager.warning(message, "Warning: Data not yet saved!", 0);
  }
};

export default function useSafeSave(
  saveFunction,
  lastChange,
  validator,
  conflictCheck
) {
  const [clean, setClean] = useState(true);
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);

  const makeDirty = () => {
    setClean(false);
  };

  const safeSave = useCallback(() => {
    return new Promise((res, rej) => {
      setClean(true);
      let validation = validator ? validator() : { result: true };
      if (validation.result) {
        conflictCheck().then(result => {
          if (result) {
            saveFunction()
              .then(() => {
                clearSavedNotification();
                res();
              })
              .catch(err => {
                NotificationManager.error(err.message, "Error!", 7000);
                rej();
                setClean(false);
              });
          } else {
            NotificationManager.error(
              "It looks like the data has been edited elsewhere - fetching the latest update! ",
              "Warning!"
            );
            dispatch(createSetDataFreshAction(dataFresh + 1));
            rej();
          }
        }).catch(err => {
          NotificationManager.error(
            "An error occurred!",
            "Warning!"
          );
        });
      } else {
        let partSuppliers = validation.missingPartSuppliers.length
          ? `\n The following parts are missing suppliers: ${validation.missingPartSuppliers.join(
              ", "
            )}`
          : ``;
        let message = `The following fields are missing: ${validation.missing.join(
          ", "
        )} ${partSuppliers}`;

        doNotSavedNotification(message);
        rej("Validation failed!");
      }
    });
  }, [validator, saveFunction, conflictCheck, dataFresh, dispatch]);

  useEffect(() => {
    if (clean) {
      clearSavedNotification();
    } else {
      doNotSavedNotification();
    }
  }, [clean]);

  return [makeDirty, safeSave];
}
