import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { readableDate } from "../../utils";
import { Link } from "react-router-dom";

export default function ExistingItemModal({
  open,
  setOpen,
  items,
  onContinue,
  vehicle,
  history,
  type,
  hideType
}) {
  const onClick = item => {
    let editUrl = item.itemType === "Job" ? "editjob" : "editquote";
    history.push(`/${editUrl}/${item.id}`);
    setOpen(false);
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Quotes/Jobs already in progress for this vehicle
          </Heading>
          <Columns>
            <Columns.Column>
              <Table>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Description</th>
                    <th>Customer</th>
                    <th>Workshop</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map(item => (
                    <tr className="clickable" onClick={() => onClick(item)}>
                      <td>{item.itemType}</td>
                      <td>{item.description || item.job_description}</td>
                      <td>
                        {item.itemType === "Job"
                            ? item.is_defect
                                ? null
                                : item.customer2
                                    ? item.customer2.display_name
                                    : item.customer
                                      ? item.customer.display_name
                                      : ""
                            :
                            item.customer2
                                ? item.customer2.display_name
                                : item.customer
                                      ? item.customer.display_name
                                      : ""
                        }
                      </td>
                      <td>
                        {item.workshop ? item.workshop.display_name : ""}
                      </td>
                      <td>{readableDate(item.itemType =='Job' ? item.workshop_date : item.provisional_start_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  onContinue(vehicle);
                }}
              >
                Continue {hideType ? "" : "Creating " + type}
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
