import React from "react";
import { useJobData } from "../../hooks/useJobData";
import Form from "./Form";
import {getDataFresh, getDepartmentsData, getKeysData} from "../../state/selectors";
import { useSelector } from "react-redux";
import {useDefaultsData} from "../../hooks/useDefaultsData";
import {useDepartmentsData} from "../../hooks/useDepartmentsData";
import {useFittersData} from "../../hooks/useFittersData";
import {useKeysData} from "../../hooks/useKeysData";

function JobForm(props) {
  let dataFresh = useSelector(getDataFresh);
  let app_defaults_loaded = useDefaultsData({});
  let job_loaded = useJobData(props.match.params.job, dataFresh);
  let departmentsLoaded = useDepartmentsData({}, dataFresh);
  let departments = useSelector(getDepartmentsData);
  let keys_loaded = useKeysData({});
  let keys = useSelector(getKeysData);
  const fittersLoaded = useFittersData({})
  return (
    <Form
      loaded={job_loaded && departmentsLoaded && fittersLoaded && keys_loaded}
      endpoint={props.endpoint}
      history={props.history}
      departments={departments}
      keys={keys}
    />
  );
}

export default JobForm;
