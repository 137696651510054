import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";

import { Icon } from "react-bulma-components";
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

export default function Customer({ contact, handleChange, handleCheckbox }) {
  return (
    <Box>
      <h1 className="title">Customer Details</h1>
      <Columns>
        <Columns.Column>
          <Form.Field>
            <Form.Label className="label">Labour Markup (%)</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="labour_rate_markup"
                onChange={handleChange}
                value={String(contact.labour_rate_markup)}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label className="label">Parts Markup (%)</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="parts_markup"
                onChange={handleChange}
                value={String(contact.parts_markup)}
                required
              />
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Weekly Rentals Target</Form.Label>
            <Form.Control>
              <Form.Input
                type="number"
                name="weekly_target"
                onChange={handleChange}
                value={String(contact.weekly_target)}
              />
              <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
              </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Display Cost Summary on Jobs Page</Form.Label>
            <input
              className="checkbox"
              type="checkbox"
              name="display_job_cost_summary"
              onChange={handleCheckbox}
              checked={contact.display_job_cost_summary}
            />
          </Form.Field>
        </Columns.Column>
        <Columns.Column>
          <Form.Field>
            <Form.Label>Internal Account</Form.Label>
            <input
              className="checkbox"
              type="checkbox"
              name="internal_account"
              onChange={handleCheckbox}
              checked={contact.internal_account}
            />
          </Form.Field>
        </Columns.Column>
      </Columns>
    </Box>
  );
}
