import React from "react";
import {Button} from "react-bulma-components";
import axios from "axios";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import NotificationManager from "react-notifications/lib/NotificationManager";

function WorkshopRow(workshop, dataFresh){
    const dispatch = useDispatch();
    const save = () => {
        const endpoint = "api/contacts/"+workshop.workshop.id;
        const conf ={
            method: "put",
            data: workshop.workshop,
            url: endpoint
        }

        return axios(conf).then((resp) => {
            dispatch(createSetDataFreshAction(dataFresh +2));
        }).catch(err => {NotificationManager.error("An error occurred!")});
    }

    const onClick = (e) => {
        workshop.workshop.job_tab = false;
        save().catch(err => {NotificationManager.error("An error occurred!")});
    }

    return (
        <tr>
            <td>{workshop.workshop.id}</td>
            <td>{workshop.workshop.display_name}</td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onClick}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    )


}
export default WorkshopRow;