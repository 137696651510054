import FilterHeading from "../../../Invoicing/FilterHeading";
import React from "react";
import FilterBlankHeading from "../../../Invoicing/FilterBlankHeading";

function ArrivingHeadings({data, activeTab, vehicleStatus, filters, setPage, setFilters}) {
  const headings = [
      {
        label:"Order No",
          display:true
      },
      {
          label:"Registration",
          display: true
      },
      {
          label:"Month Due",
          field:"month_due",
          labelField:"month_due",
          display: true
      },
      {
        label: "Deposit Date",
        display:true
      },
      {
        label:"Deposit To Be Paid",
        display: true
      },
      {
        label: "Delivery Date",
        field: "delivery_date",
        labelField: "delivery_date",
        date: true,
        display:true
      },
      {
          label:"Make",
          field:"make",
          labelField:"make__name",
          display: true
      },
      {
          label:"Model",
          field:"model",
          labelField:"model__name",
          display: true
      },
      {
          label: "Variant",
          display: true
      },
      {
          label:"Chassis No.",
          display:true
      },
      {
        label:"Purchase Price",
        display:true
      },
      {
          label: "Reserved",
          field: "reserve_customer",
          labelField: "reserve_customer__display_name",
          display:true
      },
      {
          label:"Comments",
          display:true
      }
  ]
  return (
      <tr>
          {headings.map(heading => {
              if(heading.display) {
                  if (!heading.field) {
                        return <th key={heading.label}>{heading.label}</th>;
                  }
                  if (heading.blank) {
                      return (
                          <FilterBlankHeading
                              key={heading.label}
                              heading={heading}
                              setFilters={f => {
                                  setPage(1);
                                  setFilters(f);
                              }}
                              filters={filters}
                          />
                      );
                  }
                  return (
                      <FilterHeading
                          key={heading.label}
                          heading={heading}
                          setFilters={f => {
                              setPage(1);
                              setFilters(f);
                          }}
                          filters={filters}
                          options={data.filter_list[heading.field]}
                      />
                  );
              }
          })}
      </tr>
  )
}

export default ArrivingHeadings;
