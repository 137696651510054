import React from "react";
import Form from "./Form";
import {useWorkLookup} from "../../hooks/useWorkLookup";
import {useSelector} from "react-redux";
import {getDataFresh} from "../../state/selectors";




function WorkLookupForm(props){
    let dataFresh = useSelector(getDataFresh);
    let work_lookup_loaded = useWorkLookup(props.match.params.workLookup, dataFresh);
    return(
        <Form
            endpoint={props.endpoint}
            loaded={work_lookup_loaded}
        />
    )

}
export default WorkLookupForm;