import React from "react";
import {Box, Button, Columns} from "react-bulma-components";
import Collapsible from "react-collapsible";
import useInput from "../../../../hooks/useInput";
import {useDispatch, useSelector} from "react-redux";
import {createSetDataFreshAction} from "../../../../actions/dataActions";
import {getDataFresh} from "../../../../state/selectors";
import axios from "axios";
import {NotificationManager} from "react-notifications";


export default function NewVehicleMake(){
    const dispatch = useDispatch();
    let dataFresh = useSelector(getDataFresh)
    let [name, nameInput, setName] = useInput({
        type: "text",
        label: "Name"
    });


    const clear = () =>{
        setName("");
    }
    const handleSubmit = () => {
        const conf = {
            data: {name: name},
            url: "api/vehiclemake",
            method: "post"
        }
        axios(conf).then((res) => {
            dispatch(createSetDataFreshAction(dataFresh + 1));
            clear();
        }).catch(err => {
            NotificationManager.error("There was an error");
        })
    }

    return (
        <Box className="settings_type">
          <Collapsible
            className="title"
            triggerOpenedClassName="title"
            trigger="Add Vehicle Make"
          >
              <Columns>
                  {nameInput}
              </Columns>
              <Columns>
                <Columns.Column>
                    <Button
                        color={"success"}
                        fullwidth
                        onClick={handleSubmit}
                    >
                        Add +
                    </Button>
                </Columns.Column>
              </Columns>
          </Collapsible>
        </Box>
    )
}