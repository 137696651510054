import {createSetInspectionDataAction} from "../actions/dataActions";
import {useDispatch} from "react-redux";
import {useHttp} from "./useHttp";

export const useVehicleInspectionData = (id, dataFresh) => {
  let nofetch = !id;
  const dispatch = useDispatch();
  const [data, loading, error] = useHttp(
      `vehicleinspections`,
      "get",
      {vehicle: id},
      dataFresh,
      nofetch
  );
  if (error) {
    throw Error("There was an error");
  }
  if (!loading && data !== null) {
    dispatch(createSetInspectionDataAction(data));
  }
  return !loading;
}