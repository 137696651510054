import {useGetAndSet} from "./useGetAndSet";
import {createSetImagesDataAction} from "../actions/dataActions";


export const useImagesData = (params, dataFresh, nofetch) => {
  return useGetAndSet(
    "images",
    params,
    createSetImagesDataAction,
    dataFresh,
    nofetch
  );
};