import React, {Component, useState} from "react";

import axios from "axios";
import {Tabs} from "react-bulma-components";
import DailyTable from "./DailyTable";
import WeeklyTable from "./WeeklyTable";
import {Box} from "react-bulma-components";
import {Button} from "react-bulma-components";
import {Columns} from "react-bulma-components";
import {Form} from "react-bulma-components";
import ContactFilter from "../Invoicing/ContactFilter";
import {
  getFirstDayOfWeek,
  getLastDayOfWeek,
  getQueryString,
  formatDate
} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {getDataFresh, getPartsData, getRentalData} from "../../state/selectors";
import {usePartsData} from "../../hooks/usePartsData";
import {useRentalData} from "../../hooks/useRentalsData";
import useSelect from "../../hooks/useSelect";
import Spinner from "react-spinner-material";
import useWeekInput from "../../hooks/useWeekInput";

export default function View(props) {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [currentSearch, setCurrentSearch] = useState("");
  const [filters, setFilters] = useState({});


  const dispatch = useDispatch();
  const dataFresh = useSelector(getDataFresh);


  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     activeTab: "",
  //     rentals_total_cost: 0,
  //     results: [],
  //     count: 0,
  //     next: "",
  //     previous: "",
  //     current: 1,
  //     search_text: "",
  //     current_search: "",
  //     rental_type: "",
  //     day: formatDate(new Date()),
  //     searching: false,
  //     start_date: getFirstDayOfWeek(new Date()),
  //     finish_date: getLastDayOfWeek(getFirstDayOfWeek(new Date()))
  //   };
  //
  //   this.loadRentals = this.loadRentals.bind(this);
  //   this.updateRentals = this.updateRentals.bind(this);
  // }

  const [view, viewInput] = useSelect({
    label: "View",
    options: [
      {name: "Vehicle", value: "vehicle"},
      {name: "Weekly", value: "weekly"},
    ],
    initialValue: "vehicle",
    callback: () => {
      setPage(1);
    }
  });

  const [day, dayInput] = useWeekInput({
    label: "Day",
    initialValue: new Date(),
    callback: () => {
      setPage(1);
    }
  });

  let params = {page, day}
  const complete = useRentalData(params, dataFresh);
  const data = useSelector(getRentalData);
  // handleFilter = customer => {
  //   this.setState(
  //     {
  //       customer: customer ? customer.id : undefined,
  //       customer_target: customer ? customer.weekly_target : undefined,
  //       customer_name: customer ? customer.display_name : undefined
  //     },
  //     this.view
  //   );
  // };

  // onChange = e => {
  //   this.setState({ [e.target.name]: e.target.value }, () =>
  //     this.loadRentals(this.state.day)
  //   );
  // };

  // onWeekSelect = day => {
  //   let start = getFirstDayOfWeek(day);
  //   let finish_date = getLastDayOfWeek(start);
  //   this.setState(
  //     {
  //       start_date: start,
  //       finish_date: finish_date,
  //       day: formatDate(day)
  //     },
  //     this.loadRentals
  //   );
  // };

  // onDaySelect = date => {
  //   this.setState({ day: formatDate(date) }, this.loadRentals);
  // };

  // handleDateChange = e => {
  //   let date = new Date(e.target.value);
  //   if (this.state.activeView === "weekly")
  //     return this.onWeekSelect(new Date(date));
  //   if (this.state.activeView === "daily")
  //     return this.onDaySelect(new Date(date));
  // };

  // getParams = () => {
  //   let params = {};
  //   if (this.state.rental_type.length)
  //     params.rental_type = this.state.rental_type;
  //
  //   if (this.state.activeView === "weekly") {
  //     params.start_date = formatDate(this.state.start_date);
  //     params.finish_date = formatDate(this.state.finish_date);
  //   } else if (this.state.activeView === "daily") params.day = this.state.day;
  //
  //   if (this.state.customer) params.customer = this.state.customer;
  //
  //   return params;
  // };
  if (!complete) {
    return (
      <div className="spinner-centre">
        <Spinner
          className="spinner-centre"
          radius={120}
          color={"#3273dc"}
          stroke={5}
        />
      </div>
    );
  }
  return (
    <div>
      <Box>
        <Button
          color="success"
          fullwidth
          onClick={() => props.history.push("/editrental")}
        >
          New Rental +
        </Button>
      </Box>
      <Box>
        <Tabs type="toggle" fullwidth align="centered">
          {viewInput}
          {dayInput}
        </Tabs>
        {!!view && (
          <div>
            <Columns>
              <Columns.Column>
                <Columns>
                  {/*<Columns.Column>*/}
                  {/*  <Form.Label>Select a Date</Form.Label>*/}
                  {/*  <Form.Field className="contact-section has-addons">*/}
                  {/*    <Form.Control>*/}
                  {/*      <Form.Input*/}
                  {/*        type="date"*/}
                  {/*        value={this.state.day}*/}
                  {/*        name="day"*/}
                  {/*        onChange={this.handleDateChange}*/}
                  {/*      />*/}
                  {/*    </Form.Control>*/}
                  {/*  </Form.Field>*/}
                  {/*</Columns.Column>*/}
                  <Columns.Column>
                    <Form.Label>Rental Types</Form.Label>
                    {/*<Form.Select*/}
                    {/*  onChange={this.onChange}*/}
                    {/*  name="rental_type"*/}
                    {/*  value={this.state.rental_type}*/}
                    {/*>*/}
                    {/*  <option value="">All</option>*/}
                    {/*  <option value="LT">Long Term</option>*/}
                    {/*  <option value="ST">Short Term</option>*/}
                    {/*</Form.Select>*/}
                  </Columns.Column>
                  {/*<Columns.Column>*/}
                  {/*  <Form.Label>Customer</Form.Label>*/}
                  {/*  <ContactFilter*/}
                  {/*    type="CC"*/}
                  {/*    contactUpdate={this.handleFilter}*/}
                  {/*    endpoint={this.props.endpoint}*/}
                  {/*  />*/}
                  {/*</Columns.Column>*/}
                </Columns>
              </Columns.Column>
            </Columns>
          </div>
        )}
      </Box>
      {view === "weekly" && <WeeklyTable
      rentals={data.results}
      current={page}
      count={data.count}
      startDate={"start_date"}
      onPageChange={setPage}
      formatDate={formatDate}
      // customerTarget={this.state.customer_target}
      // customerName={this.state.customer_name}
    />}
      {view === "vehicle" && <DailyTable
        rentals={data.results}
        total_weekly={data.total_weekly}
        current={page}
        count={data.count}
        onPageChange={setPage}
      />}
    </div>
  );
}

