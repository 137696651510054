import React from "react";
import Figure from "../../Homepage/Figure";
import { Columns } from "react-bulma-components";

function WorkTotals({ labourTotal, partsTotal }) {
  return (
    <Columns>
      <Figure label="Labour" loaded={true} value={labourTotal} />
      <Figure label="Parts" loaded={true} value={partsTotal} />
      <Figure label="Total" loaded={true} value={labourTotal + partsTotal} />
    </Columns>
  );
}

export default WorkTotals;
