import {Form} from "react-bulma-components";
import React from "react";

export function Picker({name, label, value, onChange, options, blank, optionField, disabled, className}){
  return (
      <Form.Field className={className}>
           {label && <Form.Label>{label}</Form.Label>}
            <Form.Control>
                <Form.Select
                    name={name}
                    value={value ? value.id : -1}
                    onChange={onChange}
                    disabled={disabled}
                >
                  {blank && <option value={"BLANK"} key={-1}></option>}
                    {options.results.map(option => (
                        <option key={option.id} value={option.id}>{option[optionField]}</option>
                    ))}
                </Form.Select>
            </Form.Control>
        </Form.Field>
    )
}