import React from "react";

import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";

import { formatDate } from "../../utils";
import { Button } from "react-bulma-components";
import Collapsible from "react-collapsible";
import { Icon } from "react-bulma-components";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function CAPSection(props) {

  return (<Box>
    <Collapsible
      className="title"
      triggerOpenedClassName="title"
      trigger="CAP"
    >
      <div>
        <Columns>
          <Columns.Column>
            <Form.Field>
              <Form.Label>CAP Retail</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="cap_retail"
                  onChange={props.onChange}
                  value={props.data.cap_retail}
                  required
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>CAP Clean</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="cap_clean"
                  onChange={props.onChange}
                  value={props.data.cap_clean}
                  required
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
          <Form.Field>
            <Form.Label>CAP Average</Form.Label>
            <Form.Control>
              <Form.Input
                type="text"
                name="cap_average"
                onChange={props.onChange}
                value={props.data.cap_average}
                required
              />
              <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>CAP Below</Form.Label>
              <Form.Control>
                <Form.Input
                  type="text"
                  name="cap_below"
                  onChange={props.onChange}
                  value={props.data.cap_below}
                  required
                />
                <Icon align={"left"} className={"small-icon"}>
                  <FontAwesomeIcon icon={faPoundSign}/>
                </Icon>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Label>Date Valued</Form.Label>
              <Form.Control>
                <Form.Input
                  type="date"
                  name="cap_date"
                  onChange={props.onChange}
                  value={String(props.data.cap_date)}
                />
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </div>
    </Collapsible>
  </Box>);
}

export default CAPSection;
