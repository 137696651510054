import React from "react";


export default function AccountBalanceRow({contact, index}){

    return(
        <tr
            className={"clickable"}
            onClick={() => window.open(`/editcontact/${contact.id}`, '_self')}
            key={contact.id ?? index}
        >
            <td>{contact ? contact.display_name : ""}</td>
            <td>{contact ? contact.company_phone : ""}</td>
            <td>£{contact ? contact.credit_limit : 0}</td>
            <td>£{contact ? contact.outstanding_balance: 0}</td>
        </tr>
    )

}