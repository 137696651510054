import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getDataFresh} from "../../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import NewCostCentre from "./NewCostCentre";
import CostCentreRow from "./CostCentreRow";
import {getCostCentresData} from "../../../state/selectors";
import {createSetCostCentresDataAction} from "../../../actions/dataActions";
import {useCostCentreData} from "../../../hooks/useCostCentreData";

function CostCentreView(props) {
  const dispatch = useDispatch();


  const onCostCentreUpdate = (index, newCostCentre) => {
    let newState = data;
    newState.results[index] = newCostCentre;
    dispatch(createSetCostCentresDataAction(newState));
  };

  const data = useSelector(getCostCentresData);

  if (!data) {
    return (
      <Box>
         <Spinner
           className="spinner-centre"
           size={120}
           spinnerColor={"#3273dc"}
           spinnerWidth={2}
         />
       </Box>
     );
   }

  return (
    <div>
      <NewCostCentre />
      <Box>
        <Heading size={5}>Cost Centres</Heading>
        <Heading size={6}>
          Note: Modifying these values will update the cost centres of existing
          transactions!
        </Heading>
        <Table>
          <thead>
            <tr>
              <th>Cost Centre</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.results.map((value, index) => (
              <CostCentreRow
                key={value.id}
                costCentreData={value}
                index={index}
                endpoint={props.endpoint} onCostCentreUpdate={onCostCentreUpdate}
              />
            ))}
          </tbody>
        </Table>
      </Box>
    </div>
  );
}

export default CostCentreView;
