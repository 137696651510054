import { useDispatch } from "react-redux";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import React, { useState } from "react";
import { createSetAppDefaultsDataAction } from "../../actions/dataActions";
import axios from "axios";
import useEditableTableNumberCell from "../../hooks/useEditableTableNumberCell";

function Defaults({ endpoint, appDefaultsData }) {
  const [editable, setEditable] = useState(false);
  const dispatch = useDispatch();
  const [appDefaults, setAppDefaults] = useState(appDefaultsData);

  // TODO: Implement not saved warning
  const safeSave = () => {
    const config = {
      method: "put",
      data: appDefaults,
      url: endpoint + "appdefaults"
    };
    axios(config).then(resp => {
      setEditable(false);
      dispatch(createSetAppDefaultsDataAction(resp));
    });
  };

  const depreciationCell = useEditableTableNumberCell({
    propName: "default_depreciation",
    value: appDefaults && appDefaults.default_depreciation,
    onChange: e =>
      setAppDefaults({ ...appDefaults, [e.target.name]: e.target.value }),
    onSave: safeSave,
    editable,
    setEditable,
    inlineEdit: true
  });

  const defaultLabourQuoteRateCell = useEditableTableNumberCell({
    propName: "default_labour_estimate_rate",
    value: appDefaults && appDefaults.default_labour_estimate_rate,
    onChange: e =>
      setAppDefaults({ ...appDefaults, [e.target.name]: e.target.value }),
    onSave: safeSave,
    editable,
    setEditable,
    inlineEdit: true
  });

  const partsMarkupCell = useEditableTableNumberCell({
    propName: "default_parts_markup",
    value: appDefaults && appDefaults.default_parts_markup,
    onChange: e =>
      setAppDefaults({ ...appDefaults, [e.target.name]: e.target.value }),
    onSave: safeSave,
    editable,
    setEditable,
    inlineEdit: true
  });

  return (
    <Box className="settings_type">
      <Heading>Defaults</Heading>
      <Table>
        <thead>
          <tr>
            <th>Setting</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Vehicle Annual Depreciation %</td>
            {depreciationCell}
          </tr>
          <tr>
            <td>Default Labour Quote Rate £/hr</td>
            {defaultLabourQuoteRateCell}
          </tr>
          <tr>
            <td>Default Parts Markup %</td>
            {partsMarkupCell}
          </tr>
        </tbody>
      </Table>
    </Box>
  );
}

export default Defaults;
