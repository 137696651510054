import React from "react";
import {readableDate} from "../../../../utils";

function ForSaleRow({value, finance, activeTab}) {
  return (
    <tr
      className="clickable"
      onClick={() => window.open("/editvehicle/" + value.id, "_self")}
      key={value.id}
    >
      <td data-testid={"vehicle-registration"}>
    <span className="registration-field">
    {value.registration}
    </span>
      </td>
      {activeTab === 'fleet' &&
      <td data-testid={"vehicle-fleetnum"}>{value.fleet_number}</td>
      }
      {activeTab === 'customer' &&

      <td data-testid={"vehicle-owner"}>{value.owner ? value.owner.display_name : "None Set"}</td>
      }
      <td data-testid={"vehicle-make"}>{value.make ? value.make.name : ""}</td>
      <td data-testid={"vehicle-model"}>{value.model ? value.model.name : ""}</td>
      <td data-testid={"vehicle-variant"}>{value.variant}</td>
      <td data-testid={"vehicle-vin"}>{value.vin_number}</td>
      <td data-testid={"vehicle-type"}>{value.type ? value.type.name : ""}</td>
      <td data-testid={"vehicle-salecomments"}>{finance.sale_comments}</td>
      <td data-testid={"vehicle-soldto"}>{finance.sold_to}</td>
      <td data-testid={"vehicle-solddate"}>{readableDate(finance.sold_date)}</td>
      <td data-testid={"vehicle-soldprice"}>£{finance.sold_price ? parseFloat(finance.sold_price).toFixed(2) : 0.00}</td>
       <td data-testid={"vehicle-remainingbal"}>
        £
        {!finance.finance_early_settlement_date && finance.remaining_balance
          ? parseFloat(finance.remaining_balance - finance.hp_remaining_interest).toFixed(2)
          : 0.00
        }
      </td>
      <td data-testid={"vehicle-estimatedvalue"}>
        £
        {typeof finance.estimated_value === "string"
          ? finance.estimated_value
          : (finance.estimated_value || 0).toFixed(2)}
      </td>
      <td data-testid={"vehicle-listprice"}>£{finance.external_list_price ? parseFloat(finance.external_list_price).toFixed(2)
        : 0.00}</td>
    </tr>
  )
}

export default ForSaleRow;
