import { Form } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoundSign} from "@fortawesome/free-solid-svg-icons";

function useEditableTableCostCell({
  propName,
  value,
  onChange,
  onSave,
  editable,
  setEditable,
  disabled,
  inlineEdit
}) {
  const input = (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Input
            onChange={onChange}
            className="small-row-black"
            type={editable ? "number" : "text"}
            name={propName}
            value={
              editable
                ? value
                : (parseFloat(value) || 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
            }
            disabled={!editable}
          />
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable, disabled})}
      </Form.Field>
    </td>
  );
  return input;
}

export default useEditableTableCostCell;
