import React from "react";
import {Form} from "react-bulma-components";

export default function KeyFilterDropDown({onChange, selectedFilter}){


    return (
        <div>
            <Form.Label>Key Filter</Form.Label>
            <Form.Select
                name={"key-filter"}
                onChange={onChange}
                value={selectedFilter}
            >
                <option key={"ALL"} value={"ALL"}>ALL</option>
                <option key={"NO KEY"} value={"BLANK"}>NONE</option>
                <option key={"WORKSHOP"} value={"WORKSHOP"}>WORKSHOP</option>
                <option key={"NOT WORKSHOP"} value={"NOT WORKSHOP"}>NOT WORKSHOP</option>
                <option key={"EXTERNAL"} value={"EXTERNAL"}>EXTERNAL</option>
            </Form.Select>
        </div>
    )






}