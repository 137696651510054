import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import useInput from "../../hooks/useInput";

export default function MultiplePartStockEditModal({
  open,
  setOpen,
  selected,
  saveSelected
}) {
  const [partName, partNameInput, setPartName] = useInput({
    type: "text",
    label: "Part Name"
  });
  const [partCost, partCostInput, setPartCost] = useInput({
    type: "number",
    label: "Part Cost",
    currency: true
  });
  const [partNumber, partNumberInput, setPartNumber] = useInput({
    type: "text",
    label: "Part Number"
  });
  const [stockVolume, stockVolumeInput, setStockVolume] = useInput({
    type: "number",
    label: "Stock Volume",
  });

  const onSave = () => {
    saveSelected(partName, partCost, partNumber, stockVolume).then(onClose);
  };

  const onClose = () => {
    setOpen(false);
    setPartName("");
    setPartCost("");
    setPartNumber("");
    setStockVolume("");
  };

  return (
    <Modal closeOnBlur show={open} onClose={onClose}>
      <Modal.Content>
        <Section style={{ backgroundColor: "white" }}>
          <Columns>
            {partNameInput}
            {partCostInput}
            {partNumberInput}
            {stockVolumeInput}
          </Columns>
          <Form.Field>
            <p>You are editing {Object.keys(selected).length} parts</p>
          </Form.Field>
          <Button color="success" onClick={onSave}>
            Save
          </Button>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
