import React from "react";
import {Box, Columns, Form, Heading} from "react-bulma-components";



export default function DefectJobSection({job, onChange, disabled}){




    return (
        <div>
            <Box>
              <Heading>Defect Information</Heading>
              <Columns>
                <Columns.Column>
                  <Form.Label>Status of Vehicle</Form.Label>
                  <Form.Select
                    value={job.defect_status ? job.defect_status : 0}
                    disabled={disabled}
                    onChange={onChange}
                    name={"defect_status"}
                  >
                    <option value={"DRIVEABLE"} key={"DRIVEABLE"}>DRIVEABLE</option>
                    <option value={"NOT_DRIVEABLE"} key={"NOT_DRIVEABLE"}>NOT DRIVEABLE</option>
                  </Form.Select>
                </Columns.Column>
                <Columns.Column>
                  <Form.Label>Vehicle Location</Form.Label>
                  <Form.Input
                    value={job.location_of_van}
                    disabled={disabled}
                    onChange={onChange}
                    name={"location_of_van"}
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <Form.Label>Driver Name</Form.Label>
                  <Form.Input
                    value={job.defect_driver_name}
                    disabled={disabled}
                    onChange={onChange}
                    name={"defect_driver_name"}
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.Label>Driver Phone Number</Form.Label>
                  <Form.Input
                    value={job.defect_driver_number}
                    disabled={disabled}
                    onChange={onChange}
                    name={"defect_driver_number"}
                  />
                </Columns.Column>
                <Columns.Column>
                  <Form.Label>Defect Creator</Form.Label>
                  <Form.Input
                    disabled={true}
                    value={job.defect_creator ? job.defect_creator.first_name + " " + job.defect_creator.last_name : ""}
                    name={"defect_creator"}
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column>
                  <Form.Label>Defect Description</Form.Label>
                  <Form.Textarea
                    value={job.defect_description}
                    disabled={disabled}
                    onChange={onChange}
                    name={"defect_description"}
                  />
                </Columns.Column>
              </Columns>
            </Box>
        </div>
    )
}