import React from "react";
import { formatDate, sortRentals, readableDate } from "../../utils";
import { useRentalData } from "../../hooks/useRentalsData";
import { useSelector } from "react-redux";
import { getRentalData } from "../../state/selectors";
import { Columns } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Tile } from "react-bulma-components";
import { Link } from "react-router-dom";

const DATES = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

function Rentals(props) {
  const complete = useRentalData({
    start_date: props.dates.start,
    finish_date: props.dates.end
  });
  const data = useSelector(getRentalData);
  if (!complete) {
    return <p>Not Ready</p>;
  }

  const rentals = sortRentals(data.results, props.dates.start);
  return (
    <Box>
      <Heading>Rentals</Heading>
      <Columns>
        {DATES.map((day, index) => {
          let monday = new Date(props.dates.start);
          monday.setDate(monday.getDate() + index);

          return (
            <Columns.Column className="rental-column" key={day}>
              <Heading size={5}>{day}</Heading>
              <p className="is-size-7">{readableDate(formatDate(monday))}</p>
              <Heading size={6}>
                £{rentals.day_totals[index].toFixed(2)}
              </Heading>
              <Tile kind="parent" vertical style={{ padding: "0px" }}>
                {rentals.day_rentals[index].map(rental => (
                  <Tile
                    renderAs="article"
                    kind="child"
                    className="rental-homepage-tile"
                    key={rental.id}
                  >
                    <Link target="_self" to={"/editrental/" + rental.id}>
                      <p className="no-underline is-size-7">
                        {rental.customer.display_name}
                      </p>
                      <p
                        size={6}
                        className="no-underline registration-field registration-homepage"
                      >
                        {rental.vehicle.registration}
                      </p>
                    </Link>
                  </Tile>
                ))}
              </Tile>
            </Columns.Column>
          );
        })}
      </Columns>
    </Box>
  );
}

export default Rentals;
