import React, {useState} from "react";
import {Box, Columns, Heading, Pagination, Table} from "react-bulma-components";
import Collapsible from "react-collapsible";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import AccountBalanceRow from "./AccountBalanceRow";
import {useContactsData} from "../../hooks/useContactsData";
import {useSelector} from "react-redux";
import {getContactsData, getDataFresh} from "../../state/selectors";
import Spinner from "react-spinner-material";



export default function ContactsAccountBalance(props){
    const dataFresh = useSelector(getDataFresh)
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({});
    const headings = [
        {
            label: "Name",
            field: "display_name",
            labelField: "display_name",
            blank: false
        },
        {
            label: "Phone Number",
            field: "company_phone",
            labelField: "company_phone",
            blank: false
        },
        {
            label: "Credit Limit",
        },
        {
            label: "Account Balance",
        },
    ];
    let params ={page:page, contact_type:'CC'}

    for (let filter in filters) {
      if (filters[filter][0] && filters[filter][0][filter]) {
        params[filter] = filters[filter][0][filter];
      }
    }

    let loaded = useContactsData(params)
    const contacts = useSelector(getContactsData)

    if(!loaded){
        return(
            <Box>
                <Spinner
                  className="spinner-centre"
                  size={120}
                  spinnerColor={"#3273dc"}
                  spinnerWidth={2}
                />
            </Box>
        );
    }


    return(
        <Box>
            <Heading>Customer Account Balance</Heading>
            <Columns>
                <Columns.Column>
                    <Table
                        size={"fullwidth"}
                        striped={false}
                        className={"is-hoverable"}
                    >
                        <thead>
                            <tr>
                              {headings.map(heading => {
                                if (!heading.field) {
                                  return <th key={heading.label}>{heading.label}</th>;
                                }
                                if (heading.blank) {
                                  return (
                                    <FilterBlankHeading
                                      key={heading.label}
                                      heading={heading}
                                      setFilters={f => {
                                        setPage(1);
                                        setFilters(f);
                                      }}
                                      filters={filters}
                                    />
                                  );
                                }
                                return (
                                  <FilterHeading
                                    key={heading.label}
                                    heading={heading}
                                    setFilters={f => {
                                      setPage(1);
                                      setFilters(f);
                                    }}
                                    filters={filters}
                                    options={contacts.filter_list[heading.field]}
                                  />
                                );
                              })}
                            </tr>
                        </thead>
                        <tbody>
                            {contacts.results.map((contact, index) => (
                                <AccountBalanceRow
                                    contact={contact}
                                    index={index}
                                />
                            ))
                            }
                        </tbody>
                    </Table>
                </Columns.Column>
            </Columns>
            <Pagination
                showFirstLast={true}
                onChange={setPage}
                current={page}
                total={Math.ceil(contacts.count / 25)}
            />
        </Box>
    )

}