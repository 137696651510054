import React from "react";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Heading } from "react-bulma-components";


export default function StatusChangeModal({
    onApprove,
    setOpen,
    open,
    new_status
}) {
    const onClick = () => {
        onApprove(new_status);
        setOpen(false);
    };

    return (
        <Modal
            closeOnBlur
            show={open}
            onClose={() => {
                setOpen(false);
            }}
        >
            <Modal.Content>
                <Section style={{ backgroundColor: "white" }}>
                    <Heading className="is-centered">
                        Make sure if any preparation needs to be done, the right job card has been raised.
                    </Heading>
                    <Columns>
                        <Columns.Column>
                            <Button color="info" fullwidth onClick={onClick}>
                                Change Status
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )
}