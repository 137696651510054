import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetVehicleTypesDataAction } from "../../../actions/dataActions";
import { getDataFresh, getVehicleTypesData } from "../../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { useVehicleTypeData } from "../../../hooks/useVehicleTypeData";
import NewVehicleType from "./NewVehicleType";
import VehicleTypeRow from "./VehicleTypeRow";
import Collapsible from "react-collapsible";

function VehicleTypeView(props) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);

  const complete = useVehicleTypeData({ page }, dataFresh);

  const onVehicleTypeUpdate = (index, newVehicleType) => {
    let newState = data;
    newState.results[index] = newVehicleType;
    dispatch(createSetVehicleTypesDataAction(newState));
  };

  const data = useSelector(getVehicleTypesData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <NewVehicleType />
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Vehicle Types"
        >
            <Heading size={6}>
              Note: Modifying these values will update the vehicle types of existing
              vehicles!
            </Heading>
            <Table>
              <thead>
                <tr>
                  <th>Vehicle Type</th>
                  <th>Special Labour Rate</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.results.map((value, index) => (
                  <VehicleTypeRow
                    key={value.id}
                    vehicleTypeData={value}
                    index={index}
                    endpoint={props.endpoint}
                    onVehicleTypeUpdate={onVehicleTypeUpdate}
                  />
                ))}
              </tbody>
            </Table>
            <Pagination
              showFirstLast={true}
              onChange={page => setPage(page)}
              current={page}
              total={Math.ceil(data.count / 25)}
            >
              {" "}
            </Pagination>
          </Collapsible>
      </Box>
    </div>
  );
}

export default VehicleTypeView;
