import React, { useState } from "react";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createSetDataFreshAction } from "../../../../actions/dataActions";
import { getDataFresh } from "../../../../state/selectors";
import { Icon } from "react-bulma-components";

function TransactionReportingCategoryRow({
  transactionReportingCategoryData,
  index,
  onTransactionReportingCategoryUpdate,
  endpoint
}) {
  const dispatch = useDispatch();
  const [transactionReportingCategory, setTransactionReportingCategory] = useState(transactionReportingCategoryData);
  const [editable, setEditable] = useState(false);
  let dataFresh = useSelector(getDataFresh);

  const submitUpdate = e => {
    e.stopPropagation();
    const url = endpoint + "transactionreportingcategory/" + transactionReportingCategory.id;
    const conf = {
      method: "put",
      data: transactionReportingCategory,
      url
    };

    axios(conf).then(() => {
      setEditable(false);
      dispatch(createSetDataFreshAction(dataFresh + 1));
    });
  };

  const onChange = e => {
    let newTransactionReportingCategory = { ...transactionReportingCategory };
    newTransactionReportingCategory[e.target.name] = e.target.value || null;
    setTransactionReportingCategory(newTransactionReportingCategory);
    onTransactionReportingCategoryUpdate(index, newTransactionReportingCategory);
  };

  const onEdit = e => {
    e.stopPropagation();
    setEditable(!editable);
  };

  const onDelete = e => {
    const url = endpoint + "transactionreportingcategory/" + transactionReportingCategory.id;
    const conf = { method: "delete", url };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };


  return (
    <tr key={transactionReportingCategoryData.id}>
      <td>
        <Form.Field onClick={e => e.stopPropagation()} className="has-addons">
          <Form.Control>
            <Form.Input
              onChange={onChange}
              category="text"
              name="name"
              value={transactionReportingCategory.name}
              disabled={!editable}
            />
          </Form.Control>
          {editable ? (
            <Form.Control>
              <Button color="success" onClick={submitUpdate}>
                Save
              </Button>
            </Form.Control>
          ) : (
            <Form.Control>
              <Button onClick={onEdit}>Edit</Button>
            </Form.Control>
          )}
        </Form.Field>
      </td>
      <td className={"is-narrow"}>
        <Button color={"danger"} onClick={onDelete}>
          Delete X
        </Button>
      </td>
    </tr>
  );
}

export default TransactionReportingCategoryRow;
