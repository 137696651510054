import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import React from "react";
import FAIcon from "../components/Icon/FAIcon";
import useInlineEditButton from "./useInlineEditButton";

function useEditableTableDropdownCell({
  propName,
  value,
  options,
  onChange,
  onSave,
  editable,
  setEditable,
  inlineEdit
}) {
  if (value && !value.id) {
    value = {id: value}
  }
  return (
    <td onClick={e => e.stopPropagation()}>
      <Form.Field className="has-addons">
        <Form.Control>
          <Form.Select
            onChange={onChange}
            name={propName}
            value={value ? value.id : options[0].id}
            disabled={!editable}
          >
            {options.map(value =>
              <option key={value.id} value={value.id}>
                {value.name}
              </option>
            )}
          </Form.Select>
        </Form.Control>
        {useInlineEditButton({show: inlineEdit, onSave, setEditable, editable})}
      </Form.Field>
    </td>
  );
}

export default useEditableTableDropdownCell;
