import React from "react";
import {Box, Columns, Form} from "react-bulma-components";
import Collapsible from "react-collapsible";


export default function PartEnquiryJobSection({job, disabled, onChange}){


    return (
        <div>
            <Box>
                <Collapsible
                    className="title"
                    triggerOpenedClassName="title"
                    trigger="Part Enquiry Details"
                >
                    <Columns>
                        <Columns.Column>
                            <Form.Label>Registration</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type={"text"}
                                    name={"enquiry_registration"}
                                    value={job ? job.enquiry_registration : ""}
                                    disabled={disabled}
                                    onInput={onChange}
                                />
                            </Form.Control>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Label>Make</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type={"text"}
                                    name={"enquiry_make"}
                                    value={job ? job.enquiry_make : ""}
                                    disabled={disabled}
                                    onInput={onChange}
                                />
                            </Form.Control>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Label>Model</Form.Label>
                            <Form.Control>
                                <Form.Input
                                    type={"text"}
                                    name={"enquiry_model"}
                                    value={job ? job.enquiry_model : ""}
                                    disabled={disabled}
                                    onInput={onChange}
                                />
                            </Form.Control>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Form.Label>Description</Form.Label>
                            <Form.Control>
                                <Form.Textarea
                                    name={"enquiry_description"}
                                    value={job? job.enquiry_description : ""}
                                    disabled={disabled}
                                    onInput={onChange}
                                />
                            </Form.Control>
                        </Columns.Column>
                    </Columns>
                </Collapsible>
            </Box>
        </div>
    )

}