import React, { useState } from "react";
import { Modal } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Section } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";

import { Heading } from "react-bulma-components";
import { Icon } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { asyncContainer, Typeahead } from "react-bootstrap-typeahead";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import { useDispatch, useSelector } from "react-redux";
import { getDataFresh } from "../../state/selectors";
import PaymentRow from "./PaymentRow";
import { NotificationManager } from "react-notifications";
import * as shortid from "shortid";

const AsyncTypeahead = asyncContainer(Typeahead);

export default function ApproveModal({
  open,
  setOpen,
  endpoint,
  customer,
  invoiceNumber,
  costCentres,
  costCentreObjects
}) {
  const dispatch = useDispatch();
  let dataFresh = useSelector(getDataFresh);
  const [, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [typeahead, setTypeahead] = useState();
  let [payments, setPayments] = useState([]);

  const onClickAction = () => {
    if (checkAllFieldsFilled()) {
      save();
      setPayments([]);
      setOpen(false);
    } else {
      NotificationManager.error("Please fill in all fields", "Error!", 10000);
    }
  };

  const handleChange = (e, index) => {
    let newPayments = [...payments];
    newPayments[index][e.target.name] = e.target.value;
    setPayments(newPayments);
  };

  const handleDelete = index => {
    const newPayments = [...payments];
    newPayments.splice(index, 1);
    setPayments(newPayments);
  };

  const checkAllFieldsFilled = () => {
    for (let i = 0; i < payments.length; i++) {
      let payment = payments[i];
      if (
        !payment.contact ||
        !payment.invoice_number ||
        !payment.payment_date ||
        !payment.amount
      ) {
        return false;
      }
    }
    return true;
  };

  const save = () => {
    const url = endpoint + "payments";
    Promise.all(
      payments.map(payment => {
        const data = payment;
        const conf = {
          method: "post",
          data,
          url
        };

        return axios(conf).then(() => {
          dispatch(createSetDataFreshAction(dataFresh + 1));
          setOpen(false);
        });
      })
    ).then(() => {
      return true;
    });
  };

  const addPaymentRow = () => {
    let newPayments = payments;
    let newPayment = { payment_type: "BACS", key: shortid.generate() };
    newPayment.invoice_number = invoiceNumber ? invoiceNumber : undefined;
    newPayment.contact = customer ? customer : undefined;
    newPayments.push(newPayment);
    setPayments([...newPayments]);
  };

  return (
    <Modal
      closeOnBlur
      show={open}
      onClose={() => {
        setPayments([]);
        setOpen(false);
      }}
    >
      <Modal.Content className="wider-modal">
        <Section style={{ backgroundColor: "white" }}>
          <Heading className="is-centered">
            Items approved. Would you like to add Payments for these items?
          </Heading>
          <Table>
            <thead>
              <tr>
                <th>Customer</th>
                <th>Invoice Number</th>
                <th>Payment Amount</th>
                <th>Payment Type</th>
                <th>Cost Centre</th>
                <th>Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((payment, index) => (
                <PaymentRow
                  endpoint={endpoint}
                  payment={payment}
                  index={index}
                  costCentres={costCentres}
                  costCentreObjects={costCentreObjects}
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                />
              ))}
            </tbody>
          </Table>
          <Columns>
            <Columns.Column>
              <Button color="primary" onClick={addPaymentRow}>
                Add Payment
              </Button>
            </Columns.Column>
          </Columns>
          <Columns>
            <Columns.Column>
              <Button
                color="info"
                fullwidth
                onClick={() => {
                  setPayments([]);
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Columns.Column>
            <Columns.Column>
              <Button color="danger" fullwidth onClick={onClickAction}>
                Save Payments
              </Button>
            </Columns.Column>
          </Columns>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
