import {useGetAndSet} from "./useGetAndSet";
import {createSetVehicleTrackerTypeDataAction} from "../actions/dataActions";

export const useVehicleTrackerTypeData = (params, dataFresh, nofetch) => {
    return useGetAndSet(
        "vehicletrackertype",
        params,
        createSetVehicleTrackerTypeDataAction,
        dataFresh,
        nofetch
    )
}