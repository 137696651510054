import React from "react";



export default function PrintJobRow({job, index}){
    return (
        <tr>
            <td data-testID={"job-id"+index}>{job.id}</td>
            <td data-testID={"vehicle-reg"+index}>{job.vehicle ? job.vehicle.registration : ""}</td>
            <td data-testID={"vehicle-make"+index}>{job.vehicle ? job.vehicle.make ? job.vehicle.make.name : "" : ""}</td>
            <td data-testID={"vehicle-model"+index}>{job.vehicle ? job.vehicle.model ? job.vehicle.model.name : "" : ""}</td>
            <td data-testID={"customer-display"+index}>{job.customer ? job.customer.display_name : ""}</td>
            <td data-testID={"job-description"+index}>{job.job_description}</td>
            <td data-testID={"job-fitter"+index}>{job.fitter ? job.fitter.name : ""}</td>
            <td></td>
        </tr>
    )


}