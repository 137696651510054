import React, { useState } from "react";
import { usePartsData } from "../../hooks/usePartsData";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Table } from "react-bulma-components";
import {useDispatch, useSelector} from "react-redux";
import {
  getDataFresh,
  getPartsData,
  getVehicleMakeData,
  getVehicleModelData,
  getVehicleTypesData
} from "../../state/selectors";
import { Pagination } from "react-bulma-components";
import PartHistoryRow from "./PartHistoryRow";
import useSelect from "../../hooks/useSelect";
import {createSetPartsDataAction, createSetWorkDataAction} from "../../actions/dataActions";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import FilterHeading from "../Invoicing/FilterHeading";
import {useVehicleMakeData} from "../../hooks/useVehicleMakeData";
import {useVehicleModelData} from "../../hooks/useVehicleModelData";

function PartHistory(props) {
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);
  const dispatch = useDispatch()

  const vehicle_types_loaded = useVehicleTypeData({});
  const makes_loaded = useVehicleMakeData({}, dataFresh);
  const models_loaded = useVehicleModelData({}, dataFresh);
  const vehicle_types = useSelector(getVehicleTypesData);
  const makes = useSelector(getVehicleMakeData);
  const models = useSelector(getVehicleModelData);

  const headings = [
    {
      label: "Registration",
      field: "job__vehicle",
      labelField: "job__vehicle__registration"
    },
    {
      label: "Make",
    },
    {
      label: "Model",
    },
    {
      label: "Type",
    },
    {
      label: "Part Name",
      blank: true,
      field: "name"
    },
    {
      label: "Part Number",
      blank: true,
      field: "part_number"
    },
    {
      label: "Cost",
      blank: true,
      field: "cost"
    },
    {
      label: "Date Ordered",
      field: "date_ordered",
      labelField: "date_ordered",
      date: true
    },
    {
      label: "Supplier",
      field: "supplier",
      labelField: "supplier__display_name"
    },
    {
      label: "Stock Volume",
    },
    {
      label: "Comments"
    }
  ];

  const [viewParam, viewInput] = useSelect({
    label: "View",
    options: [
      { name: "All", value: "all" },
      { name: "Hidden", value: "hidden" },
      { name: "Stock", value: 'lookup_stock'}
    ],
    initialValue: "all",
    testId: "part-view",
    callback: () => {
      setPage(1);
    }
  });

  let lookupHidden, partStockLookup;

  if (viewParam === "all") {
    lookupHidden = false;
  } else if (viewParam === 'hidden') {
    lookupHidden = true;
  } else if (viewParam === 'lookup_stock') {
    partStockLookup = true;
  }

  let params = {
    page,
    search,
    lookup_hidden: lookupHidden,
    part_lookup_stock: partStockLookup,
    view: "with_number"
  };

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const complete = usePartsData(params, dataFresh);
  const data = useSelector(getPartsData);

  const updateValue = (index, newPart) => {
    let currentData = { ...data };
    currentData.results[index] = newPart;
    dispatch(createSetPartsDataAction(currentData));
  };

  if (!complete || !vehicle_types_loaded || !models_loaded || !makes_loaded) return <div>Loading</div>;

  document.title = "Part History";

  return (
    <div>
      <Box>
        <Columns>
          {viewInput}
          <Columns.Column>
            <Form.Label>Part Search</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => {
                    setSearchText(e.target.value);
                  }}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && setSearch(searchText)}
                  data-testid="part-search"
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                />
              </Form.Control>
              <Form.Control>
                <Button
                  data-testid="search-button"
                  color="success"
                  onClick={() => {
                    setSearch(searchText);
                  }}
                >
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button
                  color="warning"
                  onClick={() => {
                    setSearch("");
                    setSearchText("");
                  }}
                >
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Table>
          <thead>
            <tr>
              {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data.results.map((part, index) => (
              <PartHistoryRow
                key={part.id}
                part={part}
                endpoint={props.endpoint}
                updateValue={newPart => updateValue(index, newPart)}
                vehicleTypes={vehicle_types.results}
                makes={makes}
                models={models}
              />
            ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default PartHistory;
