import React, {useState} from "react";
import {Modal, Form, Section, Columns, Button, Heading} from "react-bulma-components";
import {asyncContainer, Typeahead} from "react-bootstrap-typeahead";
import axios from "axios";
import {useDispatch} from "react-redux";
import {createSetJobFieldAction} from "../../../actions/dataActions";
import NotificationManager from "react-notifications/lib/NotificationManager";


const AsyncTypeahead = asyncContainer(Typeahead);
export default function ToQuoteModal({open, setOpen, job, endpoint, history}){

    const [isLoading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [contactSearchData, setContactSearchData] = useState([]);
    const [typeahead, setTypeahead] = useState();
    const dispatch = useDispatch();
    const handleVehicleSearch = query => {
        setLoading(true);
        let url = endpoint + `vehicles?search=${query}`;
        axios.get(url).then(resp => {
        setSearchData(resp.data.results);
        setLoading(false);
        });
    };

    const handleContactSearch = query => {
        setLoading(true);
        let url = endpoint + `contacts?search=${query}&type=CC`
        axios.get(url).then(res => {
            setContactSearchData(res.data.results);
            setLoading(false);
        })
    }
    const triggerChange = data => {
        dispatch(createSetJobFieldAction(data));
    }
    const handleVehicleSelect = e => {
        triggerChange({vehicle: e[0]});
        setSearchData([]);
    }
    const handleContact1Select = e => {
        triggerChange({"customer": e[0]});
        setContactSearchData([]);
    }
    const handleContact2Select = e => {
        triggerChange({"customer2": e[0]});
        setContactSearchData([]);
    }

    const validator = (data) => {
        if(data.vehicle && data.customer){
            return true
        }
        else{
            NotificationManager.warning("Vehicle and Customer 1 are required");
            return false
        }
    }
    const enquiryToQuote = () => {
        if(validator(job)) {
            const conf = {
                data: job,
                method: "post",
                url: endpoint + "enquiryquote"
            }
            axios(conf).then((res) => {
                if (res.data && res.data.id) {
                    history.push("/editquote/" + res.data.id);
                }
            }).catch(err => {
                NotificationManager.error("An error occured!");
            })
        }
    }



    return (
        <Modal
            show={open}
            onClose={() =>
                setOpen(false)
            }
            closeOnBlur
        >
            <Modal.Content>
                <Section style={{background:"white"}}>
                    <Heading>Enquiry To Quote</Heading>
                    <Columns>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Vehicle</Form.Label>
                                <Form.Control>
                                    <AsyncTypeahead
                                      useCache={false}
                                      data-testid="vehicle-search"
                                      id="typeahead"
                                      labelKey="registration"
                                      minLength={2}
                                      name={"registration"}
                                      onSearch={handleVehicleSearch}
                                      onChange={handleVehicleSelect}
                                      placeholder={"Search Vehicle Registration"}
                                      options={searchData}
                                      ref={typeahead => setTypeahead(typeahead)}
                                      className="typeahead"
                                      isLoading={isLoading}
                                    />
                                </Form.Control>
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Registration</Form.Label>
                                <Form.Input
                                    type={"text"}
                                    disabled={true}
                                    readOnly={true}
                                    value={job.vehicle? job.vehicle.registration : ""}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Customer 1</Form.Label>
                                <Form.Control>
                                    <AsyncTypeahead
                                      useCache={false}
                                      data-testid="customer1-search"
                                      id="typeahead"
                                      labelKey="display_name"
                                      minLength={2}
                                      name={"customer"}
                                      onSearch={handleContactSearch}
                                      onChange={handleContact1Select}
                                      placeholder={"Search Customer"}
                                      options={contactSearchData}
                                      ref={typeahead => setTypeahead(typeahead)}
                                      className="typeahead"
                                      isLoading={isLoading}
                                    />
                                </Form.Control>
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Customer 1 Name</Form.Label>
                                <Form.Input
                                    disabled={true}
                                    readOnly={true}
                                    value={job.customer ? job.customer.display_name : ""}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Customer 2</Form.Label>
                                <Form.Control>
                                    <AsyncTypeahead
                                      useCache={false}
                                      data-testid="customer2-search"
                                      id="typeahead"
                                      labelKey="display_name"
                                      minLength={2}
                                      name={"customer2"}
                                      onSearch={handleContactSearch}
                                      onChange={handleContact2Select}
                                      placeholder={"Search Customer"}
                                      options={contactSearchData}
                                      ref={typeahead => setTypeahead(typeahead)}
                                      className="typeahead"
                                      isLoading={isLoading}
                                    />
                                </Form.Control>
                            </Form.Field>
                        </Columns.Column>
                        <Columns.Column>
                            <Form.Field>
                                <Form.Label>Customer 2 Name</Form.Label>
                                <Form.Input
                                    disabled={true}
                                    readOnly={true}
                                    value={job.customer2 ? job.customer2.display_name : ""}
                                />
                            </Form.Field>
                        </Columns.Column>
                    </Columns>
                    <Columns>
                        <Columns.Column>
                            <Button
                                color={"success"}
                                onClick={enquiryToQuote}
                                fullwidth={true}
                            >
                                Turn Into Quote
                            </Button>
                        </Columns.Column>
                    </Columns>
                </Section>
            </Modal.Content>
        </Modal>
    )






}