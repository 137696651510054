import React from "react";
import { Box } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import useInput from "../../hooks/useInput";
import usePostDiaryEntry from "../../hooks/usePostDiaryEntry";
import { Button } from "react-bulma-components";
import Collapsible from "react-collapsible";

function NewDiaryEntry() {
  const [taskFor, taskForInput, setTaskFor] = useInput({
    type: "text",
    label: "Task For"
  });
  const [comments, commentsInput, setComments] = useInput({
    type: "text",
    label: "Comments",
    size: "two-fifths"
  });

  const [startDate, stateDateInput, setStartDate] = useInput({
    type: "date",
    label: "Start Date",
    initialValue: null
  });

  const [time, timeInput, setTime] = useInput({
    type: "time",
    label: "Time",
    initialValue: null
  });

  const [endDate, endDateInput, setEndDate] = useInput({
    type: "date",
    label: "End Date (Leave blank for 1 day entry)",
    initialValue: null
  });

  const [, postDiaryEntry] = usePostDiaryEntry({
    task_for: taskFor,
    time: time || null,
    start_date: startDate || null,
    end_date: endDate || null,
    comments
  });

  const handleSubmit = () => {
    postDiaryEntry();
    setTaskFor("");
    setComments("");
    setStartDate("");
    setTime("");
    setEndDate("");
  };

  return (
    <Box>
      <Collapsible
        className="title"
        triggerOpenedClassName="title"
        trigger="Add Diary Entry"
      >
        <Columns>
          {taskForInput}
          {commentsInput}
          {stateDateInput}
          {timeInput}
          {endDateInput}
        </Columns>

        <Columns>
          <Columns.Column>
            <Button onClick={handleSubmit} fullwidth color={"success"}>
              Add
            </Button>
          </Columns.Column>
        </Columns>
      </Collapsible>
    </Box>
  );
}

export default NewDiaryEntry;
