import React, { useState } from "react";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import {useDispatch} from "react-redux";
import {createSetDataFreshAction} from "../../actions/dataActions";



function Instruction({
  instruction,
  index,
  updateInstructions,
  removeInstruction,
  type
}) {

  const handleChange = e => {
    const newInstruction = getNewInstruction(e);
    updateInstructions(newInstruction, index);
  };


  const getNewInstruction = e => {
    let newInstruction = { ...instruction, [e.target.name]: e.target.value };
    return newInstruction;
  };
  const onCheck = () => {
    handleChange({target: {name: 'on_completion', value: !instruction.on_completion}});
    console.log(instruction.on_completion);
  }

  return (
    <tr>
      <td className={"comments-wrapper"}>
        <Form.Field className="has-addons">
          <Form.Control className="comments-wrapper">
            <Form.Input
              type="text"
              value={instruction.text}
              name="text"
              onChange={handleChange}
              data-testid="part-name"
              className={"comments-box"}
            />
          </Form.Control>
        </Form.Field>
      </td>
      {type === 'Workshop' &&
        <td>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                type="checkbox"
                name="on_completion"
                onChange={(e) => onCheck(e)}
                checked={instruction.on_completion}
              />
            </Form.Control>
          </Form.Field>
        </td>
      }
      <td className="instructions-remove-button">
        <Button
          remove
          onClick={() => removeInstruction(index)}
          data-testid="remove-part"
        >
          Remove
        </Button>
      </td>
    </tr>
  );
}
export default Instruction;
