import {useGetAndSet} from "./useGetAndSet";
import {createSetVehicleTrackerFigureDataAction} from "../actions/dataActions";


export const useVehicleTrackerFigureData = (params, dataFresh, nofetch) => {
    return useGetAndSet(
        "vehicletrackerfigure",
        params,
        createSetVehicleTrackerFigureDataAction,
        dataFresh,
        nofetch
    )
}