import {
  createSetWorkFiguresDataAction,
  createSetPartsFiguresDataAction,
  createSetTransactionFiguresDataAction,
  createSetRentalFiguresDataAction,
  createSetVehicleFiguresDataAction,
  createSetTargetFiguresDataAction
} from "../actions/dataActions";
import { useGetAndSet } from "./useGetAndSet";

export const useFiguresData = (params) => {
  const partsLoading = useGetAndSet(
    "parts/figures",
    params,
    createSetPartsFiguresDataAction
  );
  const workLoading = useGetAndSet(
    "work/figures",
    params,
    createSetWorkFiguresDataAction
  );
  const transactionsLoading = useGetAndSet(
    "transactions/figures",
    params,
    createSetTransactionFiguresDataAction
  );
  const rentalsLoading = useGetAndSet(
    "rentals/figures",
    params,
    createSetRentalFiguresDataAction
  );
  const vehiclesLoading = useGetAndSet(
    "vehicles/figures",
    params,
    createSetVehicleFiguresDataAction
  );
  const targetsLoading = useGetAndSet(
    "targetlatest",
      {date: params.end},
    createSetTargetFiguresDataAction
  );
  return {
    transactionsLoading,
    workLoading,
    partsLoading,
    rentalsLoading,
    vehiclesLoading,
    targetsLoading
  };
};
