import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { createSetDataFreshAction } from "../../actions/dataActions";
import { getDataFresh, getTargetsData } from "../../state/selectors";
import Spinner from "react-spinner-material";
import { Box } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { useTargetData } from "../../hooks/useTargetData";
import NewTarget from "./NewTarget";
import Collapsible from "react-collapsible";

function TargetView() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const dataFresh = useSelector(getDataFresh);

  const complete = useTargetData({ page }, dataFresh);

  const handleDelete = id => {
    const endpoint = "/api/target/" + id;
    const conf = { method: "delete", url: endpoint };
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };

  const data = useSelector(getTargetsData);

  if (!complete) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  return (
    <div>
      <NewTarget />
      <Box>
        <Collapsible
          className="title"
          triggerOpenedClassName="title"
          trigger="Targets"
        >
          <Table>
            <thead>
              <tr>
                <th>Active From</th>
                <th>Active To</th>
                <th>Amount</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.results.map(value => (
                <tr style={{ fontSize: 12 }} key={value.id}>
                  <td>{value.active_from}</td>
                  <td>{value.active_to}</td>
                  <td>£{value.weekly_target}</td>
                  <td className={"is-narrow"}>
                    <Button
                      color={"danger"}
                      onClick={() => {
                        handleDelete(value.id);
                      }}
                    >
                      Delete X
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            showFirstLast={true}
            onChange={page => setPage(page)}
            current={page}
            total={Math.ceil(data.count / 25)}
          >
            {" "}
          </Pagination>
        </Collapsible>
      </Box>
    </div>
  );
}

export default TargetView;
