import React from "react";
import {Button, Form} from "react-bulma-components";
import FAIcon from "../Icon/FAIcon";

export default function VehicleTrackerTypePicker({dataFresh, updateValue, tracker, types, onSave, editable, setEditable, inLineEdit=true}){


    return (
          <Form.Field className={'has-addons'}>
            <Form.Control>
              <Form.Select
                name={"type"}
                value={tracker.type ? tracker.type.id : -1}
                onChange={(e) => updateValue(e)}
                disabled={!editable}
              >
                <option key={-1} value={"BLANK"}></option>
                {types.results.map(type => (
                  <option key={type.id} value={type.id}>
                      {type.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Control>

            {inLineEdit && (editable ? (
                  <Form.Control>
                    <Button
                        className="small-row-white"
                        color="success"
                        onClick={onSave}
                    >
                      <FAIcon size="small" icon={["fas", "save"]}></FAIcon>
                    </Button>
                  </Form.Control>
              ) : (
                  <Form.Control>
                    <Button
                        className="small-row-black"
                        onClick={() => setEditable(!editable)}
                    >
                      <FAIcon size="small" icon={["fas", "edit"]}/>
                    </Button>
                  </Form.Control>
              ))}
          </Form.Field>
    )

}