import React from "react";
import {Button} from "react-bulma-components";
import axios from "axios";
import {createSetDataFreshAction} from "../../../actions/dataActions";
import {useDispatch} from "react-redux";

function FitterRow(fitter, dataFresh){
    const dispatch = useDispatch();
     const onDelete = () => {
        const url = "api/fitter/" + fitter.fitter.id;
        const conf = { method: "delete", url };
        axios(conf).then(response =>
            dispatch(createSetDataFreshAction(dataFresh + 1))
        );
    };
    return (
        <tr>
            <td>{fitter.fitter.name}</td>
            <td>
                <Button
                    color={"danger"}
                    onClick={onDelete}
                >
                    Delete X
                </Button>
            </td>
        </tr>
    )

}

export default FitterRow;

