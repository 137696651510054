import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTransactionData} from "../../hooks/useTransactionData";
import { Box } from "react-bulma-components";
import { Pagination } from "react-bulma-components";
import { Heading } from "react-bulma-components";
import { Table } from "react-bulma-components";
import {
  getCostCentresData,
  getDataFresh,
  getTransactionCategoriesData,
  getTransactionData, getTransactionFiguresData,
  getTransactionTypesData
} from "../../state/selectors";
import Spinner from "react-spinner-material";
import NewTransaction from "./NewTransaction";
import {
  formatDate,
  getLastDayOfWeek,
  getFirstDayOfWeek,
  readableDate
} from "../../utils";
import useWeekInput from "../../hooks/useWeekInput";
import { Columns } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Button } from "react-bulma-components";
import axios from "axios";
import {
  createSetDataFreshAction,
  createSetPartsDataAction,
  createSetTransactionDataAction
} from "../../actions/dataActions";
import WeeklyTransactionView from "./WeeklyTransactionView";
import useSelect from "../../hooks/useSelect";
import TransactionRow from "./TransactionRow";
import { Modal } from "react-bulma-components";
import { Section } from "react-bulma-components";
import ReactToPrint from "react-to-print";
import PrintParts from "../Part/PrintParts";
import {NotificationManager} from "react-notifications";
import PrintTransactions from "./PrintTransactions";
import FilterHeading from "../Invoicing/FilterHeading";
import FilterBlankHeading from "../Invoicing/FilterBlankHeading";
import {useVehicleTypeData} from "../../hooks/useVehicleTypeData";
import {useTransactionCategoryData} from "../../hooks/useTransactionCategoryData";
import FAIcon from "../Icon/FAIcon";
import MultipleEditModal from "./MultipleEditModal";
import {useTransactionFiguresData} from "../../hooks/useTransactionFiguresData";
import TransactionsSummary from "../Homepage/TransactionsSummary";
import {useCostCentreData} from "../../hooks/useCostCentreData";
import qs from "qs"
import {useLocation} from "react-router";

function Transactions(props) {
  const dispatch = useDispatch();
  const inout_mapping = {"OUT": "SP", "IN": "CC"};
  const locationMapping = {income: 'IN', expenses: 'OUT'}
  const componentRef = useRef();
  const location = useLocation()
  const pathName = location.pathname.substr(1)
  const view = locationMapping[pathName]

  let cc;

  const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true })

  if (queryParams.cost_centre) {
    cc = queryParams.cost_centre;
  }

  const [page, setPage] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [searchText, setSearchText] = useState();
  const [currentSearch, setCurrentSearch] = useState();
  const [filters, setFilters] = useState({});
  const [dateRange, setDateRange] = useState('all');
  const [searchFresh, setSearchFresh] = useState(1);
  // const [view, viewInput] = useSelect({
  //   label: "View",
  //   initialValue: "IN",
  //   options: [
  //     {name: "Income", value: "IN"},
  //     {name: "Expense", value: "OUT"}
  //   ],
  //   testId: "part-view",
  //   callback: () => {
  //     setPage(1);
  //   }
  // });
  const [approved, approvedInput] = useSelect({
    label: "Approval Status",
    initialValue: "false",
    options: [
      {name: "Approved", value: "true"},
      {name: "Unapproved", value: "false"}
    ],
    testId: "part-approval",
    callback: () => {
      setPage(1);
    }
  });
  const [recurrentView, recurrentViewInput] = useSelect({
    label: "Recurring Transactions",
    initialValue: "false",
    options: [
      {name: "Shown", value: "false"},
      {name: "Hidden", value: "true"}
    ],
    callback: () => {
      setPage(1);
    }
  });

  const [date, dateInput] = useWeekInput({
    label: "Week Selection",
    initialValue: getFirstDayOfWeek(new Date()),
    weekly: true,
    disabled: dateRange === 'all'
  });
  const dataFresh = useSelector(getDataFresh);


  let params = {
    page,
    cost_centre: cc
  };

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh){
    params.searchFresh = searchFresh;
  }
  if (dateRange === 'weekly') {
    params.start_date = formatDate(new Date(date));
    params.finish_date = formatDate(getLastDayOfWeek(new Date(date)));
  }

  params.type = view;
  params.approved = approved;
  params.hiderecurring = recurrentView;

  const headings = [
    {
        label: "ID",
    },
    {
        label: "Date",
        field: "date",
        labelField: "date",
        date: true
    },
    {
        label: "Description"
    },
    {
        label: "Contact",
        field: "contact",
        labelField: "contact__display_name"
    },
    {
        label: "Cost Centre",
        field: "cost_centre",
        labelField: "cost_centre__name"
    },
    {
        label: "Category",
        field: "category",
        labelField: "category__name"
    },
    {
      label: "Amount (£)"
    },
    {
        label: "Invoice Date",
        field: "invoice_date",
        labelField: "invoice_date",
        date: true
    },
    {
        label: "Invoice Number",
        field: "invoice_number",
        labelField: "invoice_number"
    },
    {
        label: "Invoice Amount (£)"
    },
    {
        label: "Invoice VAT (£)"
    },
    {
        label: "Invoice Total (£)",
    },
    {
        label: "Payment Date",
        field: "payment_date",
        labelField: "payment_date",
        date: true
    }
  ];

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  const complete = useTransactionData(
    params,
    dataFresh
  );

  const summaryComplete = useTransactionFiguresData(params, dataFresh)

  const toggleSelectVisible = () => {
    let newSelected = {...selected};
    let selectedKeys = Object.keys(newSelected);
    const notPresent = data.results.find(
      transaction => selectedKeys.indexOf(transaction.id.toString()) === -1
    );

    if (notPresent) {
      data.results.forEach(item => {
        newSelected[item.id.toString()] = item;
      });
    } else {
      data.results.forEach(item => {
        delete newSelected[item.id.toString()];
      });
    }
    setSelected(newSelected);
  };

  const transaction_types_loaded = useTransactionCategoryData({});
  // const cost_centres_loaded = useCostCentreData({});

  const onSelectCheck = item => {
    let newSelection = {...selected};
    if (Object.keys(selected).indexOf(item.id.toString()) === -1) {
      newSelection[item.id.toString()] = item;
    } else {
      delete newSelection[item.id.toString()];
    }
    setSelected(newSelection);
  };

  const clearSelected = () => {
    setSelected({});
  };

  const updateMultiple = newData => {
    const url = props.endpoint + "transactions/update";
    const conf = {
      method: "put",
      data: newData,
      url
    };

    return axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1));
      setSelected({});
    });
  };

  const saveSelected = (invoiceDate, invoiceNumber, paymentDate, invoiceAmount, invoiceVAT, comments) => {
    const newData = Object.values(selected).map(item => {
      let toSave = {...item};
      if (invoiceDate) {
        toSave.invoice_date = invoiceDate;
      }
      if (invoiceNumber) {
        toSave.invoice_number = invoiceNumber;
      }
      if (paymentDate) {
        toSave.payment_date = paymentDate;
      }
      if (invoiceAmount) {
        toSave.invoice_amount = invoiceAmount;
      }
      if (invoiceVAT) {
        toSave.invoice_vat = invoiceVAT;
      }
      if (comments) {
        toSave.comments = comments;
      }
      return toSave;
    });
    return updateMultiple(newData);
  };

  const approveSelected = () => {
    let passValidation = true;
    const newData = Object.values(selected).map(item => {
      if (item.invoice_number.length > 0 && (item.type === 'IN' ? /^\d+$/.test(item.invoice_number) : true) && item.payment_date) {
        return {...item, approved: true};
      } else {
        passValidation = false;
        return {};
      }
    });

    if (passValidation) {
      return updateMultiple(newData);
    } else {
      NotificationManager.warning(
        "You must specify an invoice number and payment date before approving!",
        "Invoice Number and Payment Date Required",
        10000
      );
    }
  };

  const onTransactionUpdate = (index, newTransaction) => {
    let newState = {...data};
    newState.results[index] = newTransaction;
    dispatch(createSetTransactionDataAction(newState));
    setSelected({});
  };

  const handleDelete = id => {
    const endpoint = "/api/transactions/" + id;
    const conf = {method: "delete", url: endpoint};
    axios(conf).then(response =>
      dispatch(createSetDataFreshAction(dataFresh + 1))
    );
  };
  const data = useSelector(getTransactionData);
  const transaction_categories = useSelector(getTransactionCategoriesData);
  const summaryData = useSelector(getTransactionFiguresData)
  const costCentres = useSelector(getCostCentresData);

  const costCentreObjects =  costCentres ? costCentres.results.reduce((obj, item) => {
    return {
      ...obj,
      [item["id"]]: item
    };
  }, {}) : {};


  if (!complete || !transaction_types_loaded || !summaryComplete || !costCentres) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = pathName[0].toUpperCase() + pathName.substring(1);

  return (
    <div>
      <NewTransaction transaction_categories={transaction_categories.results} costCentres={costCentres.results} cc={cc} endpoint={props.endpoint} type={view}/>
      <WeeklyTransactionView cc={cc}/>
      <Box>
        <Heading>Week View</Heading>
        <Columns>
          <Columns.Column>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Label>Date Range</Form.Label>
                <Button
                  color={dateRange === "all" ? "primary" : ""}
                  onClick={() => setDateRange("all")}
                >
                  All
                </Button>
                <Button
                  color={dateRange === "weekly" ? "primary" : ""}
                  onClick={() => setDateRange("weekly")}
                >
                  Weekly
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
          {dateInput}
          {approvedInput}
          {recurrentViewInput}
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Label>Search for a Transaction</Form.Label>
            <Form.Field className="has-addons">
              <Form.Control>
                <Form.Input
                  onChange={e => setSearchText(e.target.value)}
                  onKeyPress={e => (e.key === 'Enter' || e.keyCode === 13) && handleSearch()}
                  name="search_text"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                ></Form.Input>
              </Form.Control>
              <Form.Control>
                <Button onClick={handleSearch} type="primary">
                  Search
                </Button>
              </Form.Control>
              <Form.Control>
                <Button onClick={clear} color="warning">
                  Clear
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      <Box>
        <Columns>
          {summaryData.categories.map(cat => <TransactionsSummary category={cat} loaded={summaryComplete}
                                                                  hideExpense={view === 'IN'}
                                                                  hideIncome={view === 'OUT'}/>)}
          {view === 'IN' &&
          <Columns.Column>
            <Form.Field>
              <Form.Control>Total Income: £{summaryData.total_in.toFixed(2)}</Form.Control>
            </Form.Field>
          </Columns.Column>
          }
          {view === 'OUT' &&
          <Columns.Column>
            Total Expense: £{summaryData.total_out.toFixed(2)}
          </Columns.Column>
          }
        </Columns>
      </Box>
      <Box>
        <Heading size={4}>{view === 'IN' ? "Incoming" : "Outgoing"}{cc ? " " + costCentreObjects[cc].name  : ""} Transactions</Heading>
        <Table>
          <thead>
          <tr>
            {headings.map(heading => {
                if (!heading.field) {
                  return <th key={heading.label}>{heading.label}</th>;
                }
                if (heading.blank) {
                  return (
                    <FilterBlankHeading
                      key={heading.label}
                      heading={heading}
                      setFilters={f => {
                        setPage(1);
                        setFilters(f);
                      }}
                      filters={filters}
                    />
                  );
                }
                return (
                  <FilterHeading
                    key={heading.label}
                    heading={heading}
                    setFilters={f => {
                      setPage(1);
                      setFilters(f);
                    }}
                    filters={filters}
                    options={data.filter_list[heading.field]}
                  />
                );
            })}
            {approved === 'true' && <th>Approver</th>}
            <th></th>
            <th>
              <Button
                onClick={toggleSelectVisible}
                color={
                  data.results.find(
                    transaction =>
                      Object.keys(selected).indexOf(transaction.id.toString()) === -1
                  )
                    ? ""
                    : "danger"
                }
              >
                {data.results.find(
                  transaction =>
                    Object.keys(selected).indexOf(transaction.id.toString()) === -1
                ) ? (
                  <div>
                    <FAIcon size="small" icon={["fas", "check"]}/>
                    {/*<span>Visible</span>*/}
                  </div>
                ) : (
                  <div>
                    <FAIcon size="small" icon={["fas", "times"]}/>
                    {/*<span>Visible</span>*/}
                  </div>
                )}
              </Button>
            </th>
            <th></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {data.results.map((value, index) => (
            <TransactionRow
              key={value.id}
              transaction={value}
              index={index}
              onTransactionUpdate={onTransactionUpdate}
              endpoint={props.endpoint}
              onSelectCheck={onSelectCheck}
              selected={
                Object.keys(selected).indexOf(value.id.toString()) !== -1
              }
              clearSelected={clearSelected}
              editDisabled={approved === "true"}
              transactionCategories={transaction_categories.results}
              costCentres={costCentres.results}
              contact_type={inout_mapping[view]}
            />
          ))}
          </tbody>
        </Table>
        <Pagination
          showFirstLast={true}
          onChange={page => setPage(page)}
          current={page}
          total={Math.ceil(data.count / 25)}
        >
          {" "}
        </Pagination>
      </Box>
      <Box>
        <Columns>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setEditModalOpen(true)}
              color={Object.entries(selected).length ? "primary" : ""}
              disabled={!Object.entries(selected).length}
            >
              Edit Selected
            </Button>
          </Columns.Column>
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => setModalOpen(true)}
              color={Object.entries(selected).length ? "warning" : ""}
              disabled={!Object.entries(selected).length}
            >
              Print Selected
            </Button>
          </Columns.Column>
          {approved === "false" &&
          <Columns.Column>
            <Button
              fullwidth
              onClick={() => approveSelected()}
              color={Object.entries(selected).length ? "success" : ""}
              disabled={!Object.entries(selected).length}
            >
              Approve Selected
            </Button>
          </Columns.Column>
          }
        </Columns>
      </Box>
      <Modal closeOnBlur show={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Content>
          <Section style={{backgroundColor: "white"}}>
            <Form.Field>
              <p>
                You have selected {Object.keys(selected).length} out of total of{" "}
                {data.count} items for printing
              </p>
            </Form.Field>
            <ReactToPrint
              trigger={() => (
                <Button
                  color={Object.entries(selected).length ? "success" : ""}
                >
                  Print Selected
                </Button>
              )}
              content={() => componentRef.current}
            />
          </Section>
        </Modal.Content>
      </Modal>
      <MultipleEditModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        selected={selected}
        saveSelected={saveSelected}
      />
      <div style={{display: "none"}}>
        <PrintTransactions data={selected} ref={componentRef}/>
      </div>
    </div>
  );
}

export default Transactions;
